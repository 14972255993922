import { useAuth0 } from '@auth0/auth0-react'
import { Color, Spinner } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { useBeansQuery } from '../../graphql/types.generated'
import { Purchase } from './Purchase'

export const Beans = () => {
  const { data, loading } = useBeansQuery({ fetchPolicy: 'cache-first' })
  const { isLoading } = useAuth0()

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Color.GREY_200,
        paddingTop: 24,
        paddingBottom: 48
      }}
    >
      <div
        style={{
          width: '80%',
          backgroundColor: Color.WHITE,
          paddingTop: 24,
          paddingLeft: 12,
          paddingRight: 12,
          paddingBottom: 36
        }}
      >
        {loading || isLoading ? <Spinner size='small' color='primary' /> : <Purchase items={data?.beans || []} />}
      </div>
    </div>
  )
}
