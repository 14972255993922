import { Text } from '@bloom-coffee/steamed-milk'
import React, { useEffect } from 'react'

import { clean } from '../../config'
import { logger } from '../../logger'

export const Splash = () => {
  useEffect(() => {
    logger.debug('Splash', JSON.stringify(clean()))
  }, [])

  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <Text variant='header1'>RDY</Text>
      <Text variant='subheader3'>Nothing to see here...</Text>
    </div>
  )
}
