import { Cart, CartItem, Maybe } from '@bloom-coffee/espresso'
import { Color } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { CartItemContainer } from './CartItemContainer'

interface CartItemsProps {
  readableId: string
  cart: Maybe<Cart>
  handleDuplicateItem: (item: CartItem) => void
  handleRemoveItem: (item: CartItem) => void
}

export const CartItemsContainer = (props: CartItemsProps) => {
  const { cart, readableId, handleDuplicateItem, handleRemoveItem } = props
  return (
    <div>
      {cart?.items.map((item: CartItem) => {
        return (
          <div key={item.id} style={{ paddingBottom: 2, borderBottom: `1px solid ${Color.RDY_BEIGE}` }}>
            <CartItemContainer
              readableId={readableId}
              item={item}
              handlePlusClick={handleDuplicateItem}
              handleMinusClick={() => handleRemoveItem(item)}
            />
          </div>
        )
      })}
    </div>
  )
}
