import {
  CartItem,
  DiningOption,
  diningOptionToOrderDiningOption,
  formatUsCentsAsUsDollar,
  FulfillmentLocationInfo,
  Maybe,
  OrderTiming,
  PaymentSummaryInfo
} from '@bloom-coffee/espresso'
import { Button, Color, Spinner, Text } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import { CardElement, PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { TextField } from '../../components/TextField'
import { PhoneField } from '../../components/TextField/PhoneField'
import { OrderItemInput, OrderItemModifierInput, useInitializeOrderMutation } from '../../graphql/types.generated'
import { logger } from '../../logger'
import { deleteCart } from '../../service/CartService'
import { GuestCheckoutInfoModel, guestCheckoutInfoSchema } from './GuestCheckoutValidator'

interface CheckoutProps {
  onCancel: () => void
  cartItems: CartItem[]
  paymentSummary: PaymentSummaryInfo
  orderTiming: OrderTiming
  placeInSeconds?: Maybe<number>
  placeOnDate?: Maybe<Date>
  servingLocation?: Maybe<FulfillmentLocationInfo>
  diningOption: DiningOption
  merchantName: string
  intermission: boolean
}

// pls fix hack
function constructOrderItems(cartItems: CartItem[], intermission: boolean = false): OrderItemInput[] {
  const items: OrderItemInput[] = []
  for (let cartItem of cartItems) {
    let notes = cartItem.notes
    if (intermission) {
      notes = !!notes ? `${notes}\nINTERMISSION` : 'INTERMISSION'
    }
    const modifiers: OrderItemModifierInput[] = cartItem.selectedModifiers.map((sm) => ({
      parentModifierId: sm.topModifier.id,
      selectedModifierId: sm.secondLevelModifier.id
    }))
    items.push({
      productId: cartItem.product.id,
      modifiers,
      currencyType: cartItem.currencyType,
      notes
    })
  }
  return items
}

export const Checkout = (props: CheckoutProps) => {
  const {
    paymentSummary,
    merchantName,
    orderTiming,
    placeInSeconds,
    placeOnDate,
    servingLocation,
    diningOption,
    cartItems,
    intermission,
    onCancel
  } = props

  const items = constructOrderItems(cartItems, intermission)

  const [error, setError] = useState<string | undefined>()
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null)
  const [confirmingPaymentIntent, setConfirmingPaymentIntent] = useState(false)

  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()

  const [execute] = useInitializeOrderMutation()

  const formProps = useForm<GuestCheckoutInfoModel>({
    resolver: yupResolver(guestCheckoutInfoSchema)
  })
  const { register, handleSubmit, formState } = formProps

  /**
   * Check if Apple/Google Pay available
   */
  useEffect(() => {
    if (stripe && !!paymentSummary.usCents?.total) {
      logger.debug('Checkout', 'checking canMakePayment()')

      const amount = paymentSummary.usCents?.total
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: `${merchantName} via RDY`,
          amount
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true
      })

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr)
        }
      })
    }
  }, [stripe, paymentSummary, merchantName])

  /**
   * Listen to paymentRequest event 'paymentmethod'
   * on this event, attempt to confirm the payment
   */
  useEffect(() => {
    if (paymentRequest) {
      logger.debug('Checkout', 'setting paymentRequest listener')

      paymentRequest.on('paymentmethod', async (ev) => {
        if (!confirmingPaymentIntent) {
          setConfirmingPaymentIntent(true)

          /**
           * Initialize order with the result
           */
          const userProfile: GuestCheckoutInfoModel = {
            firstName: ev.payerName || '',
            lastName: '',
            email: ev.payerEmail!,
            phoneNumber: ev.payerPhone || ''
          }

          let _placeInSeconds = placeInSeconds
          if (!_placeInSeconds && !!placeOnDate) {
            _placeInSeconds = Math.round((placeOnDate.getTime() - new Date().getTime()) / 1000) + 60
          }

          let clientSecret: Maybe<string>
          let uuid: Maybe<string>
          try {
            const initializeOrderResult = await execute({
              variables: {
                input: {
                  userProfile,
                  order: { items },
                  timing: orderTiming,
                  placeInSeconds: _placeInSeconds,
                  tipUsCents: paymentSummary.usCents!.tip!,
                  diningOption: diningOptionToOrderDiningOption[diningOption],
                  servingLocationId: diningOption === DiningOption.TableService ? servingLocation?.id : null
                }
              }
            })
            const result = initializeOrderResult.data?.initializeOrder
            clientSecret = result?.paymentIntent?.clientSecret
            uuid = result?.order.uuid
          } catch (e) {
            logger.error('Checkout', `${e}`)
          }

          if (!clientSecret) {
            setError('Something went wrong')
            setConfirmingPaymentIntent(false)
          } else {
            /**
             * Confirm the payment
             */
            const { paymentIntent, error: confirmError } = await stripe!.confirmCardPayment(
              clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            )

            /**
             * On error, display the error message
             */
            if (confirmError) {
              setError(`${confirmError.message}`)
              setConfirmingPaymentIntent(false)
              ev.complete('fail')
            } else {
              ev.complete('success')

              /**
               * According to stripe docs, check for requires action.
               * If requires action, confirm again and display necessary errors
               */
              if (paymentIntent.status === 'requires_action') {
                const { error: confirmError2 } = await stripe!.confirmCardPayment(clientSecret)
                if (confirmError2) {
                  setError(confirmError2.message)
                  setConfirmingPaymentIntent(false)
                } else {
                  deleteCart()
                  navigate(`/order/${uuid}`)
                }
              } else {
                deleteCart()
                navigate(`/order/${uuid}`)
              }
            }
          }
        }
      })
    }
  }, [
    paymentRequest,
    items,
    execute,
    navigate,
    stripe,
    confirmingPaymentIntent,
    orderTiming,
    placeInSeconds,
    placeOnDate,
    paymentSummary,
    diningOption,
    servingLocation
  ])

  async function confirmCardPayment(userProfile: GuestCheckoutInfoModel) {
    if (confirmingPaymentIntent) {
      return
    }

    if (!stripe || !elements) {
      setError('Something went wrong')
      return
    }

    setError(undefined)
    setConfirmingPaymentIntent(true)

    const cardElement = elements.getElement(CardElement)!
    const { error: cardError, paymentMethod } = await stripe!.createPaymentMethod({
      type: 'card',
      card: cardElement
    })

    if (cardError) {
      setError(cardError.message)
      setConfirmingPaymentIntent(false)
    } else {
      let clientSecret: Maybe<string>
      let uuid: Maybe<string>
      try {
        let _placeInSeconds = placeInSeconds
        if (!_placeInSeconds && !!placeOnDate) {
          _placeInSeconds = Math.round((placeOnDate.getTime() - new Date().getTime()) / 1000) + 60
        }

        const initializeOrderResult = await execute({
          variables: {
            input: {
              userProfile,
              order: { items },
              timing: orderTiming,
              placeInSeconds: _placeInSeconds,
              tipUsCents: paymentSummary.usCents!.tip!,
              diningOption: diningOptionToOrderDiningOption[diningOption],
              servingLocationId: diningOption === DiningOption.TableService ? servingLocation?.id : null
            }
          }
        })
        const result = initializeOrderResult.data?.initializeOrder
        clientSecret = result?.paymentIntent?.clientSecret
        uuid = result?.order.uuid
      } catch (e) {
        logger.error('Checkout', `${e}`)
      }

      if (!clientSecret) {
        setError('Something went wrong')
        setConfirmingPaymentIntent(false)
      } else {
        const { paymentIntent, error: confirmError } = await stripe!.confirmCardPayment(
          clientSecret,
          { payment_method: paymentMethod.id },
          { handleActions: false }
        )

        if (confirmError) {
          setError(`${confirmError.message}`)
          setConfirmingPaymentIntent(false)
        } else {
          if (paymentIntent.status === 'requires_action') {
            const { error: confirmError2 } = await stripe!.confirmCardPayment(clientSecret)
            if (confirmError2) {
              setError(confirmError2.message)
              setConfirmingPaymentIntent(false)
            } else {
              deleteCart()
              navigate(`/order/${uuid}`)
            }
          } else {
            deleteCart()
            navigate(`/order/${uuid}`)
          }
        }
      }
    }
  }

  const textFieldWidth = window.innerWidth > 400 ? 250 : 225

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div>
        {!!paymentRequest && (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ width: textFieldWidth }}>
                <PaymentRequestButtonElement options={{ paymentRequest }} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 24,
                paddingBottom: 24
              }}
            >
              <div style={{ width: '45%', height: 1, backgroundColor: Color.RDY_FOREST }} />
              <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Text variant='body1'>OR</Text>
              </div>
              <div style={{ width: '45%', height: 1, backgroundColor: Color.RDY_FOREST }} />
            </div>
          </>
        )}

        <div>
          <FormProvider {...formProps}>
            <form>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  paddingBottom: 12
                }}
              >
                <div style={{ marginLeft: 4, marginRight: 4 }}>
                  <TextField name='firstName' label='First Name' ref={register()} width={textFieldWidth} />
                </div>
                <div style={{ marginLeft: 4, marginRight: 4 }}>
                  <TextField name='lastName' label='Last Name' ref={register()} width={textFieldWidth} />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  paddingBottom: 12
                }}
              >
                <div style={{ marginLeft: 4, marginRight: 4 }}>
                  <TextField name='email' label='Email' ref={register()} width={textFieldWidth} />
                </div>
                <div style={{ marginLeft: 4, marginRight: 4 }}>
                  <PhoneField name='phoneNumber' label='Phone Number' defaultValue={null} width={textFieldWidth} />
                </div>
              </div>

              <div style={{ marginBottom: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                  style={{
                    border: `1px solid ${Color.RDY_FOREST}`,
                    padding: '12px 8px 12px 8px',
                    width: textFieldWidth
                  }}
                >
                  <CardElement />
                </div>
              </div>

              {confirmingPaymentIntent && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ marginBottom: 8 }}>
                    <Text variant='body2'>Confirming Order... Please do not leave this page</Text>
                  </div>
                  <Spinner size='small' color='primary' />
                </div>
              )}

              <div style={{ display: 'flex', justifyContent: 'center', padding: 12 }}>
                {error && <Text variant='error'>{error}</Text>}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ marginBottom: 12 }}>
                  <Button
                    theme='action'
                    onClick={handleSubmit(confirmCardPayment)}
                    size='large'
                    label='Place Order'
                    endIcon={<Text variant='body2'>${formatUsCentsAsUsDollar(paymentSummary.usCents!.total!)}</Text>}
                    disabled={confirmingPaymentIntent || formState.isSubmitting}
                  />
                </div>

                <div>
                  <Button
                    theme='cancel'
                    onClick={() => onCancel()}
                    size='large'
                    label='Cancel'
                    disabled={confirmingPaymentIntent || formState.isSubmitting}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  )
}
