/* eslint-disable max-len */
import React from 'react'

export const Position2 = (props: { height: number }) => {
  const { height } = props
  return (
    <svg height={height} width='100%' viewBox='0 0 72 81' fill='none'>
      <path
        d='M72 54.5625C72 69.075 60.2059 81 45.6037 81H26.3963C11.9064 81 0 69.075 0 54.5625V26.4375C0 11.925 11.9064 0 26.3963 0H45.6037C60.0936 0 72 11.925 72 26.4375V54.5625Z'
        fill='#F8B5BA'
      />
      <path
        d='M53.8033 57.0373H32.1246L45.3789 46.7998C53.017 40.4998 56.1621 34.6498 56.1621 27.7873C56.1621 17.6623 49.0856 8.77484 36.8423 8.77484C24.5989 8.77484 17.5225 17.6623 17.5225 27.3373C17.5225 29.2498 17.6348 30.4873 17.9718 32.0623H29.2042C28.6426 30.9373 28.4179 29.4748 28.4179 27.7873C28.4179 23.0623 31.563 19.6873 36.8423 19.6873C42.1215 19.6873 45.2666 23.1748 45.2666 27.7873C45.2666 31.3873 43.5817 34.5373 39.2011 38.2498L17.6348 55.6873V68.0623H19.5443H49.0856H53.8033L58.6332 62.5498L53.8033 57.0373Z'
        fill='#09292D'
      />
    </svg>
  )
}
