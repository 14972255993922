import { Maybe, MerchantLocationInfo, OrderInfo } from '@bloom-coffee/espresso'
import { Button, Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

interface MerchantDirectionsContainerProps {
  order: OrderInfo
  fontColor?: Color
}

export const MerchantDirectionsContainer = (props: MerchantDirectionsContainerProps) => {
  const { order, fontColor = Color.RDY_FOREST } = props

  function getMapsAddress(merchantLocation: Maybe<MerchantLocationInfo>): string {
    if (!merchantLocation) {
      return ''
    }

    let street = merchantLocation?.street || ''
    let city = merchantLocation?.city || ''
    let state = merchantLocation?.state || ''
    let zip = merchantLocation?.zipCode || ''
    return `http://maps.google.com/?q=${street}, ${city}, ${state}, ${zip}`
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginBottom: 12 }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5 }}>
        <Text variant='header1' color={fontColor}>
          {order.merchant?.name}
        </Text>
        <Text variant='body1' color={fontColor}>
          {order.merchant?.location?.street}
        </Text>
      </div>
      <a
        style={{ textDecoration: 'none' }}
        href={getMapsAddress(order.merchant?.location)}
        target='_blank'
        rel='noreferrer'
      >
        <Button label='Directions' onClick={() => {}} />
      </a>
    </div>
  )
}
