import './index.css'
import './fonts/Matter-Medium.ttf'
import './fonts/Matter-Regular.ttf'
import './fonts/Raw-Bold.otf'
import './fonts/RDY-Glyphs.ttf'

import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//57029

reportWebVitals()
