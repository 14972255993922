import {
  formatUsCentsAsUsDollar,
  readableDateFromInstant,
  Requisition,
  RequisitionScheduleFrequency
} from '@bloom-coffee/espresso'
import { Button, Color, Text } from '@bloom-coffee/steamed-milk'
import React, { useState } from 'react'

import { useUpdateRequisitionMutation } from '../../graphql/types.generated'
import { RequisitionPurchaseContainer } from './RequisitionPurchaseContainer'

interface RequisitionContainerProps {
  requisition: Requisition
}

function getPerVerbiageFromFrequency(frequency: RequisitionScheduleFrequency): string {
  if (frequency === RequisitionScheduleFrequency.EndOfMonth) {
    return 'per month'
  }
  return ''
}

export const RequisitionContainer = (props: RequisitionContainerProps) => {
  const { requisition } = props
  const [paused, setPaused] = useState(!!requisition.schedule?.paused)

  const [execute, { error, loading }] = useUpdateRequisitionMutation()

  function handlePauseClick() {
    if (loading) {
      return
    }

    if (
      !requisition.schedule?.paymentMethodId ||
      !requisition.schedule.frequency ||
      !requisition.firstName ||
      !requisition.lastName ||
      !requisition.fulfillmentLocation
    ) {
      return
    }

    const shippingAddress = {
      street: requisition.fulfillmentLocation.street || '',
      street2: requisition.fulfillmentLocation.street2 || '',
      city: requisition.fulfillmentLocation.city || '',
      state: requisition.fulfillmentLocation.state || '',
      zipcode: requisition.fulfillmentLocation.zipcode || '',
      countryCode: requisition.fulfillmentLocation.countryCode || ''
    }

    setPaused(!paused)

    execute({
      variables: {
        input: {
          id: requisition.id,
          fulfillment: {
            firstName: requisition.firstName,
            lastName: requisition.lastName,
            shippingAddress,
            paymentMethodId: requisition.schedule.paymentMethodId,
            schedule: {
              frequency: requisition.schedule.frequency,
              paused: !paused,
              archived: false
            }
          }
        }
      }
    })
  }

  return (
    <div>
      <div style={{ marginBottom: 12 }}>
        <div>
          <Text variant='header3'>Order no. {requisition.id} </Text>
        </div>
        <div>
          <Text variant='body2'>Placed on {readableDateFromInstant(requisition.createdOn || 0)}</Text>
        </div>
      </div>

      <div style={{ marginBottom: 15 }}>
        <div>
          <Text variant='subheader2'>Items</Text>
        </div>

        {requisition.items?.map((item, i) => (
          <div key={`${item.id}.${i}`}>
            <div style={{ marginRight: 6 }}>
              <Text variant='body2'>{item.item?.name}</Text>
            </div>
            <div>
              <Text variant='body3'>{'$' + formatUsCentsAsUsDollar(item.cents || 0)}</Text>
              {!!requisition.schedule?.frequency && (
                <Text variant='body3'> {getPerVerbiageFromFrequency(requisition.schedule.frequency)}</Text>
              )}
            </div>
          </div>
        ))}
      </div>

      {!!requisition.fulfillmentLocation && (
        <div style={{ marginBottom: 15 }}>
          <div>
            <Text variant='subheader2'>Shipping</Text>
          </div>

          <div>
            <Text variant='body2'>{`${requisition.firstName} ${requisition.lastName}`}</Text>
          </div>

          <div>
            <Text variant='body2'>{requisition.fulfillmentLocation.street}</Text>
          </div>
          <div>
            <Text variant='body2'>{requisition.fulfillmentLocation.street2}</Text>
          </div>

          <div>
            <Text variant='body2'>
              {`${requisition.fulfillmentLocation.city}, ${requisition.fulfillmentLocation.state}` +
                `  ${requisition.fulfillmentLocation.zipcode}`}
            </Text>
          </div>
        </div>
      )}

      <div style={{ marginBottom: 15 }}>
        <div>
          <Text variant='subheader2'>Charges</Text>
        </div>

        {!!requisition.schedule && (
          <div style={{ marginBottom: 5 }}>
            <div>
              <Text variant='subheader3' color={Color.RDY_FOREST}>
                Next payment scheduled for {requisition.schedule.nextScheduledPayment}
              </Text>
            </div>
          </div>
        )}

        {!!requisition.schedule && (
          <div style={{ marginBottom: 12 }}>
            <Button
              theme='action'
              onClick={() => handlePauseClick()}
              size='medium'
              label={paused ? 'Unpause' : 'Pause'}
            />
          </div>
        )}

        {requisition.purchases?.map((p, i) => (
          <div key={`${p?.id}.${i}`} style={{ marginBottom: 4 }}>
            <RequisitionPurchaseContainer purchase={p} />
          </div>
        ))}
      </div>

      {requisition.schedule?.paused === true && (
        <div>
          <div>
            <Text variant='error'>This subscription has been paused.</Text>
          </div>
        </div>
      )}

      {!!error && (
        <div>
          <Text variant='error'>{error.message}</Text>
        </div>
      )}
    </div>
  )
}
