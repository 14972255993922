import { formatUsCentsAsUsDollar, Item, RequisitionScheduleFrequency } from '@bloom-coffee/espresso'
import { Color, ImageCard, ImageCardItem } from '@bloom-coffee/steamed-milk'
import React from 'react'

import ImageAsset from '../../img/ImageAsset'

function getPerVerbiageFromFrequency(frequency: RequisitionScheduleFrequency): string {
  if (frequency === RequisitionScheduleFrequency.EndOfMonth) {
    return '/ month'
  }
  return ''
}

interface PurchaseItemProps {
  index: number
  item: Item
  onPress: (item: Item) => void
  disabled: boolean
  selected: boolean
}

function itemToImageCard(item: Item, index: number) {
  const image = {
    type: 'image',
    source: index % 2 === 0 ? ImageAsset.PRODUCT_IMAGE_BEANS_ONE_TIME : ImageAsset.PRODUCT_IMAGE_BEANS_SUB
  }
  return {
    id: item.id,
    value: item,
    image,
    title: item.name || '',
    subtitle:
      '$' + formatUsCentsAsUsDollar(item.priceCents || 0) + getPerVerbiageFromFrequency(item.scheduleFrequency!!),
    tagValue: index % 2 === 0 ? undefined : 'First Month Free',
    tagColor: 'primary'
  } as ImageCardItem<Item>
}

export const PurchaseItem = (props: PurchaseItemProps) => {
  const { item, onPress, disabled, selected, index } = props
  const card = itemToImageCard(item, index)
  return (
    <div style={{ border: selected ? `3px solid ${Color.RDY_MALIBU}` : 'none' }}>
      <ImageCard variant='light' card={card} onSelect={() => onPress(item)} size='medium' clickDisabled={disabled} />
    </div>
  )
}
