import { getMerchantAvailability, Maybe, MerchantAvailabilityState, MerchantInfo } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

interface CartHeaderProps {
  merchant?: Maybe<MerchantInfo>
  onPress: () => void
}

export const CartHeader = (props: CartHeaderProps) => {
  const { merchant, onPress } = props

  const availability = getMerchantAvailability(merchant?.operationHours)

  const fontColor = Color.RDY_FOREST
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <button onClick={() => onPress()} style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
        <div>
          <Text variant='header1'>{merchant?.name}</Text>
          {availability.state === MerchantAvailabilityState.Opened &&
            !!availability.nextClose?.minutesUntil &&
            availability.nextClose.minutesUntil < 60 && (
              <div>
                <Text variant='body1' color={Color.RDY_RED}>
                  Closes in {availability.nextClose.minutesUntil} minutes.
                </Text>
              </div>
            )}
          {!!merchant?.location && (
            <>
              <div>
                <Text variant='body3' color={fontColor}>
                  {merchant.location.street}
                </Text>
              </div>

              <div>
                <Text variant='body3' color={fontColor}>
                  {`${merchant.location.city}, ${merchant.location.state}`}
                </Text>
              </div>
            </>
          )}
        </div>
      </button>
    </div>
  )
}
