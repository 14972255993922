import { OrderTiming } from '@bloom-coffee/espresso'
import { Button, Color, Select, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { OrderTimingConfig, OrderTimingSelection } from './OrderTimingConfig'

interface OrderTimingSelectorProps {
  config: OrderTimingConfig
  selected: OrderTimingSelection
  onOrderTimingUpdate: (selection: OrderTimingSelection) => void
}

export const OrderTimingSelector = (props: OrderTimingSelectorProps) => {
  const { config, selected, onOrderTimingUpdate } = props

  let description = ''
  if (selected.timing === OrderTiming.Asap) {
    if (selected.displayName === 'Intermission') {
      description = 'We will start making your order at intermission' // hack - fix
    } else {
      description = 'We will start making your order as soon as possible'
    }
  } else if (selected.timing === OrderTiming.OnTimer) {
    if (selected.displayName.toLowerCase().includes('minutes')) {
      description = `We will start making your order in ${selected.displayName}`
    } else {
      description = `We will start making your order on ${selected.displayName}`
    }
  }

  function handleSelection(newIndex: string) {
    let index: number = +newIndex
    onOrderTimingUpdate(config.selections!![index])
  }

  return (
    <div style={{ flexDirection: 'column', alignItems: 'center', display: 'flex' }}>
      {config.selections.length === 2 ? (
        <div style={{ display: 'flex' }}>
          <div>
            <Button
              theme={selected.displayName === config.selections[0].displayName ? 'action' : 'cancel'}
              onClick={() => handleSelection('0')}
              label={config.selections[0].displayName}
            />
          </div>
          <div>
            <Button
              theme={selected.displayName === config.selections[1].displayName ? 'action' : 'cancel'}
              onClick={() => handleSelection('1')}
              label={config.selections[1].displayName}
            />
          </div>
        </div>
      ) : (
        <Select
          options={config.selections.map((selection, index) => {
            return { id: index.toString(), displayName: selection.displayName }
          })}
          onChange={handleSelection}
        />
      )}

      <div style={{ paddingTop: 6, flexDirection: 'row', justifyContent: 'center', display: 'flex' }}>
        <Text variant='subheader3' color={Color.RDY_FOREST}>
          {description}
        </Text>
      </div>
    </div>
  )
}
