import {
  Cart,
  CartItem,
  CurrencyType,
  generateRandomAlphaNumeric,
  getRawPriceTag,
  Maybe,
  MerchantAvailability,
  MerchantAvailabilityState,
  MerchantInfo,
  OrderTiming,
  ProductInfo,
  SelectedModifier,
  TipOptionConfig,
  tipOptionConfigs,
  TipType,
  UpdateCartItemInput
} from '@bloom-coffee/espresso'

import { OrderTimingConfig, OrderTimingSelection } from '../views/Cart/components/OrderTimingConfig'

const LOCAL_STORAGE_KEY_CART = 'cart'

export function fetchCart(): Maybe<Cart> {
  const current = localStorage.getItem(LOCAL_STORAGE_KEY_CART)
  if (current) {
    return JSON.parse(current) as Cart
  }
}

function _createCartItems(
  product: ProductInfo,
  selectedModifiers: SelectedModifier[],
  currencyType: CurrencyType,
  quantity: number,
  notes?: string
): CartItem[] {
  const items: CartItem[] = []
  for (let x = 0; x < quantity; x++) {
    items.push({
      id: generateRandomAlphaNumeric(15),
      product,
      selectedModifiers,
      currencyType,
      notes
    })
  }
  return items
}

function addCartItems(merchant: MerchantInfo, items: CartItem[]): Cart {
  const current = localStorage.getItem(LOCAL_STORAGE_KEY_CART)
  let _items = [...items]
  if (!!current) {
    const asCart = JSON.parse(current) as Cart
    if (asCart.merchant.id === merchant.id) {
      _items = [..._items, ...asCart.items]
    }
  }
  const newCart: Cart = { merchant, items: _items }
  localStorage.setItem(LOCAL_STORAGE_KEY_CART, JSON.stringify(newCart))
  return newCart
}

export function addToCart(
  merchant: MerchantInfo,
  productDetails: ProductInfo,
  selectedModifiers: SelectedModifier[],
  quantity: number,
  notes?: string
): Cart {
  let items: CartItem[] = _createCartItems(productDetails, selectedModifiers, CurrencyType.UsCents, quantity, notes)
  return addCartItems(merchant, items)
}

function updateItemsInState(cartItems: CartItem[]): Maybe<Cart> {
  const current = localStorage.getItem(LOCAL_STORAGE_KEY_CART)
  if (current) {
    const currentAsCart = JSON.parse(current) as Cart

    const items = []
    for (let x = 0; x < currentAsCart.items.length; x++) {
      const updatedItem = cartItems.find((i) => i.id === currentAsCart.items[x].id)
      if (updatedItem) {
        items.push(updatedItem)
      } else {
        items.push(currentAsCart.items[x])
      }
    }

    const newCart: Cart = { ...currentAsCart, items }
    localStorage.setItem(LOCAL_STORAGE_KEY_CART, JSON.stringify(newCart))
    return newCart
  }
}

export function updateCartItem(item: CartItem, input: UpdateCartItemInput): Maybe<Cart> {
  const { currencyType, selectedModifiers, notes } = input

  let updatedItem = { ...item }
  if (currencyType) {
    updatedItem = { ...updatedItem, currencyType }
  }

  if (selectedModifiers?.length) {
    updatedItem = { ...updatedItem, selectedModifiers }
  }

  if (typeof notes !== 'undefined') {
    const updatedNotes = notes?.length ? notes : undefined
    updatedItem = { ...updatedItem, notes: updatedNotes }
  }
  return updateItemsInState([updatedItem])
}

export function removeFromCart(item: CartItem): Maybe<Cart> {
  const current = localStorage.getItem(LOCAL_STORAGE_KEY_CART)
  if (current) {
    const currentAsCart = JSON.parse(current) as Cart
    const items = currentAsCart.items.filter((cItem: CartItem) => cItem.id !== item.id)
    const newCart: Cart = { ...currentAsCart, items }
    localStorage.setItem(LOCAL_STORAGE_KEY_CART, JSON.stringify(newCart))
    return newCart
  }
}

export function duplicateCartItem(item: CartItem): Maybe<Cart> {
  const current = localStorage.getItem(LOCAL_STORAGE_KEY_CART)
  if (current) {
    const currentAsCart = JSON.parse(current) as Cart
    const newitem: CartItem = {
      id: generateRandomAlphaNumeric(15),
      product: item.product,
      selectedModifiers: item.selectedModifiers,
      currencyType: CurrencyType.UsCents, // allow them to select tokens if they have enough
      notes: item.notes
    }
    const items = [...currentAsCart.items, newitem]
    const newCart: Cart = { ...currentAsCart, items }
    localStorage.setItem(LOCAL_STORAGE_KEY_CART, JSON.stringify(newCart))
    return newCart
  }
}

export function deleteCart() {
  localStorage.removeItem(LOCAL_STORAGE_KEY_CART)
}

const percentageMinimumUsCents = 1000
export function getDefaultTipInfoFromCart(cart: Cart) {
  const nonPromoSubtotal = getRawPriceTag(cart.items).priceUsCents

  let options: TipOptionConfig[] = tipOptionConfigs.flat
  let type: TipType = 'flat'
  if (nonPromoSubtotal >= percentageMinimumUsCents) {
    options = tipOptionConfigs.percentage
    type = 'percentage'
  }

  const defaultIndex = Math.floor(options.length / 2)
  const value = options[defaultIndex].value
  const newTipCents = type === 'flat' ? value : Math.round(Math.round(nonPromoSubtotal * value * 100) / 100)

  return { nonPromoSubtotal, options, type, newTipCents, defaultIndex }
}

export function getOrderTimingConfigFromAvailability(
  availability: MerchantAvailability,
  intermissionConfig: boolean
): OrderTimingConfig {
  if (availability.state === MerchantAvailabilityState.Opened && !!availability.nextClose) {
    // HACK - remove soon
    if (intermissionConfig) {
      return {
        selections: [
          {
            displayName: 'ASAP',
            timing: OrderTiming.Asap
          },
          {
            displayName: 'Intermission',
            timing: OrderTiming.Asap
          }
        ]
      }
    }

    const scheduledSelections: OrderTimingSelection[] = [5, 15, 30, 45, 60]
      .filter((m) => m < availability.nextClose!!.minutesUntil)
      .map((m) => ({
        displayName: `${m} Minutes`,
        timing: OrderTiming.OnTimer,
        placeInSeconds: 60 * m
      }))
    const selections = [
      {
        displayName: 'ASAP',
        timing: OrderTiming.Asap
      },
      ...scheduledSelections
    ]
    return { selections }
  } else if (availability.state === MerchantAvailabilityState.Closed && !!availability.nextOpen) {
    const selections: OrderTimingSelection[] = [
      {
        timing: OrderTiming.OnTimer,
        date: availability.nextOpen.date,
        displayName: formatDateSelection(availability.nextOpen.date)
      }
    ]

    let x = 0
    while (x < 60) {
      x += 15
      const nextSelectionDate = new Date(availability.nextOpen.date.getTime() + x * 60 * 1000)
      selections.push({
        timing: OrderTiming.OnTimer,
        date: nextSelectionDate,
        displayName: formatDateSelection(nextSelectionDate)
      })
    }
    return { selections }
  }

  throw new Error('Cannot generate order timing config from availability state')
}

function formatDateSelection(date: Date): string {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  })
}
