import { SelectedModifier } from '@bloom-coffee/espresso'
import { Spinner, Text } from '@bloom-coffee/steamed-milk'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useProductsForQuickCartLazyQuery } from '../../graphql/types.generated'
import { logger } from '../../logger'
import { useCart } from '../../providers/cart/CartProvider'
import { addToCart } from '../../service/CartService'

export const QuickCart = () => {
  const [fetched, setFetched] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const itemIds = searchParams.get('items')?.split(',')

  const { notifyCartUpdated } = useCart()

  const [fetchProducts, { data: productsData, loading: loadingProducts, error }] = useProductsForQuickCartLazyQuery({
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (productsData?.products.length) {
      for (let product of productsData.products) {
        logger.debug('QuickCart', `Adding ${product.name} to cart`)
        const selectedModifiers: SelectedModifier[] = []
        for (let topModifier of product.modifiers || []) {
          const defaultSelections = topModifier.defaultSelectionIds || []
          for (let secondLevelModifier of topModifier.children || []) {
            if (defaultSelections.includes(secondLevelModifier.id)) {
              logger.debug('QuickCart', `Adding default ${secondLevelModifier.name} to selected modifiers`)
              selectedModifiers.push({ topModifier, secondLevelModifier })
            }
          }
        }
        addToCart(product.merchant, product, selectedModifiers, 1, '')
      }
      notifyCartUpdated()
      navigate('/cart')
    }
  }, [navigate, notifyCartUpdated, productsData])

  useEffect(() => {
    if (itemIds?.length && !fetched) {
      logger.debug('QuickCart', `fetching item ids ${itemIds.join(', ')}`)
      setFetched(true)
      fetchProducts({ variables: { ids: itemIds } })
    }
  }, [itemIds, fetchProducts, fetched])

  return (
    <div>
      {loadingProducts && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: 8 }}>
            <Text variant='body2'>Loading Cart...</Text>
          </div>
          <Spinner size='small' color='primary' />
        </div>
      )}

      {error && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: 8 }}>
            <Text variant='body2'>We cannot find this product</Text>
          </div>
        </div>
      )}
    </div>
  )
}
