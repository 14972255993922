import { FulfillmentLocationInfo } from '@bloom-coffee/espresso'

import { Select } from './Select'

interface ServingLocationProps {
  initialValue: FulfillmentLocationInfo | undefined
  servingLocations: FulfillmentLocationInfo[]
  onChange: (servingLocation: FulfillmentLocationInfo) => void
}

export const ServingLocationSelector = (props: ServingLocationProps) => {
  const { initialValue, servingLocations, onChange } = props

  function updateServingLocationId(newId: string) {
    onChange(servingLocations.find((sl) => sl.id === newId)!!)
  }

  return (
    <Select
      initialValue={initialValue?.id}
      options={servingLocations.map((sl) => {
        return {
          id: sl.id,
          displayName: sl.displayName
        }
      })}
      onChange={updateServingLocationId}
    />
  )
}
