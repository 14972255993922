import { ProductInfo } from '@bloom-coffee/espresso'
import { Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { sortOrderSort } from '../../../utils/sorting'
import { hasImage } from '../../Product/UxUtils'
import { ProductImageContainer } from './ProductImageContainer'
import { ProductListContainer } from './ProductListContainer'

interface CategoryContainerProps {
  disabled?: boolean
  items: ProductInfo[]
  onPressItem: (product: ProductInfo) => void
  categoryName?: string
  topProductIds: string[]
  trendingProductIds: string[]
}

export const CategoryContainer = (props: CategoryContainerProps) => {
  const { items, onPressItem, categoryName, topProductIds, trendingProductIds } = props
  const sortedCategories = items.sort(sortOrderSort)
  const productsWithImages = sortedCategories.filter((product) => hasImage(product))

  if (items.length === 0) {
    return null
  }
  return (
    <div>
      {!!categoryName && (
        <div style={{ marginBottom: 8, display: 'flex', justifyContent: 'center' }}>
          <Text variant='header2'>{categoryName}</Text>
        </div>
      )}
      {productsWithImages.length > 0 ? (
        <ProductImageContainer
          variant='light'
          products={sortedCategories}
          trendingProductIds={trendingProductIds}
          topProductIds={topProductIds}
          onPressItem={onPressItem}
        />
      ) : (
        <ProductListContainer
          products={sortedCategories}
          trendingProductIds={trendingProductIds}
          topProductIds={topProductIds}
          onPressItem={onPressItem}
        />
      )}
    </div>
  )
}
