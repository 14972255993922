import { useAuth0 } from '@auth0/auth0-react'
import { Button, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { config } from '../../config'
import { Requisitions } from './Requisitions'

export const Account = () => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0()

  function login() {
    loginWithRedirect({
      redirectUri: `${config.baseUrl}/beans/account`
    })
  }

  function executeLogout() {
    logout({
      returnTo: `${config.baseUrl}/beans/account`
    })
  }

  return (
    <div style={{ paddingTop: 24, paddingLeft: 12 }}>
      {isAuthenticated && (
        <div style={{ marginBottom: 20 }}>
          <div>
            <Text variant='header1'>Purchases</Text>
          </div>
          <div style={{ paddingTop: 12 }}>
            <Requisitions />
          </div>
        </div>
      )}

      {!isAuthenticated ? (
        <div>
          <Button theme='action' onClick={() => login()} size='medium' label='Log In' />
        </div>
      ) : (
        <div>
          <Button theme='action' onClick={() => executeLogout()} size='medium' label='Log Out' />
        </div>
      )}
    </div>
  )
}
