import { Button, Spinner, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import { config } from '../../config'
import { useMerchantsForOrganizationSplashQuery } from '../../graphql/types.generated'

export const Organization = () => {
  const { readableOrganizationId } = useParams()

  const { data, loading } = useMerchantsForOrganizationSplashQuery({
    fetchPolicy: 'cache-first',
    variables: { readableOrganizationId: readableOrganizationId! }
  })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Helmet>
        <title>{`${
          data?.merchantsByReadableOrganizationId[0]?.organization.name ?? 'Independent Coffee'
        } - RDY`}</title>
        <meta
          name='description'
          // eslint-disable-next-line max-len
          content={`Order-ahead online from ${data?.merchantsByReadableOrganizationId[0]?.organization.name} through RDY. Skip the line and enjoy great independent coffee!`}
        />
      </Helmet>
      <div>
        {loading ? (
          <Spinner size='small' color='primary' />
        ) : (
          <Text variant='header1'>{data?.merchantsByReadableOrganizationId[0]?.organization.name}</Text>
        )}
      </div>

      <div>
        {!!data?.merchantsByReadableOrganizationId.length && <Text variant='subheader1'>Select your location:</Text>}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 12 }}>
        {data?.merchantsByReadableOrganizationId
          .filter((m) => !!m.readableId)
          .map((m) => {
            return (
              <div style={{ marginBottom: 12 }}>
                <Button
                  label={m.name}
                  onClick={() => window.open(`${config.baseUrl}/${m.readableId}`, '_blank')}
                  theme='action'
                  size='large'
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}
