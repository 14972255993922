import { OrderStateInfo, OrderStateName } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import Icon from '../../../components/RdyIcon/Icon'
import { RdyIconName } from '../../../components/RdyIcon/RdyIconName'

interface OrderStatusBarContainerProps {
  orderState: OrderStateInfo
}

export const OrderStatusBarContainer = (props: OrderStatusBarContainerProps) => {
  const { name } = props.orderState

  const statesToShow = ['Confirmed', 'Preparing', 'Rdy']
  const icons = [RdyIconName.SUN, RdyIconName.CLOCKFACE, RdyIconName.THUMBSUP]
  let completed = [false, false, false]

  switch (name) {
    case OrderStateName.PickedUpFromRdyPass:
    case OrderStateName.ReadyForPickupWithRdyPass:
    case OrderStateName.ReadyForPickupWithTraditionalPass:
      completed = [true, true, true]
      break
    case OrderStateName.Preparing:
      completed = [true, true, false]
      break
    case OrderStateName.PaymentFulfilled:
      completed = [true, false, false]
      break
    default:
      break
  }

  const statusBarComponents = statesToShow.map((state, index) => {
    const stageCompleted = completed[index]
    const backgroundColor = stageCompleted ? Color.RDY_GOLD : Color.RDY_BEIGE
    const textColor = stageCompleted ? Color.RDY_GOLD : Color.RDY_DARK_GREY
    return (
      <div style={{ flex: 1, marginLeft: 6, marginRight: 6 }} key={index}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingBottom: 4,
            paddingLeft: 6,
            paddingRight: 6,
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Text variant='header3' color={textColor}>
            {state}
          </Text>
          {stageCompleted && <Icon color={textColor} icon={icons[index]} size={16} />}
        </div>

        <div style={{ height: 10, borderRadius: 10, backgroundColor }} />
      </div>
    )
  })

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 48, alignItems: 'flex-end' }}>
      {statusBarComponents}
    </div>
  )
}
