import {
  CategoryType,
  formatUsCentsAsUsDollar,
  getDefaultRawItemPriceTag,
  getTagValuesByType,
  isProductCold,
  Maybe,
  ModifierInfo,
  ProductImageInfo,
  ProductInfo,
  ProductTag,
  ProductTagType,
  RoastType
} from '@bloom-coffee/espresso'
import { Color, ImageAssetDetails, ImageCardItem } from '@bloom-coffee/steamed-milk'

import ImageAsset from '../../img/ImageAsset'

const ESPRESSO_TAG = 'Espresso'
const CORTADO_TAG = 'Cortado'
const CAPPUCCINO_TAG = 'Cappuccino'
const LATTE_TAG = 'Latte'
const FLAVORED_TAG = 'Flavored'
const AMERICANO_TAG = 'Americano'
const FLAT_WHITE_TAG = 'Flat White'
const MACCHIATO_TAG = 'Macchiato'
const MOCHA_TAG = 'Mocha'
const COLD_BREW_TAG = 'Cold Brew'
const DRIP_COFFEE_TAG = 'Drip'
const FILTER_COFFEE_TAG = 'Filter'
const POUROVER_TAG = 'Pour Over'
const MATCHA_TAG = 'Matcha'
const CHAI_TAG = 'Chai'

export enum BadgeType {
  TOP,
  TRENDING
}

export function getProductImageFromProduct(product: ProductInfo): ImageAssetDetails {
  return getProductImage(
    product.name,
    product.productTags || [],
    product.modifiers || [],
    product.images || [],
    product.category?.type,
    product.id
  )
}

export function getProductImage(
  name: string,
  tags: ProductTag[],
  modifiers: ModifierInfo[] = [],
  images: ProductImageInfo[],
  categoryType: Maybe<CategoryType>,
  productId: string
): ImageAssetDetails {
  let imageAssetDetails: ImageAssetDetails
  const isCold = isProductCold(name, modifiers)
  if (images && images.length > 0 && !!images[0].imageUri) {
    imageAssetDetails = {
      type: 'image',
      source: images[0].imageUri
    }
  } else {
    switch (categoryType) {
      case CategoryType.SpecialtyBeverages:
        imageAssetDetails = getSpecialtyImageFromTags(productId, tags, isCold)
        break
      case CategoryType.Coffee:
        imageAssetDetails = getCoffeeIconFromTags(tags, isCold)
        break
      case CategoryType.Tea:
        imageAssetDetails = getTeaImageFromTags(tags, isCold)
        break
      case CategoryType.NotCoffee:
        imageAssetDetails = {
          source: isCold ? ImageAsset.PRODUCT_GLYPH_DRINK_ICED : ImageAsset.PRODUCT_GLYPH_DRINK,
          type: 'glyph'
        }
        break
      case CategoryType.Food:
        imageAssetDetails = { source: ImageAsset.PRODUCT_GLYPH_FOOD, type: 'glyph' }
        break
      case CategoryType.RetailMerch:
        imageAssetDetails = { source: ImageAsset.PRODUCT_GLYPH_RETAIL, type: 'glyph' }
        break
      case CategoryType.RetailBeans:
        imageAssetDetails = { source: ImageAsset.PRODUCT_GLYPH_RETAIL, type: 'glyph' }
        break
      case CategoryType.KitchenFood:
        imageAssetDetails = { source: ImageAsset.PRODUCT_GLYPH_FOOD, type: 'glyph' }
        break
      default:
        imageAssetDetails = { source: ImageAsset.PRODUCT_IMAGE_COFFEE, type: 'image' }
    }
  }

  return {
    ...imageAssetDetails,
    backgroundColor: isCold ? Color.RDY_MALIBU : Color.RDY_RED
  }
}

export function hasImage(product: ProductInfo) {
  return !!product.images?.length || getProductImageFromProduct(product).type === 'image'
}

function getSpecialtyImageFromTags(productId: string, tags: ProductTag[], isCold: boolean): ImageAssetDetails {
  let source = isCold ? ImageAsset.PRODUCT_GLYPH_DRINK_ICED : ImageAsset.PRODUCT_GLYPH_DRINK
  let type: 'image' | 'glyph' = 'glyph'
  let dynamicIconIndex

  const espressoDynamicImages = [
    ImageAsset.PRODUCT_IMAGE_DYNAMIC_ESPRESSO_1,
    ImageAsset.PRODUCT_IMAGE_DYNAMIC_ESPRESSO_3
  ]

  const latteDynamicImages = [
    ImageAsset.PRODUCT_IMAGE_DYNAMIC_LATTE_2,
    ImageAsset.PRODUCT_IMAGE_DYNAMIC_LATTE_ALT,
    ImageAsset.PRODUCT_IMAGE_DYNAMIC_MILK_STEAM
  ]

  tags
    .filter((t) => t.value !== FLAVORED_TAG && t.tagType === ProductTagType.Coffee)
    .forEach((ct) => {
      switch (ct.value) {
        case ESPRESSO_TAG:
          dynamicIconIndex = parseInt(productId) % espressoDynamicImages.length
          source = espressoDynamicImages[dynamicIconIndex]
          type = 'image'
          break
        case LATTE_TAG:
          const allDynamicImages = [...espressoDynamicImages, ...latteDynamicImages]
          dynamicIconIndex = parseInt(productId) % allDynamicImages.length
          source = allDynamicImages[dynamicIconIndex]
          type = 'image'
          break
      }
    })

  if (tags.map((t) => t.value).includes(ESPRESSO_TAG)) {
    const iconIndex = parseInt(productId) % espressoDynamicImages.length
    return { source: espressoDynamicImages[iconIndex], type: 'image' }
  }

  const allDynamicImages = [...espressoDynamicImages, ...latteDynamicImages]
  const iconIndex = parseInt(productId) % allDynamicImages.length
  source = allDynamicImages[iconIndex]
  type = 'image'

  return { source, type }
}

function getCoffeeIconFromTags(tags: ProductTag[], isCold: boolean): ImageAssetDetails {
  let source = isCold ? ImageAsset.PRODUCT_GLYPH_DRINK_ICED : ImageAsset.PRODUCT_GLYPH_DRINK
  let type: 'image' | 'glyph' = 'glyph'
  tags
    .filter((t) => t.value !== FLAVORED_TAG && t.tagType === ProductTagType.Coffee)
    .forEach((ct) => {
      switch (ct.value) {
        case ESPRESSO_TAG:
          source = isCold ? ImageAsset.PRODUCT_IMAGE_ESPRESSO_ICED : ImageAsset.PRODUCT_IMAGE_ESPRESSO
          type = 'image'
          break
        case CORTADO_TAG:
          source = ImageAsset.PRODUCT_IMAGE_CORTADO
          type = 'image'
          break
        case LATTE_TAG:
          source = isCold ? ImageAsset.PRODUCT_IMAGE_LATTE_ICED : ImageAsset.PRODUCT_IMAGE_LATTE
          type = 'image'
          break
        case CAPPUCCINO_TAG:
          source = ImageAsset.PRODUCT_IMAGE_CAPPUCCINO
          type = 'image'
          break
        case AMERICANO_TAG:
          source = isCold ? ImageAsset.PRODUCT_IMAGE_AMERICANO_ICED : ImageAsset.PRODUCT_IMAGE_AMERICANO
          type = 'image'
          break
        case FLAT_WHITE_TAG:
          source = ImageAsset.PRODUCT_IMAGE_FLAT_WHITE
          type = 'image'
          break
        case MACCHIATO_TAG:
          source = ImageAsset.PRODUCT_IMAGE_MACCHIATO
          type = 'image'
          break
        case MOCHA_TAG:
          //todo: show iced latte instead?
          source = isCold ? ImageAsset.PRODUCT_IMAGE_LATTE_ICED : ImageAsset.PRODUCT_IMAGE_MOCHA
          type = 'image'
          break
        case COLD_BREW_TAG:
          source = ImageAsset.PRODUCT_IMAGE_COLD_BREW
          type = 'image'
          break
        case FILTER_COFFEE_TAG:
        case DRIP_COFFEE_TAG:
          source = isCold ? ImageAsset.PRODUCT_IMAGE_COFFEE_ICED : ImageAsset.PRODUCT_IMAGE_COFFEE
          type = 'image'
          break
        case POUROVER_TAG:
          source = isCold ? ImageAsset.PRODUCT_IMAGE_COFFEE_ICED : ImageAsset.PRODUCT_IMAGE_POUROVER
          type = 'image'
          break
      }
    })
  return { source, type }
}

function getTeaImageFromTags(tags: ProductTag[], isCold: boolean): ImageAssetDetails {
  let source = isCold ? ImageAsset.PRODUCT_GLYPH_TEA_ICED : ImageAsset.PRODUCT_GLYPH_TEA
  let type: 'image' | 'glyph' = 'glyph'
  tags
    .filter((t) => t.value !== FLAVORED_TAG && t.tagType === ProductTagType.Tea)
    .forEach((ct) => {
      switch (ct.value) {
        case MATCHA_TAG:
          source = isCold ? ImageAsset.PRODUCT_IMAGE_MATCHA_ICED : ImageAsset.PRODUCT_IMAGE_MATCHA
          type = 'image'
          break
        case CHAI_TAG:
          source = isCold ? ImageAsset.PRODUCT_IMAGE_CHAI_ICED : ImageAsset.PRODUCT_IMAGE_CHAI
          type = 'image'
          break
      }
    })
  return { source, type }
}

export function productsToImageCardItems(
  products: ProductInfo[],
  topProductIds: string[] = [],
  trendingProductIds: string[] = [],
  actionText?: string
) {
  return products.map((product) => productToImageCardItem(product, topProductIds, trendingProductIds, actionText)) || []
}

export function getRoastTypeDisplay(roastType: RoastType) {
  switch (roastType) {
    case RoastType.Light:
      return 'Light Roast'
    case RoastType.Medium:
      return 'Medium Roast'
    case RoastType.Dark:
      return 'Dark Roast'
  }
}

function productToImageCardItem(
  product: ProductInfo,
  topProductIds: string[] = [],
  trendingProductIds: string[] = [],
  actionText?: string
) {
  if (product.category?.type === CategoryType.RetailBeans) {
    return retailBeansToImageCardItem(product, actionText)
  }

  const image = getProductImageFromProduct(product)
  let tagValue: string | undefined
  let tagColor: string | undefined
  if (trendingProductIds.includes(product.id)) {
    tagValue = 'Trending'
    tagColor = Color.RDY_GOLD
  } else if (topProductIds.includes(product.id)) {
    tagValue = 'Popular'
    tagColor = Color.RDY_RED
  }
  return {
    id: product.id,
    value: product,
    image,
    title: `${product.name}`,
    subtitle: '$' + formatUsCentsAsUsDollar(getDefaultRawItemPriceTag(product).priceUsCents),
    actionText,
    tagValue,
    tagColor
  } as ImageCardItem<ProductInfo>
}

function retailBeansToImageCardItem(product: ProductInfo, actionText?: string) {
  const image = getProductImageFromProduct(product)
  const originTags = getTagValuesByType(product.productTags, ProductTagType.BeanOrigin)
  const singleOrigin = originTags?.length === 1
  return {
    id: product.id,
    value: product,
    image,
    title: product.roaster ? `${product.roaster} - ${product.name}` : product.name,
    subtitle: product.roastType
      ? getRoastTypeDisplay(product.roastType)
      : getTagValuesByType(product.productTags, ProductTagType.FlavorNotes)?.join(', '),
    actionText,
    tagValue: singleOrigin ? 'Single Origin' : 'Blend',
    tagColor: singleOrigin ? 'primary' : 'secondary'
  } as ImageCardItem<ProductInfo>
}
