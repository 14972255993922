import * as yup from 'yup'

export const addressFormSchema = yup.object({
  first: yup.string().label('First Name').required(),
  last: yup.string().label('Last Name').required(),
  street: yup.string().label('Address Line 1').required(),
  street2: yup.string().label('Address Line 2 (Optional)').nullable(true).optional(),
  city: yup.string().label('City').required(),
  state: yup.string().label('State').required(),
  zipcode: yup
    .string()
    .label('Zip Code')
    .test({
      message: 'Invalid Zip Code',
      test: (val) => {
        if (!val) {
          return false
        }
        const regEx = /^[0-9]{5}(?:-[0-9]{4})?$/
        return regEx.test(val)
      }
    })
    .required()
})

export type AddressFormModel = yup.InferType<typeof addressFormSchema>
