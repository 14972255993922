import { CategoryType, ModifierInfo, ProductInfo } from '@bloom-coffee/espresso'
import { Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { getProductImage } from '../UxUtils'

interface ProductHeaderProps {
  product: ProductInfo
  topModifierState?: ModifierInfo[]
}

export const ProductHeader = (props: ProductHeaderProps) => {
  const { product, topModifierState } = props

  const windowWidth = window.innerWidth

  const image = getProductImage(
    product.name,
    product.productTags || [],
    topModifierState || [],
    product.images || [],
    product.category?.type,
    product.id
  )
  const _useImageStyle = image.type === 'image'
  const imageScale = _useImageStyle ? 1.0 : 0.7

  let _productTileWidth = Math.floor(windowWidth * 0.25)
  if (windowWidth > 900) {
    _productTileWidth = 180
  } else {
    if (product?.category?.type === CategoryType.RetailBeans) {
      _productTileWidth = Math.floor(windowWidth * 0.95) - 72
    } else if (_useImageStyle) {
      _productTileWidth = Math.floor(windowWidth * 0.5)
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 12,
          paddingRight: 12,
          justifyContent: 'center'
        }}
      >
        <div>
          <Text variant='header1'>
            {product?.category?.type === CategoryType.RetailBeans
              ? product.roaster
                ? `${product.roaster} - ${product.name}`
                : product.name
              : product?.name}
          </Text>
        </div>

        <div>
          <Text variant='body1'>
            {product?.category?.type === CategoryType.RetailBeans ? product.name : product?.description}
          </Text>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: _productTileWidth,
          width: _productTileWidth,
          backgroundColor: _useImageStyle ? undefined : image.backgroundColor
        }}
      >
        {_useImageStyle ? (
          <img
            alt='imgStyle'
            src={image.source}
            width={_productTileWidth * imageScale}
            height={_productTileWidth * imageScale}
            style={{ borderRadius: 8, objectFit: 'cover' }}
          />
        ) : (
          <img alt='glyph' src={image.source} height={_productTileWidth * imageScale} style={{ borderRadius: 8 }} />
        )}
      </div>
    </div>
  )
}
