import {
  orderDiningOptionToLanguage,
  OrderEventName,
  OrderInfo,
  readableDateFromInstant,
  readableTimeFromInstant
} from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { config } from '../../../../config'
import { OrderReceiptItemContainer } from './OrderReceiptItemContainer'
import { OrderTransactionContainer } from './OrderTransactionContainer'

interface OrderReceiptContainerProps {
  order: OrderInfo
}

export const OrderReceiptContainer = (props: OrderReceiptContainerProps) => {
  const { order } = props

  const dateCreated = `${readableDateFromInstant(order.createdOn || 0)}, ${readableTimeFromInstant(
    order.createdOn || 0
  )}`
  const paymentRejected: boolean = !!order.events?.filter((e) => e.name === OrderEventName.PaymentRejected).length

  return (
    <div>
      <div style={{ paddingTop: 12, marginBottom: 16 }}>
        <div>
          <a style={{ textDecoration: 'none' }} href={`${config.baseUrl}/${order.merchant?.readableId}`}>
            <Text variant='header1' color={Color.RDY_FOREST}>
              {order.merchant?.name}
            </Text>
          </a>
        </div>

        <div>
          <Text variant='body1' color={Color.RDY_DARK_GREY}>
            {dateCreated ? dateCreated.toString() : ''} {'\u2022'} Order No. {order.id}{' '}
            {order.diningOption ? `\u2022 ${orderDiningOptionToLanguage[order.diningOption]}` : ''}
          </Text>
        </div>
      </div>

      <div style={{ marginBottom: 16 }}>
        {order.items?.map((oItem) => {
          return (
            <div key={oItem.id} style={{ marginBottom: 8 }}>
              <OrderReceiptItemContainer orderItem={oItem} />
            </div>
          )
        })}
      </div>

      {!!order.transactions?.length && (
        <div>
          {order.transactions.map((tr) => (
            <OrderTransactionContainer key={`tr.${tr.id}`} transaction={tr} />
          ))}
        </div>
      )}

      {paymentRejected && (
        <div>
          <Text variant='error'>Your payment was declined for this order</Text>
        </div>
      )}

      {!!order.rewards?.length && (
        <Text variant='body1' color={Color.RDY_MALIBU}>{`Earned ${order.rewards.reduce(
          (acc, next) => acc + next.points,
          0
        )} reward points`}</Text>
      )}
    </div>
  )
}
