import { Button } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { TextField } from '../../components/TextField'
const codeSchema = yup.object({
  code: yup.string().label('Promo Code').optional().default(null)
})
type CodeModel = yup.InferType<typeof codeSchema>

interface PromoCodeContainerProps {
  onCode: (code: string) => void
}
export const PromoCodeContainer = (props: PromoCodeContainerProps) => {
  const formProps = useForm<CodeModel>({
    resolver: yupResolver(codeSchema)
  })
  const { handleSubmit, register } = formProps

  function onSubmit(form: CodeModel) {
    props.onCode(form.code)
  }

  const textFieldWidth = window.innerWidth > 400 ? 200 : 150

  return (
    <div>
      <FormProvider {...formProps}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 8, paddingBottom: 8 }}>
          <div style={{ marginBottom: 8 }}>
            <TextField name='code' label='Promo Code' ref={register()} width={textFieldWidth} />
          </div>

          <div>
            <Button theme='action' size='small' label='Add' onClick={handleSubmit(onSubmit)} />
          </div>
        </div>
      </FormProvider>
    </div>
  )
}
