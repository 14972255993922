import { Color, Text } from '@bloom-coffee/steamed-milk'
import React, { useEffect, useState } from 'react'
import { get, useFormContext } from 'react-hook-form'

import { TextFieldProps } from './models'

export const TextField = React.forwardRef((props: TextFieldProps, ref?: any) => {
  const { name, width, label, ...rest } = props
  const [error, setError] = useState<string | undefined>()

  const methods = useFormContext()
  useEffect(() => {
    const errMessage = get(methods.errors, name)?.message
    if (errMessage) {
      setError(errMessage)
    }
  }, [methods.errors, name])

  return (
    <div>
      <input
        style={{ border: `1px solid ${Color.RDY_FOREST}`, padding: '12px 8px 12px 8px', fontSize: 14, width }}
        name={name}
        placeholder={label}
        ref={ref}
        {...rest}
      />
      {!!error && (
        <div style={{ marginTop: 4 }}>
          <Text variant='error'>{error}</Text>
        </div>
      )}
    </div>
  )
})
