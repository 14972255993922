import { MerchantAvailabilityState } from '@bloom-coffee/espresso'
import { Color, Spinner, Text } from '@bloom-coffee/steamed-milk'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'

import { useCart } from '../../providers/cart/CartProvider'
import { fetchServingLocation, removeServingLocation, saveServingLocation } from '../../service/ServingLocationService'
import { Catalog } from '../Catalog/Catalog'
import { MerchantHeader } from './components/MerchantHeader'
import { useMerchantCatalog } from './components/useMerchantCatalog'
import { useMerchantOverview } from './components/useMerchantOverview'

export const Merchant = () => {
  const { readableMerchantId, servingLocationName } = useParams()

  const { info, loading: loadingMerchant } = useMerchantOverview(readableMerchantId!)
  const { loading: loadingCatalog, catalog, categories, productsByCategoryId } = useMerchantCatalog(readableMerchantId!)

  const { cart } = useCart()
  const navigate = useNavigate()

  function goToCart() {
    navigate('/cart')
  }

  useEffect(() => {
    if (info) {
      let existingServiceLocation = fetchServingLocation()
      if (servingLocationName) {
        let servingLocation = info.servingLocations?.find((s) => s.urlSafeName === servingLocationName)
        if (servingLocation) {
          saveServingLocation(servingLocation)
        } else {
          removeServingLocation()
        }
      } else if (existingServiceLocation) {
        if (!info.servingLocations?.find((s) => s.id === existingServiceLocation?.id)) {
          removeServingLocation()
        }
      }
    }
  }, [servingLocationName, info])

  const width = window.innerWidth > 600 ? window.innerWidth * 0.6 : window.innerWidth * 0.9

  return (
    <div>
      <Helmet>
        <title>{`${info?.name ?? 'Independent Coffee'} - RDY`}</title>
        <meta
          name='description'
          // eslint-disable-next-line max-len
          content={`Order-ahead online from ${info?.name ?? 'your favorite independent cafes'}${
            info?.location ? ` in ${info?.location.city}, ${info.location.state}` : ''
          } through RDY. Skip the line and enjoy great independent coffee!`}
        />
      </Helmet>
      <div style={{ display: 'flex', position: 'relative', justifyContent: 'center', paddingBottom: 48 }}>
        <div style={{ width }}>
          {(loadingMerchant || loadingCatalog) && (
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <Spinner color='primary' size='medium' />
            </div>
          )}
          {info && <MerchantHeader info={info} organizationId={info.organization!.id} showMerchantSwitch={true} />}

          {!!catalog && !!categories && !!productsByCategoryId && (
            <Catalog
              readableMerchantId={readableMerchantId!}
              catalog={catalog}
              categories={categories}
              productsByCategoryId={productsByCategoryId}
              merchantOpen={info?.availability?.state === MerchantAvailabilityState.Opened}
              bottomSpace={70}
            />
          )}
        </div>
      </div>
      {!!cart?.items.length && (
        <button
          onClick={() => goToCart()}
          style={{
            border: `1px solid ${Color.RDY_FOREST}`,
            backgroundColor: Color.RDY_PINK,
            position: 'fixed',
            display: 'flex',
            bottom: 0,
            left: 0,
            right: 0,
            paddingTop: 8,
            paddingBottom: 8,
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
        >
          <div style={{ flex: 1 }}></div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <div>
              <Text color={Color.WHITE} variant='body3'>
                {cart.merchant.name}
              </Text>
            </div>
            <div>
              <Text color={Color.RDY_FOREST} variant='subheader2'>
                VIEW CART
              </Text>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              paddingTop: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Text color={Color.WHITE} variant='body2'>
              {cart.items.length === 1 ? '1 item' : `${cart.items.length} items`}
            </Text>
          </div>
        </button>
      )}
    </div>
  )
}
