import { ProductInfo } from '@bloom-coffee/espresso'
import { ImageCard, ImageCardItem } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { productsToImageCardItems } from '../../Product/UxUtils'

interface ProductImageContainerProps {
  products: ProductInfo[]
  onPressItem: (product: ProductInfo) => void
  topProductIds: string[]
  trendingProductIds: string[]
  variant?: 'light' | 'dark'
  disabled?: boolean
}

export const ProductImageContainer = (props: ProductImageContainerProps) => {
  const { products, onPressItem, topProductIds, trendingProductIds, variant = 'light', disabled } = props
  const imageCards = productsToImageCardItems(products || [], topProductIds, trendingProductIds)
  return (
    <div>
      {imageCards.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
          {imageCards.map((item: ImageCardItem<ProductInfo>) => {
            return (
              <div key={item.id} style={{ margin: 8 }}>
                <ImageCard
                  variant={variant}
                  card={item}
                  onSelect={onPressItem}
                  size='medium'
                  clickDisabled={disabled}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
