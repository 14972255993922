import { formatUsCentsAsUsDollar, OrderTransactionRefundInfo, readableDateFromInstant } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

export const RefundContainer = (props: { refund: OrderTransactionRefundInfo }) => {
  const { refund } = props

  return (
    <div>
      {refund.amountUsCents > 0 && (
        <Text
          variant='body1'
          key={`refund.${refund.id}`}
          color={Color.RDY_MALIBU}
        >{`Refund for $${formatUsCentsAsUsDollar(refund.amountUsCents)} initialized on ${readableDateFromInstant(
          refund.initializedOn
        )}`}</Text>
      )}

      {refund.tokenValue > 0 && (
        <Text variant='body1' key={`refund.${refund.id}`} color={Color.RDY_MALIBU}>{`Refund for ${
          refund.tokenValue
        } tokens initialized on ${readableDateFromInstant(refund.initializedOn)}`}</Text>
      )}
    </div>
  )
}
