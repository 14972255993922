import {
  Maybe,
  OrderEventName,
  OrderInfo,
  OrderItemEventName,
  OrderStateInfo,
  OrderStateName,
  PositionCode
} from '@bloom-coffee/espresso'
import { useEffect, useState } from 'react'

import { useOrderByUuidQuery } from '../../../../graphql/types.generated'
import { logger } from '../../../../logger'

export type PositionStateName = 'none' | 'notPickedUp' | 'pickedUp' | 'shouldBeHere' | 'skipped'

interface PositionState {
  state: PositionStateName
  orderItemDetails?: {
    name: string
    modifiers: string
  }
}
export type RdyPassState = Record<PositionCode, PositionState>

export interface PassConfig {
  useRdyPass: boolean
  rdyPassState: RdyPassState
}

const passSkipEvents: OrderItemEventName[] = [
  OrderItemEventName.SkippedDeviceError,
  OrderItemEventName.SkippedDeviceFlagDisabled,
  OrderItemEventName.SkippedDeviceManual,
  OrderItemEventName.SkippedDeviceNotConnected,
  OrderItemEventName.SkippedDeviceNotPassable,
  OrderItemEventName.SkippedDevicePositionsFull,
  OrderItemEventName.SkippedDeviceTooManyItems
]

const stopPollingStates: OrderStateName[] = [
  OrderStateName.PaymentRejected,
  OrderStateName.ReadyForPickupWithRdyPass,
  OrderStateName.ReadyForPickupWithTraditionalPass
]

interface OrderStateReact {
  orderState: Maybe<OrderStateInfo>
  placedOn: Maybe<number>
  passConfig: Maybe<PassConfig>
  order: Maybe<OrderInfo>
  isRdy: boolean
}

const initialReactState: OrderStateReact = {
  orderState: null,
  placedOn: null,
  passConfig: null,
  order: null,
  isRdy: false
}

export function useOrderState(uuid: string) {
  const [state, setState] = useState<OrderStateReact>(initialReactState)

  const { data, stopPolling, startPolling, error } = useOrderByUuidQuery({
    variables: { uuid }
  })

  useEffect(() => {
    function checkStopPolling(stateName: OrderStateName) {
      if (stopPollingStates.includes(stateName)) {
        logger.debug(
          'userOrderState',
          'stop polling for states because found a stop polling state: ' + stopPollingStates.join(', ')
        )
        stopPolling()
      } else {
        startPolling(10000)
      }
    }
    if (state.orderState?.name) {
      logger.debug('useOrderState', 'Order state updated, checking stop polling')
      checkStopPolling(state.orderState.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    function getPassConfig(order: OrderInfo): PassConfig {
      const rdyPassState: RdyPassState = {
        [PositionCode.RdyPass_0]: { state: 'none' },
        [PositionCode.RdyPass_1]: { state: 'none' },
        [PositionCode.RdyPass_2]: { state: 'none' },
        [PositionCode.RdyPass_3]: { state: 'none' }
      }

      let passSuggested = false
      let passSkipped = false
      for (let orderItem of order.items || []) {
        let thisItemPositionCode: PositionCode | undefined
        let positionStateName: PositionStateName = 'none'
        let orderItemDetails: { name: string; modifiers: string } | undefined

        for (let orderItemEvent of orderItem.events || []) {
          if (orderItemEvent.ioTPosition) {
            thisItemPositionCode = orderItemEvent.ioTPosition.code
            if (!orderItemDetails) {
              const modifiers =
                orderItem.selectedModifiers
                  ?.map((oiMod) => oiMod.name)
                  .filter((name: string | undefined | null) => !!name)
                  .join(', ') || ''
              orderItemDetails = { name: orderItem.name, modifiers }
            }
          }
          const name = orderItemEvent.name
          if (name === OrderItemEventName.PutativelyMovedToDevice) {
            if (positionStateName !== 'skipped' && positionStateName !== 'pickedUp') {
              positionStateName = 'shouldBeHere'
            }
          } else if (name === OrderItemEventName.PutativelyRemovedFromDevice) {
            positionStateName = 'pickedUp'
          } else if (passSkipEvents.includes(name)) {
            passSkipped = true
            positionStateName = 'skipped'
          }
        }
        if (thisItemPositionCode) {
          rdyPassState[thisItemPositionCode] = { state: positionStateName, orderItemDetails }
          passSuggested = true
        }
      }
      const useRdyPass = passSuggested && !passSkipped
      return { useRdyPass, rdyPassState }
    }

    if (data?.orderByUuid) {
      logger.debug('useOrderState', 'reconfiguring order state because of data change')

      const order = data.orderByUuid
      const placedEvent = order.events?.filter((e) => e.name === OrderEventName.Placed)[0]
      const placedOn = placedEvent?.createdOn
      const passConfig = getPassConfig(order)
      const orderState = order.state
      const isRdy =
        !!data.orderByUuid.state?.name &&
        [
          OrderStateName.ReadyForPickupWithTraditionalPass,
          OrderStateName.ReadyForPickupWithRdyPass,
          OrderStateName.PickedUpFromRdyPass
        ].includes(data.orderByUuid.state.name)

      setState({
        placedOn,
        passConfig,
        orderState,
        isRdy,
        order
      })
    }
  }, [data])

  return { ...state, error }
}
