import { CatalogInfo, CategoryInfo, Maybe, ProductInfo } from '@bloom-coffee/espresso'
import { useEffect, useState } from 'react'

import { useMerchantCatalogQuery } from '../../../graphql/types.generated'
import { logger } from '../../../logger'

export function useMerchantCatalog(readableId: string, initialCatalog?: CatalogInfo) {
  const [catalog, setCatalog] = useState<CatalogInfo | undefined>()
  const [categories, setCategories] = useState<CategoryInfo[] | undefined>()
  const [productsByCategoryId, setProductsByCategoryId] = useState<Record<string, ProductInfo[]> | undefined>()

  const { data, loading } = useMerchantCatalogQuery({
    skip: !!initialCatalog,
    fetchPolicy: 'cache-first',
    variables: { readableId }
  })

  useEffect(() => {
    if (!catalog) {
      let newCatalog: Maybe<CatalogInfo> = initialCatalog || data?.merchantByReadableId?.activeCatalog
      if (newCatalog) {
        logger.debug('useMerchantCatalog', 'appending category to products and setting catalog')
        const _categories: Array<CategoryInfo> = []
        const _productsByCategoryId: Record<string, ProductInfo[]> = {}
        const categorySeen: Record<string, boolean> = {}

        for (let activeProduct of newCatalog.activeProducts || []) {
          if (!!activeProduct.category) {
            const categoryId = activeProduct.category.id
            if (!categorySeen[categoryId]) {
              categorySeen[categoryId] = true
              _categories.push(activeProduct.category)
            }
            const productsForThisCategory = _productsByCategoryId[categoryId] || []
            productsForThisCategory.push(activeProduct)
            _productsByCategoryId[categoryId] = productsForThisCategory
          }
        }
        setProductsByCategoryId(_productsByCategoryId)
        setCategories(_categories)
        setCatalog(newCatalog)
      }
    }
  }, [data, catalog, initialCatalog])

  return { loading, catalog, categories, productsByCategoryId }
}
