import { CategoryType, generateReadableModifiers, OrderItemInfo } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

interface OrderReceiptItemContainerProps {
  orderItem: OrderItemInfo
}

export const OrderReceiptItemContainer = (props: OrderReceiptItemContainerProps) => {
  const { orderableProduct, selectedModifiers, notes } = props.orderItem
  const selectedModifierIds = selectedModifiers?.map((oimi) => oimi.originalModifier?.id || '') || []
  const modString =
    orderableProduct?.category?.type === CategoryType.RetailBeans
      ? orderableProduct.roaster
        ? `${orderableProduct!.roaster} - ${props.orderItem.name}`
        : props.orderItem.name
      : generateReadableModifiers(orderableProduct!, selectedModifierIds)

  const productName =
    orderableProduct?.category?.type === CategoryType.RetailBeans ? 'Whole Bean Coffee' : props.orderItem.name
  return (
    <div>
      <Text variant='subheader2'>
        {'\u2022'} {productName}
      </Text>
      {modString.length > 0 && (
        <div style={{ paddingLeft: 12 }}>
          <Text variant='body2' color={Color.BLUE_GREY_700}>
            {modString}
          </Text>
        </div>
      )}
      {!!notes && (
        <div style={{ paddingLeft: 12, marginTop: 4 }}>
          <Text variant='body2' color={Color.BLUE_GREY_700}>
            {notes}
          </Text>
        </div>
      )}
    </div>
  )
}
