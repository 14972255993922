import { MerchantAvailabilityState, MerchantInfo } from '@bloom-coffee/espresso'
import { Color, DropdownMenu, ImageCarousel, Text } from '@bloom-coffee/steamed-milk'
import { useEffect, useState } from 'react'
import { MdArrowDropDown } from 'react-icons/md'

import { config } from '../../../config'
import { useMerchantsByOrganizationQuery } from '../../../graphql/types.generated'

const switchButtonStyle: React.CSSProperties = {
  width: 150,
  padding: 5,
  border: 'none',
  backgroundColor: Color.RDY_MALIBU,
  color: Color.RDY_FOREST,
  fontFamily: 'Raw-Bold',
  fontSize: 16,
  cursor: 'pointer',
  justifyContent: 'center'
}

interface MerchantHeaderProps {
  organizationId: string
  info: MerchantInfo
  showMerchantSwitch?: boolean
}
export const MerchantHeader = (props: MerchantHeaderProps) => {
  const { info, organizationId, showMerchantSwitch = false } = props

  const [merchantSelections, setMerchantSelections] = useState<any[]>([])

  const { data } = useMerchantsByOrganizationQuery({ fetchPolicy: 'cache-first', variables: { id: organizationId } })

  const { images } = info
  const merchantImagesUris = (images || [])
    .concat()
    .sort((a, b) => {
      return a.sortOrder - b.sortOrder
    })
    .map((im) => im.imageUri)
  let hoursText = ''
  if (info.availability?.state === MerchantAvailabilityState.Opened) {
    hoursText = `Open until ${info.availability.nextClose?.formatted}`
  } else if (info.availability?.state === MerchantAvailabilityState.Closed) {
    hoursText = `Closed - Opens at ${info.availability.nextOpen?.formatted}. You can still place a scheduled order.`
  }

  function getMapsAddress(street: string, city: string, state: string, zip: string): string {
    return `http://maps.google.com/?q=${street}, ${city}, ${state}, ${zip}`
  }

  useEffect(() => {
    if (!merchantSelections.length && data?.merchantsByOrganizationId) {
      const newSelections = data.merchantsByOrganizationId
        .filter((m) => !!m.readableId)
        .map((m) => ({
          title: m.name,
          onClick: () => window.open(`${config.baseUrl}/${m.readableId}`, '_blank'),
          buttonStyle: switchButtonStyle
        }))
      setMerchantSelections(newSelections)
    }
  }, [data, merchantSelections])

  const mapsAddress = getMapsAddress(
    info.location?.street || '',
    info.location?.city || '',
    info.location?.state || '',
    info.location?.zipCode || ''
  )
  return (
    <div>
      {!!images?.length && <ImageCarousel height={window.innerHeight > 500 ? 300 : 200} srcs={merchantImagesUris} />}
      <div style={{ paddingTop: 12, paddingBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div>
            <Text variant='header1' color={Color.RDY_FOREST}>
              {info.name}
            </Text>
          </div>
          <div>
            <Text
              variant='body1'
              color={info.availability?.state !== MerchantAvailabilityState.Opened ? Color.RED : Color.RDY_FOREST}
            >
              {hoursText}
            </Text>
          </div>
          <div>
            <a style={{ color: Color.RDY_RED }} href={mapsAddress} target='_blank' rel='noreferrer'>
              <Text variant='body1'>{info.location?.street}</Text>
            </a>
          </div>
        </div>

        <div>
          {showMerchantSwitch && merchantSelections.length > 1 && (
            <div>
              <DropdownMenu
                triggerButtonStyle={{
                  paddingTop: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                  paddingBottom: 0,
                  margin: 0,
                  backgroundColor: Color.WHITE,
                  border: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  cursor: 'pointer',
                  borderRadius: 8
                }}
                menuStyle={{
                  backgroundColor: Color.RDY_MALIBU,
                  borderRadius: 1,
                  border: `solid 1px ${Color.RDY_FOREST}`
                }}
                dropdownMenuItemDetails={merchantSelections}
              >
                <Text variant='header3'>Locations</Text>
                <MdArrowDropDown size={24} color={Color.RDY_FOREST} />
              </DropdownMenu>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
