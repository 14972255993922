import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from './providers/auth/AuthProvider'
import { CartProvider } from './providers/cart/CartProvider'
import { GraphQLProvider } from './providers/graphql/GraphQLProvider'
import { Root } from './views/Root'

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <GraphQLProvider>
          <CartProvider>
            <Root />
          </CartProvider>
        </GraphQLProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
