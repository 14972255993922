import { Maybe, stripNonNumericCharacters } from '@bloom-coffee/espresso'
import React from 'react'

import { MaskedField } from './MaskedField'
import { TextFieldProps } from './models'

function parseValue(rawValue: string): Maybe<number> {
  return Number(stripNonNumericCharacters(rawValue))
}

function formatValue(value: Maybe<number>): string {
  if (value === null || typeof value === 'undefined') {
    return ''
  }
  const text = value.toString()

  if (text.length > 6) {
    return '(' + text.substring(0, 3) + ') ' + text.substring(3, 6) + '-' + text.substring(6, text.length)
  } else if (text.length > 3) {
    return '(' + text.substring(0, 3) + ') ' + text.substring(3, text.length)
  } else {
    return '(' + text
  }
}

export const PhoneField = (props: { defaultValue?: Maybe<number> } & TextFieldProps) => {
  return <MaskedField {...props} formatValue={formatValue} parseValue={parseValue} />
}
