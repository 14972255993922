import { useAuth0 } from '@auth0/auth0-react'
import { Requisition } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React, { useEffect, useState } from 'react'

import { useUserRequisitionsLazyQuery } from '../../graphql/types.generated'
import { logger } from '../../logger'
import { RequisitionContainer } from './RequisitionContainer'

export const Requisitions = () => {
  const [scheduled, setScheduled] = useState<Requisition[]>([])
  const [unscheduled, setUnscheduled] = useState<Requisition[]>([])

  const { isAuthenticated } = useAuth0()

  const [execute, { data, loading }] = useUserRequisitionsLazyQuery({ fetchPolicy: 'cache-first' })

  useEffect(() => {
    logger.debug('Requisitions', 'checking whether to fetch requisitions')
    if (isAuthenticated && !data?.me && !loading) {
      logger.debug('Requisitions', 'fetching requisitions')
      execute()
    }
  }, [data?.me, execute, isAuthenticated, loading])

  useEffect(() => {
    if (!!data?.me?.requisitions?.length) {
      const _scheduled: Requisition[] = []
      const _unscheduled: Requisition[] = []
      for (let req of data.me.requisitions || []) {
        if (!!req) {
          if (typeof req?.schedule !== 'undefined' && req.schedule !== null) {
            _scheduled.push(req)
          } else {
            _unscheduled.push(req)
          }
        }
      }
      setScheduled(_scheduled.sort((a, b) => b.createdOn!! - a.createdOn!!))
      setUnscheduled(_unscheduled.sort((a, b) => b.createdOn!! - a.createdOn!!))
    }
  }, [data])

  return (
    <div>
      {scheduled.length > 0 && (
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 8 }}>
            <Text variant='header2'>Scheduled</Text>
          </div>
          {scheduled.map((sr, i) => (
            <div
              style={{ border: `1px solid ${Color.RDY_MALIBU}`, padding: 4, maxWidth: 400, marginBottom: 6 }}
              key={`${sr.id}.${i}`}
            >
              <RequisitionContainer requisition={sr} />
            </div>
          ))}
        </div>
      )}

      {unscheduled.length > 0 && (
        <div>
          <div style={{ marginBottom: 8 }}>
            <Text variant='header2'>One-time Purchases</Text>
          </div>
          {unscheduled.map((ur, i) => (
            <div
              style={{ border: `1px solid ${Color.RDY_MALIBU}`, padding: 4, maxWidth: 400, marginBottom: 6 }}
              key={`${ur.id}.${i}`}
            >
              <RequisitionContainer requisition={ur} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
