import {
  CartItem,
  CategoryType,
  FeatureFlagName,
  formatUsCentsAsUsDollar,
  getRawItemPriceTag,
  getTagValuesByType,
  isProductCold,
  ModifierChildSelectionType,
  ModifierInfo,
  ProductInfo,
  ProductTagType,
  SelectedModifier
} from '@bloom-coffee/espresso'
import {
  ActionText,
  Button,
  Color,
  QuestionContainer,
  RdyIconName,
  Spinner,
  Stepper,
  Text
} from '@bloom-coffee/steamed-milk'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { TextArea } from '../../components/TextField'
import {
  useFeatureFlagLazyQuery,
  useMerchantForProductQuery,
  useProductDetailsQuery
} from '../../graphql/types.generated'
import { logger } from '../../logger'
import { useCart } from '../../providers/cart/CartProvider'
import { addToCart, updateCartItem } from '../../service/CartService'
import { ProductHeader } from './components/ProductHeader'
import { RetailBeansInfo } from './components/RetailBeansInfo'
import { TopModifiersContainer } from './components/TopModifiersContainer'

export type ProductDetailsFlow = 'addItem' | 'editItem'
interface ProductParams {
  preSelectedModifiers?: SelectedModifier[]
  flow: ProductDetailsFlow
  cartItem?: CartItem
}

export const Product = () => {
  const { readableMerchantId, productId } = useParams()

  const [product, setProduct] = useState<ProductInfo | undefined>()
  const [topModifierState, setTopModifierState] = useState<ModifierInfo[] | undefined>()
  const [showConfirmMerchantSwitch, toggleConfirmMerchantSwitch] = useState(false)
  const [quantity, setQuantity] = useState<number>(1)
  const [price, setPrice] = useState(0)
  const [originTags, setOriginTags] = useState<string[] | undefined>()
  const [flavorNotes, setFlavorNotes] = useState<string[] | undefined>()
  const [error, setError] = useState<string | undefined>()
  const [isCold, setIsCold] = useState(false)

  const navigate = useNavigate()
  const { state } = useLocation()
  const { preSelectedModifiers, flow, cartItem } = state as ProductParams

  const { cart, notifyCartUpdated } = useCart()

  const { data: productDetailsData, loading: detailsLoading } = useProductDetailsQuery({
    fetchPolicy: 'cache-first',
    variables: { id: productId! }
  })
  const { data: merchantData } = useMerchantForProductQuery({
    fetchPolicy: 'cache-first',
    variables: { readableId: readableMerchantId! }
  })
  const [checkFeatureFlag, { data: orderNotesFeatureFlag, loading: featureFlagLoading }] = useFeatureFlagLazyQuery({
    fetchPolicy: 'cache-first'
  })

  const confirmMerchantSwitchQ = `You have already started a cart with ${
    cart?.merchant.name || 'a different cafe'
  }. Adding this item will create a new one.`

  const formProps = useForm({
    defaultValues: {
      notes: cartItem?.notes || ''
    }
  })
  const { register } = formProps

  useEffect(() => {
    if (merchantData?.merchantByReadableId?.id) {
      logger.debug('Product', 'merchant id returned, checking feature flag')
      checkFeatureFlag({
        variables: {
          merchantId: merchantData.merchantByReadableId.id,
          featureFlagName: FeatureFlagName.OrderNotes
        }
      })
    }
  }, [checkFeatureFlag, merchantData])

  useEffect(() => {
    function initTopModifierState(_product: ProductInfo, _preselectedModifiers?: SelectedModifier[]) {
      if (typeof topModifierState !== 'undefined') {
        return
      }

      const _topModifierState: ModifierInfo[] = []

      for (let top of _product.modifiers || []) {
        const topCopy = { ...top }
        const children: ModifierInfo[] = []
        const preselectedTopModChildren =
          _preselectedModifiers
            ?.filter((psm) => psm.topModifier.id === top.id)
            .map((psm) => psm.secondLevelModifier.id) || []

        for (let child of top.children || []) {
          const childCopy = { ...child }
          if (preselectedTopModChildren.length) {
            if (preselectedTopModChildren.includes(child.id)) {
              childCopy.selected = true
            }
          } else {
            if (top.defaultSelectionIds?.includes(child.id)) {
              childCopy.selected = true
            }
          }
          children.push(childCopy)
        }
        topCopy.children = children
        _topModifierState.push(topCopy)
      }

      setTopModifierState(_topModifierState)
    }

    logger.debug('Product', 'useEffect() - checking product init')
    if (productDetailsData?.product) {
      let _product = productDetailsData.product
      if (typeof originTags === 'undefined') {
        setOriginTags(getTagValuesByType(_product.productTags, ProductTagType.BeanOrigin) || [])
      }

      if (typeof flavorNotes === 'undefined') {
        setFlavorNotes(getTagValuesByType(_product.productTags, ProductTagType.FlavorNotes) || [])
      }

      if (!product) {
        setProduct(_product)
      }

      if (!topModifierState) {
        initTopModifierState(_product, preSelectedModifiers)
      }
    }
  }, [productDetailsData, preSelectedModifiers, originTags, flavorNotes, product, topModifierState])

  useEffect(() => {
    logger.debug('Product', 'useEffect() - product, quantity, or selected mods updated')
    if (product && topModifierState) {
      const selectedModifiers = getSelectedModifiers(topModifierState)
      const priceTag = getRawItemPriceTag(product, selectedModifiers, quantity)
      setPrice(priceTag.priceUsCents)
      setIsCold(isProductCold(product.name, topModifierState))
    }
  }, [product, topModifierState, quantity])

  async function handleAddToCart() {
    if (merchantData?.merchantByReadableId && product) {
      if (!product.active) {
        setError('Unfortunately, this item is no longer in stock')
      } else if (cart?.items.length && cart.merchant && cart.merchant.id !== merchantData.merchantByReadableId.id) {
        toggleConfirmMerchantSwitch(true)
      } else {
        executeAddToCart('addToCart')
      }
    }
  }

  function getSelectedModifiers(tops: ModifierInfo[]): SelectedModifier[] {
    const selectedModifiers: SelectedModifier[] = []
    for (let topModifier of tops) {
      for (let secondLevelModifier of topModifier.children || []) {
        // use "selected" here
        if (secondLevelModifier.selected) {
          selectedModifiers.push({ topModifier, secondLevelModifier })
        }
      }
    }
    return selectedModifiers
  }

  function handleSelectedModifier(alteredTopMod: ModifierInfo, selectedModifierId: string) {
    const _topModifierState: ModifierInfo[] = []

    for (let topModifier of topModifierState || []) {
      const tModCopy = { ...topModifier }
      if (topModifier.id === alteredTopMod.id) {
        const newChildren: ModifierInfo[] = []
        for (let child of topModifier.children || []) {
          const cCopy = { ...child }
          if (child.id === selectedModifierId) {
            // if multi-select, revert, otherwise keep selected
            cCopy.selected =
              topModifier.childSelectionType === ModifierChildSelectionType.MultiSelect ? !child.selected : true
          } else {
            if (topModifier.childSelectionType !== ModifierChildSelectionType.MultiSelect) {
              // de-select others
              cCopy.selected = false
            }
          }
          newChildren.push(cCopy)
        }
        tModCopy.children = newChildren
      }
      _topModifierState.push(tModCopy)
    }

    setTopModifierState(_topModifierState)
  }

  function handleSwitchCartConfirmation(yes: boolean) {
    toggleConfirmMerchantSwitch(false)
    if (yes) {
      executeAddToCart('addToCart')
    }
  }

  function canCheckout(): boolean {
    return typeof product !== 'undefined'
  }

  async function handlePlaceQuickOrder() {
    if (canCheckout()) {
      if (!product?.active) {
        setError('Unfortunately, this item is no longer in stock')
      } else {
        executeAddToCart('quickOrder')
      }
    }
  }

  async function handleEditCartItem() {
    if (cartItem) {
      const selectedModifiers = getSelectedModifiers(topModifierState || [])
      const notes = formProps.getValues().notes?.trim()

      updateCartItem(cartItem, { selectedModifiers, notes })
      notifyCartUpdated()

      goToCart()
    } else {
      logger.debug('Product', 'NO CART ITEM')
    }
  }

  function goToCart() {
    navigate('/cart')
  }

  function goBack() {
    navigate(`/${readableMerchantId}`)
  }

  function executeAddToCart(_flow: 'addToCart' | 'quickOrder') {
    if (error) {
      setError(undefined)
    }

    try {
      const selectedModifiers = getSelectedModifiers(topModifierState || [])
      const notes = formProps.getValues().notes?.trim()

      addToCart(merchantData!.merchantByReadableId!, product!, selectedModifiers, quantity, notes)
      notifyCartUpdated()

      if (_flow === 'addToCart') {
        goBack()
      } else {
        goToCart()
      }
    } catch (e) {
      logger.error('Product', `${e}`)
      setError('Could not add item to cart')
    }
  }

  return (
    <div style={{ backgroundColor: Color.RDY_FOREST, maxWidth: 700, margin: 'auto' }}>
      <div style={{ flex: 1, backgroundColor: Color.WHITE }}>
        <div style={{ marginLeft: 8 }}>
          <ActionText
            onClick={() => navigate(-1)}
            color={Color.BLUE_GREY_500}
            message={flow === 'addItem' ? 'Back to Menu' : 'Cancel'}
            iconName={RdyIconName.BACK_ARROW}
          />
        </div>

        {(featureFlagLoading || detailsLoading) && (
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Spinner color='primary' size='medium' />
          </div>
        )}

        {product && <ProductHeader product={product} />}

        {product?.category?.type === CategoryType.RetailBeans && (
          <RetailBeansInfo product={product} originTags={originTags} flavorNotes={flavorNotes} />
        )}

        <TopModifiersContainer
          modifiers={topModifierState || []}
          handleSelection={handleSelectedModifier}
          selectedColor={isCold ? Color.RDY_MALIBU : Color.RDY_PINK}
        />

        {orderNotesFeatureFlag?.featureFlag?.enabled === true && (
          <div>
            <div style={{ marginBottom: 12 }} color={Color.RDY_LIGHT_GREY}>
              <Text variant='body2'>Add a Note</Text>
            </div>
            <FormProvider {...formProps}>
              <TextArea maxLength={200} name='notes' label='Notes (max 150 characters)' ref={register} />
            </FormProvider>
          </div>
        )}

        <div style={{ height: 200 }} />
      </div>

      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          backgroundColor: Color.RDY_FOREST,

          paddingBottom: 12,
          paddingTop: 12,
          paddingLeft: 16,
          paddingRight: 16,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 18
          }}
        >
          <div style={{ marginRight: 24 }}>
            <Text color={Color.WHITE} variant='body1'>
              ${formatUsCentsAsUsDollar(price)}
            </Text>
          </div>

          <div>
            <Text variant='body1' color={Color.WHITE}>
              Qty:{' '}
            </Text>
            <Text color={Color.WHITE} variant='body1'>
              {quantity}
            </Text>
          </div>

          <div>
            {flow === 'addItem' && (
              <Stepper
                quantityConsumer={(_quantity: number) => setQuantity(_quantity)}
                min={1}
                initialQuantity={1}
                buttonAccentColor={Color.RDY_FOREST}
                buttonColor={Color.RDY_BEIGE}
                blockStepperClicks={!product}
              />
            )}
          </div>
        </div>
        <div style={{ marginBottom: 8 }}>
          <Text variant='body1' color={Color.RED}>
            {error}
          </Text>
        </div>
        {flow === 'addItem' && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div style={{ margin: 6 }}>
              <Button size='medium' theme='cancel' onClick={handlePlaceQuickOrder} label='Quick Order' />
            </div>

            <div style={{ margin: 6 }}>
              <Button size='medium' theme='action' onClick={handleAddToCart} label='Add to Cart' />
            </div>
          </div>
        )}
        {flow === 'editItem' && (
          <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
            <Button size='medium' theme='action' onClick={handleEditCartItem} label='Edit Cart Item' />
          </div>
        )}
      </div>

      {showConfirmMerchantSwitch && (
        <div
          style={{
            borderTop: `1px solid ${Color.BLUE_GREY_700}`,
            backgroundColor: Color.WHITE,
            display: 'flex',
            justifyContent: 'center',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            flex: 1,
            paddingTop: 12,
            paddingBottom: 18
          }}
        >
          <QuestionContainer
            question={confirmMerchantSwitchQ}
            yesAnswer={'Add Item'}
            noAnswer={'Cancel'}
            onAnswer={handleSwitchCartConfirmation}
          />
        </div>
      )}
    </div>
  )
}
