/* eslint-disable max-len */
import React from 'react'

export const Position1 = (props: { height: number }) => {
  const { height } = props
  return (
    <svg height={height} width='100%' viewBox='0 0 72 81' fill='none'>
      <path
        d='M72 54.5625C72 69.075 60.2059 81 45.6037 81H26.3963C11.9064 81 0 69.075 0 54.5625V26.4375C0 11.925 11.9064 0 26.3963 0H45.6037C60.0936 0 72 11.925 72 26.4375V54.5625Z'
        fill='#F7C02D'
      />
      <path
        d='M44.9297 57.375V26.4375V11.8125L39.7628 7.3125L22.4648 20.1375V33.4125L34.0343 24.975V26.4375V57.375H22.4648V68.0625H34.0343H44.9297H55.8252V57.375H44.9297Z'
        fill='#09292D'
      />
    </svg>
  )
}
