import { FulfillmentLocationInfo, Maybe } from '@bloom-coffee/espresso'

const LOCAL_STORAGE_KEY_SERVING_LOCATION_NAME = 'servingLocationName'

export function fetchServingLocation(): Maybe<FulfillmentLocationInfo> {
  const current = localStorage.getItem(LOCAL_STORAGE_KEY_SERVING_LOCATION_NAME)
  if (current) {
    return JSON.parse(current) as FulfillmentLocationInfo
  }
}
export function removeServingLocation() {
  localStorage.removeItem(LOCAL_STORAGE_KEY_SERVING_LOCATION_NAME)
}

export function saveServingLocation(servingLocation: FulfillmentLocationInfo) {
  localStorage.setItem(LOCAL_STORAGE_KEY_SERVING_LOCATION_NAME, JSON.stringify(servingLocation))
}
