import {
  Maybe,
  OrderInfo,
  OrderStateInfo,
  OrderStateName,
  readableDateFromInstant,
  readableTimeFromInstant
} from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import Icon from '../../../components/RdyIcon/Icon'
import { RdyIconName } from '../../../components/RdyIcon/RdyIconName'
import { OrderStatusBarContainer } from './OrderStatusBarContainer'
import { OrderStatusShape } from './OrderStatusShape'
import { RdyPassContainer } from './rdyPass/RdyPassContainer'
import { PassConfig } from './useOrderState/useOrderState'

interface OrderStateContainerProps {
  orderState: Maybe<OrderStateInfo>
  order: Maybe<OrderInfo>
  passConfig: Maybe<PassConfig>
  placedOn: Maybe<number>
  isRdy: boolean
}

export const OrderStateContainer = (props: OrderStateContainerProps) => {
  const { orderState, passConfig, placedOn, isRdy } = props

  return (
    <div
      style={{
        borderRadius: 4,
        paddingTop: 12,
        paddingLeft: 6,
        paddingRight: 6,
        backgroundColor: isRdy ? Color.RDY_MALIBU : Color.RDY_FOREST
      }}
    >
      <div style={{ flex: 0 }}>
        {orderState?.name === OrderStateName.Stale && (
          <Text variant='subheader2' color={Color.WHITE}>
            Show the staff your order details when you arrive.
          </Text>
        )}

        {orderState?.name !== OrderStateName.Stale && !isRdy && (
          <OrderStatusBarContainer orderState={orderState || { name: OrderStateName.Pending }} />
        )}
      </div>

      <div style={{ paddingTop: 12, paddingLeft: 12, paddingRight: 12 }}>
        <div>
          {passConfig?.useRdyPass === true ? (
            <div>
              {isRdy && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ marginBottom: 4, alignItems: 'center' }}>
                    <Icon color={Color.RDY_FOREST} icon={RdyIconName.THUMBSUP} size={24} />
                  </div>

                  <div>
                    <Text variant='header1' color={Color.RDY_FOREST}>
                      RDY
                    </Text>
                  </div>
                </div>
              )}

              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 8 }}>
                <Text variant='body1' color={Color.GREY_200}>
                  {orderState?.name === OrderStateName.PickedUpFromRdyPass
                    ? 'Order picked up!'
                    : 'Your order will be here:'}
                </Text>
              </div>

              {passConfig?.rdyPassState && <RdyPassContainer state={passConfig.rdyPassState} />}
            </div>
          ) : (
            <div>
              <OrderStatusShape isRdy={isRdy} orderState={orderState || { name: OrderStateName.Pending }} />
            </div>
          )}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 4, paddingBottom: 12 }}>
        {!!placedOn && (
          <Text variant='subheader3' color={Color.RDY_LIGHT_GREY}>{`Order Placed ${readableDateFromInstant(
            placedOn
          )}, ${readableTimeFromInstant(placedOn)}`}</Text>
        )}
      </div>
    </div>
  )
}
