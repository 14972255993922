import { getMerchantAvailability, MerchantAvailability, MerchantInfo } from '@bloom-coffee/espresso'
import { useEffect, useState } from 'react'

import { useMerchantOverviewQuery } from '../../../graphql/types.generated'

export function useMerchantOverview(readableId: string) {
  const [info, setInfo] = useState<MerchantInfo | undefined>()
  const { data, error, loading } = useMerchantOverviewQuery({
    fetchPolicy: 'cache-first',
    variables: { readableId }
  })

  useEffect(() => {
    if (data?.merchantByReadableId) {
      let availability: MerchantAvailability | undefined
      if (data.merchantByReadableId.operationHours) {
        availability = getMerchantAvailability(data.merchantByReadableId.operationHours)
      }
      setInfo({ ...data.merchantByReadableId, availability })
    }
  }, [data])

  return { info, error, loading }
}
