import { OrderTransactionInfo } from '@bloom-coffee/espresso'
import React from 'react'

import { OrderSummary } from '../OrderSummary'
import { RefundContainer } from './RefundContainer'

export const OrderTransactionContainer = (props: { transaction: OrderTransactionInfo }) => {
  const { transaction } = props
  return (
    <div>
      <OrderSummary
        subtotalUsCents={transaction.subtotalUsCents || 0}
        tipUsCents={transaction.tipUsCents || 0}
        taxUsCents={transaction.taxUsCents || 0}
        feeUsCents={transaction.feeUsCents || 0}
      />
      {transaction.refunds?.map((rf, index) => (
        <RefundContainer key={`refund.${rf.id}.${index}`} refund={rf} />
      ))}
    </div>
  )
}
