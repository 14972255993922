import { readableDateFromInstant, RequisitionPurchase } from '@bloom-coffee/espresso'
import { Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { PurchaseSummary } from '../Purchase/PurchaseSummary'

interface RequisitionPurchaseContainerProps {
  purchase: RequisitionPurchase
}

export const RequisitionPurchaseContainer = (props: RequisitionPurchaseContainerProps) => {
  const { purchase } = props
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: 4 }}>
          <Text variant='body3'>Charge on {readableDateFromInstant(purchase.createdOn || 0)}</Text>{' '}
        </div>
        {false && (
          <div>
            <Text variant='error'>Payment Failed</Text>
          </div>
        )}
      </div>

      <div>
        <PurchaseSummary subtotal={purchase.subtotal || 0} tax={purchase.tax || 0} discount={purchase.discount || 0} />
      </div>
    </div>
  )
}
