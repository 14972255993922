import { ModifierInfo } from '@bloom-coffee/espresso'
import { Color, FirstLevelOption, OptionsContainer, SecondLevelOption } from '@bloom-coffee/steamed-milk'
import React from 'react'

interface ModifiersContainerProps {
  modifiers: ModifierInfo[]
  handleSelection: (topModifier: ModifierInfo, selectedModifierId: string) => void
  selectedColor?: Color
}

function generateOptionsUX(modifiers: ModifierInfo[]): FirstLevelOption<ModifierInfo, ModifierInfo>[] {
  return modifiers.map((tMod) => {
    const secondLevelOptions: SecondLevelOption<ModifierInfo>[] =
      tMod.children?.map((slMod) => {
        return {
          title: slMod.name,
          isSelected: slMod.selected ? true : false,
          value: slMod
        }
      }) || []

    return {
      value: tMod,
      childSelectionType: tMod.childSelectionType || 'SINGLE_SELECT',
      title: tMod.name,
      description: tMod.description || '',
      secondLevelOptions
    }
  })
}

export const TopModifiersContainer = (props: ModifiersContainerProps) => {
  const { modifiers, handleSelection, selectedColor } = props

  const options = generateOptionsUX(modifiers)

  function handleModifierSelection(topModifier: ModifierInfo, secondLevelModifier: ModifierInfo) {
    handleSelection(topModifier, secondLevelModifier.id)
  }

  return <OptionsContainer options={options} onSelect={handleModifierSelection} selectedColor={selectedColor} />
}
