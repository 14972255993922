import { ApolloProvider } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { Spinner } from '@bloom-coffee/steamed-milk'
import React, { PropsWithChildren, useEffect } from 'react'

import { graphQLClient, setTokenRetriever } from '../../graphql/client'
import { logger } from '../../logger'

export const GraphQLProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props

  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()

  useEffect(() => {
    if (isAuthenticated) {
      logger.debug('GraphQLProvider', 'authenticated - setting token retriever')
      setTokenRetriever(getAccessTokenSilently)
    } else {
      setTokenRetriever(async () => null)
    }
  }, [getAccessTokenSilently, isAuthenticated])

  if (isLoading) {
    return <Spinner size='small' color='primary' />
  }

  return <ApolloProvider client={graphQLClient}>{children}</ApolloProvider>
}
