export default class ImageAsset {
  public static APPLE_LOGO_DARK = require('./thirdparty/apple_for_dark.png')
  public static APPLE_LOGO_LIGHT = require('./thirdparty/apple_for_light.png')
  public static APPLE_PAY_MARK = require('./thirdparty/APay.png')
  public static FACEBOOK_LOGO_SMALL_WHITE = require('./thirdparty/fb_logo_white_58.png')
  public static GOOGLE_LOGO = require('./thirdparty/goog_logo.png')
  public static GOOGLE_PAY_MARK = require('./thirdparty/GPay.png')

  public static PRODUCT_IMAGE_COFFEE = require('./products/hot/coffee.jpg')
  public static PRODUCT_IMAGE_POUROVER = require('./products/hot/pourover.jpg')
  public static PRODUCT_IMAGE_AMERICANO = require('./products/hot/americano.jpg')
  public static PRODUCT_IMAGE_MACCHIATO = require('./products/hot/macchiato.jpg')
  public static PRODUCT_IMAGE_CAPPUCCINO = require('./products/hot/cappuccino.jpg')
  public static PRODUCT_IMAGE_FLAT_WHITE = require('./products/hot/flat_white.jpg')
  public static PRODUCT_IMAGE_MOCHA = require('./products/hot/mocha.jpg')
  public static PRODUCT_IMAGE_CORTADO = require('./products/hot/cortado.jpg')
  public static PRODUCT_IMAGE_ESPRESSO = require('./products/hot/espresso.jpg')
  public static PRODUCT_IMAGE_LATTE = require('./products/hot/latte.jpg')
  public static PRODUCT_IMAGE_MATCHA = require('./products/hot/matcha_latte.jpg')
  public static PRODUCT_IMAGE_CHAI = require('./products/hot/chai_latte.jpg')

  public static PRODUCT_IMAGE_COFFEE_ICED = require('./products/iced/coffee.jpg')
  public static PRODUCT_IMAGE_AMERICANO_ICED = require('./products/iced/americano.jpg')
  public static PRODUCT_IMAGE_ESPRESSO_ICED = require('./products/iced/espresso.jpg')
  public static PRODUCT_IMAGE_LATTE_ICED = require('./products/iced/latte.jpg')
  public static PRODUCT_IMAGE_COLD_BREW = require('./products/iced/nitro_cold_brew.jpg')
  public static PRODUCT_IMAGE_MATCHA_ICED = require('./products/iced/matcha_latte.jpg')
  public static PRODUCT_IMAGE_CHAI_ICED = require('./products/iced/chai_latte.jpg')

  public static PRODUCT_IMAGE_DYNAMIC_ESPRESSO_1 = require('./products/dynamic/espresso_pour_1.jpg')
  public static PRODUCT_IMAGE_DYNAMIC_ESPRESSO_3 = require('./products/dynamic/espresso_pour_3.jpg')
  public static PRODUCT_IMAGE_DYNAMIC_LATTE_2 = require('./products/dynamic/latte_art_pour_2.jpg')
  public static PRODUCT_IMAGE_DYNAMIC_LATTE_ALT = require('./products/dynamic/latte_art_pour_alt.jpg')
  public static PRODUCT_IMAGE_DYNAMIC_MILK_STEAM = require('./products/dynamic/milk_steam.jpg')

  public static PRODUCT_IMAGE_BEANS_SUB = require('./products/dynamic/beans_sub.png')
  public static PRODUCT_IMAGE_BEANS_ONE_TIME = require('./products/dynamic/beans_one_time.png')

  public static PRODUCT_GLYPH_TEA = require('./glyphs/tea.png')
  public static PRODUCT_GLYPH_DRINK = require('./glyphs/coffee.png')
  public static PRODUCT_GLYPH_TEA_ICED = require('./glyphs/tea_iced.png')
  public static PRODUCT_GLYPH_DRINK_ICED = require('./glyphs/coffee_iced.png')
  public static PRODUCT_GLYPH_FOOD = require('./glyphs/food_bag.png')
  public static PRODUCT_GLYPH_RETAIL = require('./glyphs/retail.png')

  public static RDY_PASS_IMAGE_CUPS_1 = require('./iot/RdyPass_withCups1.png')

  public static RDY_HEADER = require('./other/RdyHeader.png')

  public static RDY_PASS = require('./iot/RDY_Pass.png')
}
