import { DiningOption, orderDiningOptionToLanguage } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

interface DiningOptionSelectorProps {
  diningOptions: DiningOption[]
  diningOption: DiningOption
  onDiningOptionUpdate: (diningOption: DiningOption) => void
}
export const DiningOptionSelector = (props: DiningOptionSelectorProps) => {
  const { diningOptions, diningOption, onDiningOptionUpdate } = props

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginLeft: 32, marginRight: 32 }}>
      {diningOptions.map((val: DiningOption, index: number) => {
        const selected = val === diningOption
        return (
          <button
            key={index}
            onClick={() => onDiningOptionUpdate(val)}
            style={{
              cursor: 'pointer',
              height: 44,
              minWidth: 120,
              paddingLeft: 12,
              paddingRight: 12,
              alignItems: 'center',
              borderRadius: 6,
              display: 'flex',
              justifyContent: 'center',
              borderWidth: 2,
              marginLeft: 16,
              marginRight: 16,
              backgroundColor: selected ? Color.RDY_PINK : 'transparent',
              border: `1px solid ${selected ? Color.RDY_FOREST : Color.RDY_BEIGE}`
            }}
          >
            <Text variant='subheader2' color={Color.RDY_FOREST} textAlign='center'>
              {orderDiningOptionToLanguage[val]}
            </Text>
          </button>
        )
      })}
    </div>
  )
}
