import { formatUsCentsAsUsDollar } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

interface PurchaseSummaryProps {
  subtotal: number
  tax: number
  discount: number
}

export const PurchaseSummary = (props: PurchaseSummaryProps) => {
  const { subtotal, tax, discount } = props
  const total = subtotal + tax

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'space-around', flexDirection: 'column' }}>
        <div>
          <Text variant='body1' color={Color.RDY_FOREST}>
            Subtotal
          </Text>
        </div>

        <div>
          <Text variant='body1' color={Color.RDY_FOREST}>
            Tax
          </Text>
        </div>

        {discount > 0 && (
          <div>
            <Text variant='body1' color={Color.RDY_FOREST}>
              Discount
            </Text>
          </div>
        )}

        <div>
          <Text variant='body1' color={Color.RDY_FOREST}>
            Total
          </Text>
        </div>
      </div>

      <div
        style={{ display: 'flex', flex: 1, alignItems: 'flex-end', justifyContent: 'center', flexDirection: 'column' }}
      >
        <div>
          <Text variant='subheader2' color={Color.RDY_FOREST}>
            ${formatUsCentsAsUsDollar(subtotal + discount)}
          </Text>
        </div>
        <div>
          <Text variant='subheader2' color={Color.RDY_FOREST}>
            ${formatUsCentsAsUsDollar(tax)}
          </Text>
        </div>

        {discount > 0 && (
          <div>
            <Text variant='body1' color={Color.RDY_FOREST}>
              - ${formatUsCentsAsUsDollar(discount)}
            </Text>
          </div>
        )}

        <div>
          <Text variant='subheader2' color={Color.RDY_FOREST}>
            ${formatUsCentsAsUsDollar(total)}
          </Text>
        </div>
      </div>
    </div>
  )
}
