export function sortOrderSort(a: any, b: any) {
  if (a.sortOrder == null || a.sortOrder === undefined) {
    return 1
  }

  if (b.sortOrder == null || b.sortOrder === undefined) {
    return -1
  }

  if (a.sortOrder === b.sortOrder) {
    return 0
  }

  return a.sortOrder < b.sortOrder ? -1 : 1
}
