import { OriginType, ProductInfo } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { getRoastTypeDisplay } from '../UxUtils'

interface RetailBeansInfoProps {
  product: ProductInfo
  originTags?: string[]
  flavorNotes?: string[]
}
export const RetailBeansInfo = (props: RetailBeansInfoProps) => {
  const { product, originTags, flavorNotes } = props
  return (
    <>
      {!!product?.roastType && (
        <div style={{ marginBottom: 16, borderBottom: '1px solid #EEEEEE' }}>
          <div style={{ marginBottom: 8 }}>
            <Text variant='body2' color={Color.RDY_LIGHT_GREY}>
              Roast Type
            </Text>
            <Text variant='body2'>{getRoastTypeDisplay(product.roastType)}</Text>
          </div>
        </div>
      )}
      {!!flavorNotes && flavorNotes.length > 0 && (
        <div style={{ marginBottom: 16, borderBottom: '1px solid #EEEEEE' }}>
          <div style={{ marginBottom: 8 }}>
            <Text variant='body2' color={Color.RDY_LIGHT_GREY}>
              Flavor Notes
            </Text>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {flavorNotes.map((note) => {
                return (
                  <div key={note} style={{ padding: 5 }}>
                    <Text variant='body2'>{note}</Text>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
      {!!originTags && originTags.length > 0 && (
        <div style={{ marginBottom: 16, borderBottom: '1px solid #EEEEEE' }}>
          <div style={{ marginBottom: 8 }}>
            <Text variant='body2' color={Color.RDY_LIGHT_GREY}>
              {product?.originType === OriginType.Blend ? 'Blend' : 'Single Origin'}
            </Text>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {originTags.map((origin) => {
                return (
                  <Text variant='body2' key={origin}>
                    {origin}
                  </Text>
                )
              })}
            </div>
          </div>
        </div>
      )}
      {!!product?.sizeInGrams && (
        <div style={{ marginBottom: 16, borderBottom: '1px solid #EEEEEE' }}>
          <div style={{ marginBottom: 8 }}>
            <Text variant='body2' color={Color.RDY_LIGHT_GREY}>
              Size
            </Text>
            <div style={{ padding: 5 }}>
              <Text variant='body2'>{product.sizeInGrams}g</Text>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
