import { OrderStateName } from '@bloom-coffee/espresso'
import { Button, Color, Spinner, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'
import { useParams } from 'react-router-dom'

import Icon from '../../components/RdyIcon/Icon'
import { RdyIconName } from '../../components/RdyIcon/RdyIconName'
import { useOrderReceiptQuery, useUserForGuestOrderQuery } from '../../graphql/types.generated'
import { OrderStateContainer } from './components/OrderStateContainer'
import { MerchantDirectionsContainer } from './components/receipt/MerchantDirectionsContainer'
import { OrderReceiptContainer } from './components/receipt/OrderReceiptContainer'
import { SuggestAppDownload } from './components/SuggestAppDownload'
import { useOrderState } from './components/useOrderState/useOrderState'

export const Order = () => {
  const { uuid } = useParams()

  const { data: userData, loading: loadingUserData } = useUserForGuestOrderQuery({
    fetchPolicy: 'cache-first',
    variables: { uuid: uuid! }
  })
  const { data: receiptData, loading: loadingReceiptData } = useOrderReceiptQuery({
    fetchPolicy: 'cache-first',
    variables: { uuid: uuid! }
  })

  const { placedOn, orderState, passConfig, order, isRdy, error: stateError } = useOrderState(uuid!)

  function openEmail() {
    window.location.href = `mailto:support@rdy.xyz?subject=Order%20${order?.id}`
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: isRdy ? Color.RDY_FOREST : Color.WHITE,
        paddingTop: 36,
        paddingBottom: 100
      }}
    >
      <div>
        {stateError && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div>
              <Text variant='header1'>Sorry, we cannot load details for this order</Text>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Text variant='subheader2'>Please email support@rdy.xyz for order details</Text>
            </div>
            <div>
              <Button onClick={() => openEmail()} size='large' label={`Email (Order #${order?.id})`} />
            </div>
          </div>
        )}
      </div>

      <div>
        {!stateError && isRdy && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: 8,
              paddingBottom: 8
            }}
          >
            <div style={{ marginRight: 4 }}>
              <Icon color={Color.RDY_MALIBU} size={55} icon={RdyIconName.LOGO_PRIMARY} />
            </div>

            <Text variant='subheader1' color={Color.RDY_MALIBU}>
              for pickup!
            </Text>
          </div>
        )}

        {!stateError && !isRdy && !loadingUserData && (
          <Text variant='header1' color={Color.RDY_FOREST}>
            {orderState?.name === OrderStateName.PaymentRejected
              ? 'Card declined - please try again'
              : `${userData?.orderByUuid.user.profile.firstName || ''}, thanks for your order!`}
          </Text>
        )}
      </div>

      {!!order && !!receiptData?.orderByUuid.merchant && (
        <div>
          <SuggestAppDownload
            merchant={receiptData.orderByUuid.merchant}
            appUrl={order.fulfillmentLocation?.appUrl}
            possibleRewardPoints={order.expectedRewardPoints}
            fontColor={isRdy ? Color.WHITE : Color.RDY_FOREST}
          />
        </div>
      )}

      {!!order && <MerchantDirectionsContainer order={order} fontColor={isRdy ? Color.WHITE : Color.RDY_FOREST} />}

      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        <div style={{ marginRight: 6, marginLeft: 6, marginBottom: 12 }}>
          {!stateError && orderState && (
            <OrderStateContainer
              isRdy={isRdy}
              placedOn={placedOn}
              orderState={orderState}
              order={order}
              passConfig={passConfig}
            />
          )}
        </div>

        <div
          style={{
            marginRight: 6,
            marginLeft: 6,
            marginBottom: 12,
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: Color.GREY_200,
            borderRadius: 4
          }}
        >
          {loadingReceiptData && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner color='primary' size='small' />
            </div>
          )}
          {!loadingReceiptData && receiptData && <OrderReceiptContainer order={receiptData.orderByUuid} />}
        </div>
      </div>
    </div>
  )
}
