import { Color } from '@bloom-coffee/steamed-milk'
import React, { useState } from 'react'

interface SelectProps {
  initialValue: string | undefined
  options: SelectOption[]
  onChange: (selectedId: string) => void
}
interface SelectOption {
  id: string
  displayName: string
}

export const Select = (props: SelectProps) => {
  const { initialValue, options, onChange } = props

  const [value, setValue] = useState<string | undefined>(initialValue)

  function handleChange(value: string) {
    setValue(value)
    onChange(value)
  }

  return (
    <select style={selectStyle} value={value} onChange={(e) => handleChange(e.target.value)}>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.displayName}
        </option>
      ))}
    </select>
  )
}

const selectStyle: React.CSSProperties = {
  fontFamily: 'Matter-Medium',
  height: 44,
  minWidth: 120,
  paddingLeft: 12,
  paddingRight: 12,
  alignItems: 'center',
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'center',
  border: '1px solid',
  borderColor: Color.RDY_FOREST,
  font: Color.RDY_FOREST,
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: Color.RDY_PINK,
  textAlign: 'center'
}
