import { TipOptionConfig } from '@bloom-coffee/espresso'
import { Button, Color, IconButton, Text } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { MdClear } from 'react-icons/md'
import * as yup from 'yup'

import { PriceField } from '../../../components/TextField/PriceField'

const customTipSchema = yup.object({
  tip: yup.number().label('Add Tip').required()
})
type CustomTipModel = yup.InferType<typeof customTipSchema>

interface TipSelectionFormProps {
  options: TipOptionConfig[]
  handleSelection: (index: number) => void
  handleCustomTip: (valueUsCents: number) => void
  selectedIndex: number
  disabled?: boolean
}

export const TipSelectionForm = (props: TipSelectionFormProps) => {
  const { options, handleSelection, selectedIndex, disabled, handleCustomTip } = props
  const [showAddCustom, setShowAddCustom] = useState(false)

  const formProps = useForm<CustomTipModel>({
    resolver: yupResolver(customTipSchema)
  })
  const { handleSubmit } = formProps

  function onSubmit(form: CustomTipModel) {
    setShowAddCustom(false)
    handleCustomTip(form.tip)
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {options.map((optionConfig: TipOptionConfig, index: number) => {
          const selected = index === selectedIndex
          return (
            <button
              disabled={disabled || showAddCustom}
              key={optionConfig.title}
              style={{
                cursor: 'pointer',
                height: 32,
                paddingLeft: 16,
                paddingRight: 16,
                alignItems: 'center',
                borderRadius: 6,
                justifyContent: 'center',
                borderWidth: 2,
                marginLeft: 6,
                marginRight: 6,
                backgroundColor: selected ? Color.RDY_PINK : 'transparent',
                border: `1px solid ${selected ? Color.RDY_FOREST : Color.RDY_BEIGE}`
              }}
              onClick={() => handleSelection(index)}
            >
              <Text variant='subheader2' color={Color.RDY_FOREST}>
                {optionConfig.title}
              </Text>
            </button>
          )
        })}

        <button
          disabled={disabled}
          style={{
            cursor: 'pointer',
            height: 32,
            paddingLeft: 16,
            paddingRight: 16,
            alignItems: 'center',
            borderRadius: 6,
            justifyContent: 'center',
            borderWidth: 2,
            marginLeft: 6,
            marginRight: 6,
            backgroundColor: selectedIndex === options.length ? Color.RDY_PINK : 'transparent',
            border: `1px solid ${selectedIndex === options.length ? Color.RDY_FOREST : Color.RDY_BEIGE}`
          }}
          onClick={() => setShowAddCustom(true)}
        >
          <Text variant='subheader2' color={Color.RDY_FOREST}>
            Other
          </Text>
        </button>
      </div>

      {showAddCustom && (
        <div
          style={{
            flex: 1,
            marginLeft: 16,
            marginRight: 16,
            marginBottom: 16,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
          <div
            style={{
              paddingBottom: 24,
              paddingTop: 12,
              backgroundColor: Color.WHITE,
              borderRadius: 10,
              border: `1px solid ${Color.RDY_DARK_GREY}`
            }}
          >
            <FormProvider {...formProps}>
              <div>
                <IconButton
                  icon={<MdClear size={22} />}
                  height={40}
                  width={40}
                  onClick={() => setShowAddCustom(false)}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginBottom: 8 }}>
                  <Text variant='header3'>How much tip would you like to add?</Text>
                </div>

                <div style={{ marginBottom: 8 }}>
                  <PriceField name='tip' label='Add Tip' defaultValue={null} />
                </div>

                <div>
                  <Button theme='action' size='small' label='Add' onClick={handleSubmit(onSubmit)} />
                </div>
              </div>
            </FormProvider>
          </div>
        </div>
      )}
    </div>
  )
}
