import { CatalogInfo, CategoryInfo, CategoryType, ProductInfo } from '@bloom-coffee/espresso'
import { SelectionBar } from '@bloom-coffee/steamed-milk'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useNotableProductInfoQuery } from '../../graphql/types.generated'
import { logger } from '../../logger'
import { sortOrderSort } from '../../utils/sorting'
import { CategoryContainer } from './components/CategoryContainer'
import { ProductImageContainer } from './components/ProductImageContainer'

const NUM_SUGGESTED = 4

interface CatalogProps {
  readableMerchantId: string
  catalog: CatalogInfo
  categories: CategoryInfo[]
  productsByCategoryId: Record<string, ProductInfo[]>
  merchantOpen: boolean
  bottomSpace?: number
}

export const Catalog = (props: CatalogProps) => {
  const { readableMerchantId, catalog, categories, productsByCategoryId, bottomSpace = 0 } = props

  const navigate = useNavigate()
  const categoriesRef = useRef(null)

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0)
  const [trendingProductIds, setTrendingProductIds] = useState<string[]>([])
  const [topProductIds, setTopProductIds] = useState<string[]>([])

  const sortedCategories = categories.sort(sortOrderSort)

  const categoryTitles = categories.map((ac) => ac.name) || []
  const suggestedItems: ProductInfo[] = [...(catalog?.suggestedProducts || [])]
  const length = suggestedItems.length
  if (length < NUM_SUGGESTED) {
    const category = sortedCategories.filter((cat) => cat.type === CategoryType.Coffee)[0]
    if (!!category) {
      const additional = productsByCategoryId[category.id]?.slice(0, NUM_SUGGESTED - length) || []
      suggestedItems.push.apply(suggestedItems, additional)
    }
  }

  const { data: notableProductData } = useNotableProductInfoQuery({
    fetchPolicy: 'cache-first',
    variables: { id: catalog?.id }
  })

  useEffect(() => {
    const _catalog = notableProductData?.catalog
    if (_catalog) {
      const _trendingProductIds: string[] = _catalog.trendingProducts?.map((p) => p.id) || []
      const _topProductIds: string[] = _catalog.topProducts?.map((p) => p.id) || []
      if (trendingProductIds.length !== _trendingProductIds.length || topProductIds.length !== _topProductIds.length) {
        logger.debug('Catalog', 'setting trending and top products')
        setTopProductIds(topProductIds)
        setTrendingProductIds(trendingProductIds)
      }
    }
  }, [notableProductData, trendingProductIds, topProductIds])

  function handleCatalogCategoryClick(index: number) {
    scrollToCategory(index)
    setCurrentCategoryIndex(index)
  }

  function scrollToCategory(index: number) {
    const element = document.getElementById(categories[index].id)
    element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  }

  function goToProduct(product: ProductInfo) {
    navigate(`/${readableMerchantId}/products/${product.id}`, { state: { flow: 'addItem' } })
  }

  return (
    <div>
      <div style={{ borderBottom: '1px solid #F2EFE4', paddingBottom: 4 }}>
        <SelectionBar
          selections={categoryTitles}
          selectedIndex={currentCategoryIndex}
          selectionCallback={(index: number) => handleCatalogCategoryClick(index)}
        />
      </div>

      <div>
        <ProductImageContainer
          products={suggestedItems}
          trendingProductIds={trendingProductIds}
          topProductIds={topProductIds}
          onPressItem={goToProduct}
          variant='dark'
        />
      </div>

      <div ref={categoriesRef} style={{ backgroundColor: '#F2EFE4', paddingTop: 12 }}>
        {sortedCategories.map((category, idx) => (
          <div id={category.id} key={`${idx}.${category.id}`} style={{ marginBottom: 36 }}>
            <CategoryContainer
              categoryName={category.name}
              items={productsByCategoryId[category.id] || []}
              onPressItem={goToProduct}
              trendingProductIds={trendingProductIds}
              topProductIds={topProductIds}
            />
          </div>
        ))}

        <div style={{ height: bottomSpace }} />
      </div>
    </div>
  )
}
