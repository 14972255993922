import { ApolloClient, ApolloLink, HttpLink, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'

import { config } from '../config'
import { graphQLCache } from './cache'

let getToken: () => Promise<string | undefined | null>

export function setTokenRetriever(tokenRetriever: () => Promise<string | undefined | null>) {
  getToken = tokenRetriever
}

const authLink = setContext(async (_, { headers }) => {
  const _headers = { ...headers, client: 'latte', clientVersion: config.version }
  if (!getToken) {
    return _headers
  }
  const token = await getToken()
  if (token) {
    _headers['authorization'] = `Bearer ${token}`
  }
  return {
    headers: _headers
  }
})

const httpLink = new HttpLink({ uri: config.graphQLApiUrl })

const webSocketLink = new GraphQLWsLink(
  createClient({
    lazy: true,
    connectionParams: async () => {
      const token = await getToken
      return { token }
    },
    url: config.graphQLWsUrl || ''
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  webSocketLink,
  authLink.concat(httpLink)
)

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value)
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }

  return forward(operation)
})

export const graphQLClient = new ApolloClient({
  link: cleanTypenameLink.concat(splitLink),
  cache: graphQLCache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    },
    watchQuery: {
      fetchPolicy: 'network-only'
    }
  }
})
