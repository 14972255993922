export const config = {
  baseUrl: process.env.REACT_APP_LATTE_BASE_URL || 'http://localhost:3009',
  graphQLApiUrl: process.env.REACT_APP_LATTE_GRAPHQL_API_URL || '',
  graphQLWsUrl: process.env.REACT_APP_LATTE_GRAPHQL_WS_URL || '',
  logLevel: process.env.REACT_APP_LATTE_LOG_LEVEL || 'DEBUG',
  stripePK: process.env.REACT_APP_LATTE_STRIPE_PK || 'testKey',
  version: '1.1.6',
  auth0: {
    clientId: process.env.REACT_APP_LATTE_AUTH0_CLIENT_ID || '',
    domain: process.env.REACT_APP_LATTE_AUTH0_DOMAIN || '',
    audience: process.env.REACT_APP_LATTE_AUTH0_AUDIENCE || ''
  }
}

export function clean() {
  return {
    ...config,
    stripePK: 'hidden'
  }
}
