import {
  CartItem,
  CategoryType,
  CurrencyType,
  formatUsCentsAsUsDollar,
  generateReadableModifiers,
  getItemIntendedPaymentPriceTag
} from '@bloom-coffee/espresso'
import { Color, IconButton, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'
import { MdMode, MdOutlineAddCircle, MdOutlineRemoveCircle } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

interface CartItemContainerProps {
  readableId: string
  item: CartItem
  handlePlusClick: (item: CartItem) => void
  handleMinusClick: (item: CartItem) => void
}

export const CartItemContainer = (props: CartItemContainerProps) => {
  const { item, handlePlusClick, handleMinusClick, readableId } = props

  const { product, selectedModifiers, currencyType, alteredFromMenu } = item
  const priceTag = getItemIntendedPaymentPriceTag(currencyType, product, selectedModifiers)
  const navigate = useNavigate()

  function navigateToEditScreen() {
    navigate(`/${readableId}/products/${item.product.id}`, {
      state: { flow: 'editItem', preSelectedModifiers: selectedModifiers, cartItem: item }
    })
  }

  const hasSubtext = selectedModifiers.length > 0 || product.category?.type === CategoryType.RetailBeans
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: 12,
          paddingBottom: 12
        }}
      >
        <div style={{ flex: 1 }}>
          <button
            style={{
              cursor: 'pointer',
              border: 'none',
              background: 'transparent',
              marginRight: 24,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            onClick={navigateToEditScreen}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Text variant='header3' color={Color.RDY_FOREST}>
                {product.category?.type === CategoryType.RetailBeans ? 'Whole Bean Coffee' : product.name}
              </Text>
              <div style={{ width: 12 }} />
              <MdMode size={16} color={Color.RDY_FOREST} />
            </div>

            <div>
              <div>
                {hasSubtext && (
                  <Text variant='body3' color={Color.GREY_600}>
                    {product.category?.type !== CategoryType.RetailBeans &&
                      generateReadableModifiers(
                        product,
                        selectedModifiers.map((sm) => sm.secondLevelModifier.id)
                      )}
                    {product.category?.type === CategoryType.RetailBeans && product.roaster
                      ? `${product.roaster} - ${product.name}`
                      : product.name}
                  </Text>
                )}
              </div>

              <div>
                {!!item.notes && (
                  <Text variant='body3' color={Color.GREY_600}>
                    Notes: {item.notes}
                  </Text>
                )}
              </div>
            </div>
            {!!alteredFromMenu && <Text variant='error'>This item may be different from your last order</Text>}
          </button>
        </div>

        <div style={{ flex: 1, display: 'flex' }}>
          {currencyType === CurrencyType.UsCents && (
            <Text variant='body1'>${formatUsCentsAsUsDollar(priceTag.priceUsCents)}</Text>
          )}
        </div>

        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: 100
          }}
        >
          <IconButton
            icon={<MdOutlineAddCircle size={24} />}
            onClick={() => handlePlusClick(item)}
            iconColor={Color.RDY_FOREST}
            height={50}
            width={50}
          />
          <IconButton
            icon={<MdOutlineRemoveCircle size={24} />}
            onClick={() => handleMinusClick(item)}
            iconColor={Color.RDY_FOREST}
            height={50}
            width={50}
          />
        </div>
      </div>
    </div>
  )
}
