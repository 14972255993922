import { Cart, Maybe } from '@bloom-coffee/espresso'
import { createContext } from 'react'

export interface CartState {
  cart: Maybe<Cart>
}

interface CartContextModel extends CartState {
  notifyCartUpdated: () => void
}

function stub(): never {
  throw new Error('Component must be wrapped in <CartProvider>.')
}

const initialCartState: CartState = {
  cart: undefined
}

const initialContext: CartContextModel = {
  ...initialCartState,
  notifyCartUpdated: stub
}

export const CartContext = createContext<CartContextModel>(initialContext)
