/* eslint-disable max-len */
import { Maybe, MerchantInfo } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import AppleDownload from '../../../img/thirdparty/AppleDownload.svg'

interface SuggestAppDownloadProps {
  fontColor?: Color
  appUrl?: Maybe<string>
  possibleRewardPoints?: number
  merchant: MerchantInfo
}

const appleButtonHeight = window.innerWidth > 390 ? 45 : 39
const googleButtonHeight = appleButtonHeight * 1.42

export const SuggestAppDownload = (props: SuggestAppDownloadProps) => {
  const { possibleRewardPoints, merchant, fontColor = Color.RDY_FOREST, appUrl } = props

  let header = 'Download RDY'
  let description: Maybe<string> =
    'Download the RDY App from the App Store to earn reward points towards free drinks and food!'
  const settingsMessage = merchant.settings?.filter((set) => set.name === 'marketing_orderCompletedMessage')[0]?.value
  if (!!settingsMessage) {
    header = settingsMessage
    description = null
  } else if (!!possibleRewardPoints && possibleRewardPoints > 0) {
    header = `You could have earned ${possibleRewardPoints} reward points!`
  }

  return (
    <div style={{ maxWidth: 400, marginBottom: 15, marginTop: 24, marginLeft: 5, marginRight: 5 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: Color.RDY_PINK,
          paddingLeft: 6,
          paddingRight: 6,
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: 6,
          marginBottom: 8
        }}
      >
        <Text variant='header3' textAlign='center' color={Color.RDY_FOREST}>
          {header}
        </Text>
      </div>

      {!!description && (
        <div>
          <Text variant='body1' color={fontColor}>
            {description}
          </Text>
        </div>
      )}

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <a href={appUrl ?? 'https://apps.apple.com/us/app/rdy-coffee/id1550189384'} target='_blank' rel='noreferrer'>
            <img height={appleButtonHeight} src={AppleDownload} alt='Apple Download' />
          </a>
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <a
            href={
              appUrl ??
              'https://play.google.com/store/apps/details?id=com.bloom.v2.cappuccino&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
            }
            target='_blank'
            rel='noreferrer'
          >
            <img
              height={googleButtonHeight}
              alt='Get it on Google Play'
              src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
            />
          </a>
        </div>
      </div>
    </div>
  )
}
