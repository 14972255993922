import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** java.time.Instant implementation */
  Instant: number;
  /** java.time.LocalTime implementation */
  LocalTime: string;
  Upload: File;
};

export type Action = {
  __typename?: 'Action';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  name: ActionName;
};

export enum ActionName {
  AcceptReferral = 'AcceptReferral',
  AddBirthday = 'AddBirthday',
  AddFavoriteMerchant = 'AddFavoriteMerchant',
  AddFavoriteOrder = 'AddFavoriteOrder',
  AddHomeAddress = 'AddHomeAddress',
  AddWorkAddress = 'AddWorkAddress',
  CompleteBasicProfile = 'CompleteBasicProfile',
  CompleteReferral = 'CompleteReferral',
  PurchaseOrder = 'PurchaseOrder',
  Refer = 'Refer',
  SocialShare = 'SocialShare'
}

export type ActiveQuest = {
  __typename?: 'ActiveQuest';
  quest: Quest;
  actualStart: Maybe<Scalars['Instant']>;
  actualEnd: Maybe<Scalars['Instant']>;
  userQuestSteps: Maybe<Array<UserQuestStep>>;
};

export type AddMerchantSubscriptionFeeInput = {
  subscriptionProductId: Scalars['ID'];
  merchantId: Scalars['ID'];
};

export type AddressInput = {
  street: Scalars['String'];
  street2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  countryCode: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export enum AppType {
  Cappuccino = 'CAPPUCCINO',
  Cupping = 'CUPPING',
  Doppio = 'DOPPIO',
  Latte = 'LATTE',
  Macchiato = 'MACCHIATO',
  Mocha = 'MOCHA'
}

export type AppVersion = {
  __typename?: 'AppVersion';
  minimumIosVersion: Scalars['String'];
  minimumAndroidVersion: Scalars['String'];
  displayMessage: Scalars['String'];
};

export type AvailabilityWindow = {
  __typename?: 'AvailabilityWindow';
  id: Scalars['ID'];
  dayOfWeek: Scalars['Int'];
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
};

export type AvailabilityWindowInput = {
  dayOfWeek: Scalars['Int'];
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
};

export type CafeLeadInput = {
  organizationName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  platformPricingId: Maybe<Scalars['ID']>;
  termSubscription: Maybe<Scalars['Boolean']>;
};

export type Card = {
  __typename?: 'Card';
  brand: Scalars['String'];
  last4: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
};

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['ID'];
  items: Maybe<Array<CartItem>>;
};

export type CartInput = {
  items: Array<CartItemInput>;
};

export type CartItem = {
  __typename?: 'CartItem';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  orderableProduct: Product;
  selectedModifiers: Maybe<Array<Modifier>>;
};

export type CartItemInput = {
  id: Maybe<Scalars['ID']>;
  active: Maybe<Scalars['Boolean']>;
  productId: Scalars['ID'];
  modifiers: Maybe<Array<CartItemModifierInput>>;
};

export type CartItemModifierInput = {
  id: Maybe<Scalars['ID']>;
  active: Maybe<Scalars['Boolean']>;
  parentModifierId: Scalars['ID'];
  selectedModifierId: Scalars['ID'];
};

export type Catalog = {
  __typename?: 'Catalog';
  id: Scalars['ID'];
  name: Scalars['String'];
  sourceSystemId: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  categories: Maybe<Array<Category>>;
  products: Maybe<Array<Product>>;
  activeProducts: Maybe<Array<Product>>;
  activeCategories: Maybe<Array<Category>>;
  availabilityWindows: Maybe<Array<CatalogAvailabilityWindow>>;
  active: Scalars['Boolean'];
  suggestedItems: Maybe<Array<SuggestedItem>>;
  topProducts: Maybe<Array<Product>>;
  trendingProducts: Maybe<Array<Product>>;
  suggestedProducts: Maybe<Array<Product>>;
  isDefault: Scalars['Boolean'];
};

export type CatalogAvailabilityWindow = {
  __typename?: 'CatalogAvailabilityWindow';
  id: Scalars['ID'];
  dayOfWeek: DayOfWeek;
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
};

export type CatalogAvailabilityWindowInput = {
  dayOfWeek: DayOfWeek;
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  products: Maybe<Array<Product>>;
  activeProducts: Maybe<Array<Product>>;
  active: Scalars['Boolean'];
  type: Maybe<CategoryType>;
  sortOrder: Maybe<Scalars['Int']>;
};

export type CategoryOutputDestination = {
  __typename?: 'CategoryOutputDestination';
  id: Scalars['ID'];
  category: Category;
  active: Scalars['Boolean'];
};

export type CategoryOutputDestinationInput = {
  categoryOutputDestinationId: Scalars['ID'];
  active: Scalars['Boolean'];
};

export enum CategoryType {
  SpecialtyBeverages = 'SPECIALTY_BEVERAGES',
  Coffee = 'COFFEE',
  Tea = 'TEA',
  NotCoffee = 'NOT_COFFEE',
  Food = 'FOOD',
  KitchenFood = 'KITCHEN_FOOD',
  RetailMerch = 'RETAIL_MERCH',
  RetailBeans = 'RETAIL_BEANS',
  Beer = 'BEER',
  Wine = 'WINE',
  Cocktails = 'COCKTAILS',
  NonAlcoholic = 'NON_ALCOHOLIC',
  Seltzers = 'SELTZERS'
}

export type CheckoutConfirmation = {
  __typename?: 'CheckoutConfirmation';
  order: Order;
  rewardData: Maybe<RewardData>;
  paymentIntent: Maybe<PaymentIntent>;
  completedReferral: Maybe<Referral>;
  organizationRewardData: Array<RewardData>;
};

export type CheckoutOrderInput = {
  order: OrderInput;
  timing: OrderTiming;
  placeInSeconds: Maybe<Scalars['Int']>;
  tipUsCents: Scalars['Int'];
  shouldConfirmPayment: Scalars['Boolean'];
  coordinates: Maybe<Coordinates>;
  paymentMethodId: Maybe<Scalars['String']>;
  diningOption: Maybe<OrderDiningOption>;
  servingLocationId: Maybe<Scalars['ID']>;
};

export type Contract = {
  __typename?: 'Contract';
  id: Scalars['ID'];
  content: Scalars['String'];
  contractType: ContractType;
};

export type ContractInput = {
  contractType: ContractType;
  content: Scalars['String'];
};

export type ContractSignature = {
  __typename?: 'ContractSignature';
  id: Scalars['ID'];
  contract: Contract;
  user: User;
  organization: Organization;
  createdOn: Scalars['Instant'];
};

export enum ContractType {
  FreshContract = 'FRESH_CONTRACT',
  StandardContract = 'STANDARD_CONTRACT'
}

export type Coordinates = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  altitude: Maybe<Scalars['Float']>;
  accuracy: Maybe<Scalars['Float']>;
  altitudeAccuracy: Maybe<Scalars['Float']>;
  heading: Maybe<Scalars['Float']>;
  speed: Maybe<Scalars['Float']>;
};

export type CopyAllProductsFromMerchantInput = {
  fromMerchantId: Scalars['ID'];
  toMerchantId: Scalars['ID'];
};

export type CreateCatalogInput = {
  merchantId: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  availabilityWindows: Array<CatalogAvailabilityWindowInput>;
  productIds: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateCategoryInput = {
  merchantId: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  sortOrder: Maybe<Scalars['Int']>;
  type: Maybe<CategoryType>;
};

export type CreateFulfillmentLocationInput = {
  merchantId: Scalars['ID'];
  displayName: Scalars['String'];
  fulfillmentLocationType: FulfillmentLocationType;
};

export type CreateMerchantContactInput = {
  merchantId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreateMerchantFeeConfigInput = {
  staticFeeInUsCents: Scalars['Int'];
  percentageFee: Scalars['Float'];
};

export type CreateMerchantKdsIntegrationInput = {
  merchantId: Scalars['ID'];
  type: IntegrationType;
  accessKey: Scalars['String'];
  locationId: Scalars['String'];
  displayName: Scalars['String'];
  sendOrdersToRdy: Scalars['Boolean'];
};

export type CreateOrderEventsInput = {
  orderId: Scalars['ID'];
  eventNames: Array<OrderEventName>;
};

export type CreateOrganizationInput = {
  name: Scalars['String'];
};

export type CreateProductInput = {
  merchantId: Scalars['ID'];
  categoryId: Scalars['ID'];
  catalogIds: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  priceCents: Scalars['Int'];
  sizeInGrams: Maybe<Scalars['Int']>;
  originType: Maybe<OriginType>;
  roaster: Maybe<Scalars['String']>;
  roastType: Maybe<RoastType>;
  topModifiers: Maybe<Array<TopModifierInput>>;
  productTags: Maybe<Array<ProductTagInput>>;
  images: Maybe<Array<ProductImageInput>>;
  inventory: Maybe<ProductInventoryInput>;
  availabilityWindows: Maybe<Array<AvailabilityWindowInput>>;
};

export type CreateQuestInput = {
  name: Scalars['String'];
  codename: Scalars['String'];
  description: Scalars['String'];
  start: DateTimeInfoInput;
  end: DateTimeInfoInput;
  topStep: CreateQuestStepInput;
};

export type CreateQuestStepEffectInput = {
  rdyPoints: Maybe<RdyPointsInput>;
  organizationId: Maybe<Scalars['ID']>;
};

export type CreateQuestStepEffectModifierInput = {
  questStepEffectId: Scalars['ID'];
  modifications: QuestStepEffectModificationsInput;
};

export type CreateQuestStepInput = {
  name: Scalars['String'];
  action: Maybe<ActionName>;
  sortOrder: Maybe<Scalars['Int']>;
  completeAfterIterations: Maybe<Scalars['Int']>;
  rules: Maybe<QuestStepRulesInput>;
  effects: Maybe<Array<CreateQuestStepEffectInput>>;
  children: Maybe<Array<CreateQuestStepInput>>;
};

export type CreateSubscriptionFeeInput = {
  costUsCents: Scalars['Int'];
  name: Scalars['String'];
};

export type CreateSubscriptionPlanInput = {
  costUsCents: Scalars['Int'];
  name: Scalars['String'];
  recurringInterval: RecurringInterval;
};

export type CsrfToken = {
  __typename?: 'CsrfToken';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  userId: Scalars['ID'];
  value: Scalars['String'];
};

export type CurrencySummary = {
  __typename?: 'CurrencySummary';
  subtotal: Scalars['Int'];
  fee: Scalars['Int'];
  tax: Scalars['Int'];
  tip: Scalars['Int'];
  total: Scalars['Int'];
  discount: Scalars['Int'];
  merchantFee: Scalars['Int'];
};

export enum CurrencyType {
  BloomToken = 'BLOOM_TOKEN',
  UsCents = 'US_CENTS'
}

export type DateTimeInfo = {
  __typename?: 'DateTimeInfo';
  dayOfWeek: Maybe<DayOfWeek>;
  day: Maybe<Scalars['Int']>;
  month: Maybe<Scalars['Int']>;
  year: Maybe<Scalars['Int']>;
};

export type DateTimeInfoInput = {
  dayOfWeek: Maybe<DayOfWeek>;
  day: Maybe<Scalars['Int']>;
  month: Maybe<Scalars['Int']>;
  year: Maybe<Scalars['Int']>;
};

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type DeepLinkClick = {
  __typename?: 'DeepLinkClick';
  userId: Scalars['ID'];
  link: Scalars['String'];
  clickedOn: Maybe<Scalars['Instant']>;
  parsedViaMobileOn: Maybe<Scalars['Instant']>;
  customData: Maybe<Array<KeyValue>>;
};

export type DeepLinkClickInput = {
  link: Scalars['String'];
  clickedOn: Maybe<Scalars['Instant']>;
  parsedViaMobileOn: Maybe<Scalars['Instant']>;
  customData: Maybe<Array<KeyValueInput>>;
};

export type DefaultSubscriptionProduct = {
  __typename?: 'DefaultSubscriptionProduct';
  subscriptionProduct: Maybe<SubscriptionProduct>;
  defaultSubscriptionType: Maybe<DefaultSuscriptionType>;
};

export enum DefaultSuscriptionType {
  StandardSubscription = 'STANDARD_SUBSCRIPTION',
  StandardFee = 'STANDARD_FEE',
  FreshSubscription = 'FRESH_SUBSCRIPTION',
  FreshFee = 'FRESH_FEE'
}

export type DeviceInfoInput = {
  deviceId: Scalars['String'];
  os: Maybe<Scalars['String']>;
  apnsToken: Maybe<Scalars['String']>;
  fcmToken: Maybe<Scalars['String']>;
};

export enum DiningOption {
  ForHere = 'FOR_HERE',
  Takeaway = 'TAKEAWAY',
  TableService = 'TABLE_SERVICE'
}

export type DiscountPromotionData = {
  __typename?: 'DiscountPromotionData';
  promotion: Promotion;
  usCentsDeductedFromSubtotal: Scalars['Int'];
};

export type DuplicateUser = {
  __typename?: 'DuplicateUser';
  id: Scalars['ID'];
  accessIdentifier: Scalars['String'];
};

export type ExternalClientSync = {
  __typename?: 'ExternalClientSync';
  id: Scalars['ID'];
  startedOn: Scalars['Instant'];
  completedOn: Maybe<Scalars['Instant']>;
  externalSourceId: Maybe<Scalars['String']>;
  events: Maybe<Array<Maybe<ExternalClientSyncEvent>>>;
  identifier: Maybe<Scalars['String']>;
};

export type ExternalClientSyncEvent = {
  __typename?: 'ExternalClientSyncEvent';
  timestamp: Scalars['Instant'];
  eventType: Scalars['String'];
  url: Maybe<Scalars['String']>;
  response: Maybe<Scalars['String']>;
};

export enum ExternalDataSource {
  Chowly = 'CHOWLY',
  Fresh = 'FRESH',
  Square = 'SQUARE'
}

export type FavoriteMerchant = {
  __typename?: 'FavoriteMerchant';
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
  merchant: Merchant;
  user: User;
};

export type FavoriteOrder = {
  __typename?: 'FavoriteOrder';
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
  order: Order;
  user: User;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  name: FeatureFlagName;
  enabled: Scalars['Boolean'];
};

export enum FeatureFlagName {
  PaymentRequired = 'PAYMENT_REQUIRED',
  MerchantPayoutEnabled = 'MERCHANT_PAYOUT_ENABLED',
  RdyPassV1 = 'RDY_PASS_V1',
  Refunds = 'REFUNDS',
  OrderNotes = 'ORDER_NOTES',
  SubscriptionRequired = 'SUBSCRIPTION_REQUIRED',
  WebOrdering = 'WEB_ORDERING'
}

export enum Frequency {
  Weekly = 'WEEKLY'
}

export type FreshAddress = {
  __typename?: 'FreshAddress';
  line1: Scalars['String'];
  line2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  phone: Maybe<Scalars['String']>;
};

export type FreshLocation = {
  __typename?: 'FreshLocation';
  id: Scalars['String'];
  name: Scalars['String'];
  address: FreshAddress;
};

export type FreshLocationSync = {
  __typename?: 'FreshLocationSync';
  locationId: Scalars['String'];
  lastSyncCompleted: Maybe<Scalars['Instant']>;
};

export type FulfillmentLocation = {
  __typename?: 'FulfillmentLocation';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  urlSafeName: Scalars['String'];
  appUrl: Maybe<Scalars['String']>;
  webUrl: Maybe<Scalars['String']>;
  fulfillmentLocationType: Maybe<FulfillmentLocationType>;
  qrCodeDisplayUrl: Maybe<Scalars['String']>;
};

export enum FulfillmentLocationType {
  PickUp = 'PICK_UP',
  TableService = 'TABLE_SERVICE'
}

export type GenerateOrderPaymentSummaryInput = {
  order: OrderInput;
  tipUsCents: Scalars['Int'];
  diningOption: Maybe<OrderDiningOption>;
  timing: Maybe<OrderTiming>;
  placeInSeconds: Maybe<Scalars['Int']>;
};

export enum GeoFenceType {
  WalkingDistance = 'WalkingDistance',
  DrivingDistance = 'DrivingDistance'
}

export type GrantTokensInput = {
  userId: Scalars['ID'];
  tokenType: TokenTypeName;
  numberOfTokens: Scalars['Int'];
};

export type InitializeOrderInput = {
  userProfile: OrderPaymentIntentUserProfile;
  order: OrderInput;
  timing: OrderTiming;
  placeInSeconds: Maybe<Scalars['Int']>;
  tipUsCents: Scalars['Int'];
  diningOption: Maybe<OrderDiningOption>;
  servingLocationId: Maybe<Scalars['ID']>;
};

export type InitializeOrderOutput = {
  __typename?: 'InitializeOrderOutput';
  order: Order;
  paymentIntent: Maybe<InitializeOrderPaymentIntent>;
};

export type InitializeOrderPaymentIntent = {
  __typename?: 'InitializeOrderPaymentIntent';
  id: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type InitializeReferralInput = {
  referralCode: Scalars['String'];
  deviceId: Scalars['String'];
};

export type InitializeRequisitionInput = {
  itemIds: Array<Scalars['ID']>;
  fulfillment: RequisitionFulfillmentInput;
  code: Maybe<Scalars['String']>;
};


export enum IntegrationType {
  FreshKds = 'FRESH_KDS',
  Square = 'SQUARE',
  Chowly = 'CHOWLY'
}

export type Inventory = {
  __typename?: 'Inventory';
  id: Scalars['ID'];
  inventoryType: InventoryType;
  restockQuantity: Maybe<Scalars['Int']>;
  currentQuantity: Maybe<Scalars['Int']>;
  currentlyAvailable: Scalars['Boolean'];
  restockDays: Maybe<Array<InventoryRestockDay>>;
};

export enum InventoryAvailability {
  Available = 'AVAILABLE',
  LowStock = 'LOW_STOCK',
  NotAvailable = 'NOT_AVAILABLE'
}

export type InventoryRestockDay = {
  __typename?: 'InventoryRestockDay';
  dayOfWeek: Scalars['Int'];
};

export enum InventoryType {
  AlwaysAvailable = 'ALWAYS_AVAILABLE',
  LimitedQuantity = 'LIMITED_QUANTITY'
}

export type IoTDevice = {
  __typename?: 'IoTDevice';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  merchantId: Maybe<Scalars['ID']>;
  type: IoTDeviceType;
  positions: Maybe<Array<IoTPosition>>;
};

export enum IoTDeviceType {
  Printer = 'PRINTER',
  RdyPassV1 = 'RDY_PASS_V1'
}

export type IoTEvent = {
  __typename?: 'IoTEvent';
  id: Scalars['ID'];
  name: IoTEventName;
  position: IoTPosition;
};

export type IoTEventInput = {
  name: IoTEventName;
  deviceUuid: Scalars['String'];
  positionCode: Scalars['String'];
};

export enum IoTEventName {
  ObjectMovedToDevice = 'OBJECT_MOVED_TO_DEVICE',
  ObjectRemovedFromDevice = 'OBJECT_REMOVED_FROM_DEVICE'
}

export type IoTPosition = {
  __typename?: 'IoTPosition';
  id: Scalars['ID'];
  code: PositionCode;
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['ID'];
  name: Scalars['String'];
  priceCents: Scalars['Int'];
  category: ItemCategory;
  shippingRequired: Scalars['Boolean'];
  scheduleFrequency: Maybe<RequisitionScheduleFrequency>;
};

export enum ItemCategory {
  Beans = 'Beans'
}

export type Kds = {
  __typename?: 'Kds';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  merchant: Merchant;
  authorizedBy: User;
  active: Scalars['Boolean'];
  /** Orders that are not on queue yet */
  placedOrders: Maybe<Array<Order>>;
  orderQueue: Maybe<Array<Order>>;
  todaysCompletedOrders: Maybe<Array<Order>>;
  completedOrders: Array<Order>;
  os: Maybe<Scalars['String']>;
  appVersion: Maybe<Scalars['String']>;
};


export type KdsTodaysCompletedOrdersArgs = {
  offset: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
};


export type KdsCompletedOrdersArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type KdsEvent = {
  __typename?: 'KdsEvent';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  timestamp: Maybe<Scalars['Instant']>;
  name: Scalars['String'];
  extras: Maybe<Scalars['String']>;
};

export type KdsEventInput = {
  name: Scalars['String'];
  timestamp: Maybe<Scalars['Instant']>;
  kdsUuid: Scalars['String'];
  extras: Maybe<Scalars['String']>;
};

export type KdsIntegrationDevice = {
  __typename?: 'KdsIntegrationDevice';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  name: Scalars['String'];
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type KeyValueInput = {
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type Lead = {
  __typename?: 'Lead';
  name: Maybe<Scalars['String']>;
  phoneNumberHasAccount: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
};

export type LeadInput = {
  name: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
};

export type LibraryImage = {
  __typename?: 'LibraryImage';
  id: Scalars['ID'];
  imageUri: Scalars['String'];
  imageCode: Scalars['String'];
};


export type LoginResponse = {
  __typename?: 'LoginResponse';
  type: LoginResponseType;
  duplicateAccounts: Maybe<Array<Maybe<DuplicateUser>>>;
  me: Maybe<User>;
};

export enum LoginResponseType {
  ExistingUser = 'EXISTING_USER',
  NewUser = 'NEW_USER',
  DuplicateUser = 'DUPLICATE_USER'
}

export type Market = {
  __typename?: 'Market';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  geographicCenterLatitude: Maybe<Scalars['Float']>;
  geographicCenterLongitude: Maybe<Scalars['Float']>;
  geographicRadiusKm: Maybe<Scalars['Int']>;
  launchPromotion: Maybe<Promotion>;
};

export type MarketInput = {
  id: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
  geographicCenterLatitude: Maybe<Scalars['Float']>;
  geographicCenterLongitude: Maybe<Scalars['Float']>;
  geographicRadiusKm: Maybe<Scalars['Int']>;
  launchPromotionId: Maybe<Scalars['ID']>;
};

export type MarketPushInput = {
  messageType: MessageType;
  marketId: Scalars['ID'];
  content: PushContentInput;
};

export enum MarketType {
  Geo = 'GEO'
}

export type MenuSyncConfig = {
  __typename?: 'MenuSyncConfig';
  id: Scalars['ID'];
  merchant: Merchant;
  source: ExternalDataSource;
  externalOrganizationId: Scalars['String'];
  externalLocationId: Scalars['String'];
  onlyWebCatalogItems: Scalars['Boolean'];
  active: Scalars['Boolean'];
};

export type Merchant = {
  __typename?: 'Merchant';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  organization: Organization;
  location: MerchantLocation;
  active: Scalars['Boolean'];
  catalogs: Maybe<Array<Catalog>>;
  activeCatalog: Maybe<Catalog>;
  products: Maybe<Array<Product>>;
  operationHours: Maybe<Array<MerchantHours>>;
  kdss: Maybe<Array<Kds>>;
  archived: Scalars['Boolean'];
  taxRate: Maybe<Scalars['Float']>;
  images: Maybe<Array<MerchantImage>>;
  merchantContacts: Maybe<Array<MerchantContact>>;
  diningOptions: Array<DiningOption>;
  deepLinks: Maybe<Array<MerchantDeepLink>>;
  stripeCustomerId: Maybe<Scalars['String']>;
  merchantFeeConfig: MerchantFeeConfig;
  kdsIntegrations: Maybe<Array<MerchantKdsIntegration>>;
  outputDestinations: Maybe<Array<OutputDestination>>;
  freshLocation: Maybe<FreshLocationSync>;
  readableId: Maybe<Scalars['String']>;
  settings: Array<MerchantSettingOutput>;
  source: Maybe<ExternalDataSource>;
  sourceSystemId: Maybe<Scalars['String']>;
  servingLocations: Maybe<Array<FulfillmentLocation>>;
  categories: Maybe<Array<Category>>;
  /** legacy */
  diningOption: Maybe<MerchantDiningOption>;
};

export type MerchantContact = {
  __typename?: 'MerchantContact';
  id: Scalars['ID'];
  merchant: Merchant;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MerchantDeepLink = {
  __typename?: 'MerchantDeepLink';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  link: Scalars['String'];
  description: Scalars['String'];
};

export enum MerchantDiningOption {
  ForHere = 'FOR_HERE',
  Takeaway = 'TAKEAWAY',
  ForHereAndTakeaway = 'FOR_HERE_AND_TAKEAWAY'
}

export type MerchantFeeConfig = {
  __typename?: 'MerchantFeeConfig';
  id: Scalars['ID'];
  default: Scalars['Boolean'];
  staticFeeInUsCents: Scalars['Int'];
  percentageFee: Scalars['Float'];
};

export type MerchantHours = {
  __typename?: 'MerchantHours';
  id: Scalars['ID'];
  merchant: Merchant;
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
  durationMinutes: Scalars['Int'];
  dayOfWeek: Scalars['Int'];
  isActive: Scalars['Boolean'];
};

export type MerchantHoursInput = {
  weekDay: Scalars['Int'];
  timeIntervals: Maybe<Array<TimeIntervalInput>>;
};

export type MerchantImage = {
  __typename?: 'MerchantImage';
  id: Scalars['ID'];
  imageUri: Scalars['String'];
  createdOn: Scalars['Instant'];
  sortOrder: Scalars['Int'];
};

export type MerchantImageInput = {
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
};

export type MerchantInput = {
  id: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  organizationId: Maybe<Scalars['ID']>;
  location: MerchantLocationInput;
  hours: Array<MerchantHoursInput>;
  taxRate: Scalars['Float'];
  diningOptions: Array<DiningOption>;
};

export type MerchantKdsIntegration = {
  __typename?: 'MerchantKdsIntegration';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  type: IntegrationType;
  locationId: Scalars['String'];
  displayName: Scalars['String'];
  active: Scalars['Boolean'];
  devices: Maybe<Array<KdsIntegrationDevice>>;
  sendOrdersToRdy: Scalars['Boolean'];
};

export type MerchantLocation = {
  __typename?: 'MerchantLocation';
  id: Scalars['ID'];
  street: Scalars['String'];
  street2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  countryCode: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  zipCode: Scalars['String'];
  timeZone: Maybe<Scalars['String']>;
  geoFenceType: GeoFenceType;
};

export type MerchantLocationInput = {
  id: Maybe<Scalars['ID']>;
  street: Scalars['String'];
  street2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  countryCode: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  geoFenceType: GeoFenceType;
};

export type MerchantPayout = {
  __typename?: 'MerchantPayout';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  merchant: Merchant;
  periodStartDate: Scalars['Instant'];
  periodEndDate: Scalars['Instant'];
  payoutAmount: Scalars['Int'];
  payoutType: PayoutType;
  manualPayout: Scalars['Boolean'];
  orders: Maybe<Array<OrderReportingDetails>>;
  tokenOrders: Maybe<Array<OrderReportingDetails>>;
  referrals: Maybe<Array<MerchantReferral>>;
  discountOrders: Maybe<Array<OrderReportingDetails>>;
  stripePaymentUrl: Maybe<Scalars['String']>;
  paidOn: Maybe<Scalars['Instant']>;
};

export type MerchantReferral = {
  __typename?: 'MerchantReferral';
  id: Scalars['ID'];
  deepLinkClick: DeepLinkClick;
  createdOn: Scalars['Instant'];
};

export type MerchantSettingOutput = {
  __typename?: 'MerchantSettingOutput';
  name: Scalars['String'];
  description: Scalars['String'];
  category: Scalars['String'];
  value: Maybe<Scalars['String']>;
  valueType: SettingValueType;
};

export type MerchantSubscription = {
  __typename?: 'MerchantSubscription';
  id: Scalars['ID'];
  subscriptionPlan: Maybe<SubscriptionProduct>;
  merchant: Merchant;
  stripeSubscription: Maybe<StripeSubscription>;
  stripeSetupDate: Maybe<Scalars['Instant']>;
  trialEndDate: Maybe<Scalars['Instant']>;
  organizationManaged: Scalars['Boolean'];
  platformPricing: Maybe<PlatformPricing>;
  termPricing: Maybe<Scalars['Boolean']>;
  subscriptionStatus: Maybe<SubscriptionStatus>;
};

export type MerchantSubscriptionFee = {
  __typename?: 'MerchantSubscriptionFee';
  id: Scalars['ID'];
  subscriptionFee: SubscriptionProduct;
  merchant: Merchant;
  stripeInvoiceItemId: Maybe<Scalars['String']>;
  paid: Scalars['Boolean'];
};

export enum MessageType {
  EmailOrderStatusOfflineKdsAdmin = 'EMAIL_ORDER_STATUS_OFFLINE_KDS_ADMIN',
  EmailStaleOrderRecap = 'EMAIL_STALE_ORDER_RECAP',
  PushUser = 'PUSH_USER',
  PushMarket = 'PUSH_MARKET',
  PushGeneral = 'PUSH_GENERAL',
  PushOrderStatus = 'PUSH_ORDER_STATUS',
  PushOrderStatusPreparing = 'PUSH_ORDER_STATUS_PREPARING',
  PushOrderStatusCompleted = 'PUSH_ORDER_STATUS_COMPLETED',
  PushOrderSuggestion = 'PUSH_ORDER_SUGGESTION',
  PushOrderSuggestionSecondOrder = 'PUSH_ORDER_SUGGESTION_SECOND_ORDER',
  PushPromotion = 'PUSH_PROMOTION',
  SmsOrderStatusOfflineKdsAdmin = 'SMS_ORDER_STATUS_OFFLINE_KDS_ADMIN',
  TeamsOrderStatusOfflineKds = 'TEAMS_ORDER_STATUS_OFFLINE_KDS',
  SmsOrderStatusStaleUser = 'SMS_ORDER_STATUS_STALE_USER',
  TeamsOrderStatusStale = 'TEAMS_ORDER_STATUS_STALE'
}

export type Modifier = {
  __typename?: 'Modifier';
  id: Scalars['ID'];
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  childSelectionType: Maybe<ModifierChildSelectionType>;
  children: Maybe<Array<Modifier>>;
  allChildren: Maybe<Array<Modifier>>;
  priceCents: Scalars['Int'];
  priceTokens: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  selectedName: Maybe<Scalars['String']>;
  defaultSelectionIds: Maybe<Array<Scalars['ID']>>;
  merchantId: Maybe<Scalars['ID']>;
  archived: Scalars['Boolean'];
  isSynced: Scalars['Boolean'];
  inventory: Maybe<Inventory>;
  availability: Maybe<InventoryAvailability>;
  /** legacy */
  selected: Maybe<Scalars['Boolean']>;
};

export type ModifierChildInput = {
  modifierId: Scalars['ID'];
  sortOrder: Maybe<Scalars['Int']>;
};

export enum ModifierChildSelectionType {
  SingleSelect = 'SINGLE_SELECT',
  MultiSelect = 'MULTI_SELECT'
}

export type ModifierInput = {
  id: Maybe<Scalars['ID']>;
  merchantId: Scalars['ID'];
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  childSelectionType: Maybe<ModifierChildSelectionType>;
  priceCents: Maybe<Scalars['Int']>;
  selectedName: Maybe<Scalars['String']>;
  defaultSelectionIds: Maybe<Array<Scalars['ID']>>;
  options: Maybe<Array<ModifierChildInput>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  setFeatureFlag: Maybe<FeatureFlag>;
  createCsrfToken: CsrfToken;
  assignRdyInternalSquareProductionAccessToken: Scalars['Boolean'];
  leadReceived: Scalars['Boolean'];
  addToMyCart: Cart;
  updateMyCart: Cart;
  createCatalog: Catalog;
  updateCatalog: Catalog;
  deleteCatalog: Scalars['Boolean'];
  updateSuggestedItems: Catalog;
  copyAllProductsFromMerchant: Scalars['Boolean'];
  processInventoryRestocks: Scalars['Boolean'];
  createProduct: Product;
  updateProduct: Product;
  duplicateProduct: Product;
  updateProductActive: Product;
  updateProductsActive: Array<Product>;
  archiveProduct: Product;
  archiveProducts: Array<Product>;
  createModifier: Modifier;
  updateModifier: Modifier;
  updateModifierActive: Modifier;
  archiveModifier: Modifier;
  archiveModifiers: Array<Modifier>;
  updateModifierDefaultSelections: Modifier;
  updateProductInventory: Inventory;
  updateTopModifierUsage: Array<Product>;
  createTemplateMenu: Maybe<Array<Product>>;
  createCategory: Maybe<Category>;
  updateCategorySortOrders: Maybe<Scalars['Boolean']>;
  updateProductSortOrders: Maybe<Scalars['Boolean']>;
  updateCategoryName: Category;
  updateProductName: Product;
  archiveCategory: Maybe<Scalars['Boolean']>;
  updateProductCategories: Maybe<Scalars['Boolean']>;
  saveContractSignature: ContractSignature;
  saveContract: Contract;
  updateMenuSyncConfigActive: Maybe<MenuSyncConfig>;
  /** Fresh */
  syncFreshMenu: Scalars['Boolean'];
  createMerchantFromLocation: Maybe<Merchant>;
  syncSquareMenu: Scalars['Boolean'];
  /** Square */
  updateSquareOrderStates: Scalars['Boolean'];
  generateSquareOAuthToken: Scalars['Boolean'];
  refreshSquareOAuthTokens: Scalars['Int'];
  syncSquareLocations: Array<Merchant>;
  archiveFulfillmentLocation: Scalars['Boolean'];
  createFulfillmentLocation: FulfillmentLocation;
  generateQRCodeDisplay: Scalars['String'];
  uploadMerchantImage: MerchantImage;
  updateMerchantImages: Array<MerchantImage>;
  deleteMerchantImage: Scalars['Boolean'];
  uploadImage: Scalars['String'];
  uploadImageByUrl: Scalars['String'];
  registerIoTDevice: IoTDevice;
  processIoTEvent: IoTEvent;
  setUpPass: PassConfig;
  registerKds: Kds;
  unlinkKds: Scalars['Boolean'];
  updateKdsVersionInfo: Kds;
  kdsHeartbeat: Scalars['Boolean'];
  kdsHeartbeatByDeviceUuid: Scalars['Boolean'];
  checkKdsHealth: Scalars['Boolean'];
  createKdsEvents: Array<KdsEvent>;
  createMerchantKdsIntegration: MerchantKdsIntegration;
  archiveMerchantKdsIntegration: Scalars['Boolean'];
  updateMerchantKdsIntegrationActive: MerchantKdsIntegration;
  setupKdsIntegrationDevices: MerchantKdsIntegration;
  trackMyLocation: Maybe<Scalars['Boolean']>;
  saveDeviceLocation: Maybe<Scalars['Boolean']>;
  archiveMerchant: Merchant;
  createMerchant: Merchant;
  updateMerchant: Merchant;
  updateMerchantActive: Merchant;
  archiveMerchantPaymentAccount: Merchant;
  setMerchantPaymentAccount: Merchant;
  createMarket: Market;
  updateMarket: Market;
  moveMerchantToDifferentOrganization: Scalars['Boolean'];
  sendStaleOrderRecap: Scalars['Boolean'];
  updateMerchantReadableId: Merchant;
  updateMerchantBooleanSetting: MerchantSettingOutput;
  updateMerchantSourceSystem: Merchant;
  createMerchantContact: MerchantContact;
  updateMerchantContact: MerchantContact;
  deleteMerchantContact: Scalars['Boolean'];
  archiveMerchantFeeConfig: Scalars['Boolean'];
  createMerchantFeeConfig: MerchantFeeConfig;
  setDefaultMerchantFeeConfig: Array<MerchantFeeConfig>;
  setMerchantFeeConfigForMerchant: Merchant;
  createSubscriptionPlan: SubscriptionProduct;
  createSubscriptionFee: SubscriptionProduct;
  setMerchantSubscription: MerchantSubscription;
  setMerchantSubscriptions: Array<MerchantSubscription>;
  addMerchantSubscriptionFee: MerchantSubscriptionFee;
  createStripeSubscription: Maybe<SubscriptionPaymentIntent>;
  refreshSubscriptionStatus: Array<MerchantSubscription>;
  updateSubscriptionPaymentMethod: StripeSubscription;
  updateMerchantsOnOrgSubscription: Array<MerchantSubscription>;
  cancelStripeSubscription: Array<MerchantSubscription>;
  updateTrialSubscriptions: Maybe<Scalars['Boolean']>;
  migrateTrialingSubscriptions: Maybe<Scalars['Boolean']>;
  setDefaultSubscriptionProduct: Maybe<Scalars['Boolean']>;
  savePlatformPricing: Maybe<PlatformPricing>;
  createPlatformPricingPaymentIntent: Maybe<SubscriptionPaymentIntent>;
  sendSupportEmail: Scalars['Boolean'];
  sendPushToMarket: Scalars['Boolean'];
  sendPushToUsers: Scalars['Boolean'];
  processLeads: Array<Lead>;
  syncCustomerUserToCustomerIO: Scalars['Boolean'];
  syncPartnerUserToCustomerIO: Scalars['Boolean'];
  syncOrganizationToCustomerIO: Scalars['Boolean'];
  validateOrder: Maybe<Scalars['Boolean']>;
  generateOrderPaymentSummary: PaymentSummary;
  checkoutOrder: CheckoutConfirmation;
  createOrderEvents: Order;
  addOrderToQueue: Maybe<Order>;
  checkPendingOrderPaymentIntents: Maybe<Scalars['Boolean']>;
  checkPendingTimerOrders: Maybe<Scalars['Boolean']>;
  checkStaleOrders: Maybe<Scalars['Boolean']>;
  checkPendingFreshKdsOrders: Maybe<Scalars['Boolean']>;
  processOrderItemEvent: OrderItemEvent;
  refundOrder: Maybe<Array<OrderTransactionRefund>>;
  refundOrderItem: Maybe<Array<OrderTransactionRefund>>;
  updateOutputDestinationCategories: Array<OutputDestination>;
  initializeOrder: InitializeOrderOutput;
  createOrganization: Organization;
  updateOrganization: Organization;
  archiveOrganization: Organization;
  setupOrganization: Organization;
  setOrganizationPaymentAccount: Organization;
  archiveOrganizationPaymentAccount: Organization;
  addOrganizationUserRole: Maybe<Scalars['Boolean']>;
  removeOrganizationUserRole: Maybe<Scalars['Boolean']>;
  updateOrganizationReadableId: Organization;
  updateOrganizationBooleanSetting: OrganizationSettingOutput;
  updateOrganizationNumericSetting: OrganizationSettingOutput;
  saveMyPaymentMethod: PaymentMethod;
  deletePaymentMethod: Maybe<PaymentMethod>;
  setupPaymentAccount: Scalars['String'];
  createSetupIntent: Scalars['String'];
  syncPayout: Scalars['Boolean'];
  generatePayout: MerchantPayout;
  payoutMerchant: Maybe<MerchantPayout>;
  prepareMerchantPayouts: Scalars['Boolean'];
  createPromotion: Promotion;
  createMerchantPromotion: Promotion;
  adjudicatePromotion: Promotion;
  archivePromotion: Promotion;
  createPromoCode: PromoCode;
  processPromoCode: ProcessPromoCodeResponse;
  checkPromotionRewardDispensation: Maybe<Scalars['Boolean']>;
  checkEnterUserInMarketLaunchPromo: Array<Token>;
  notifyPendingPromotions: Maybe<Scalars['Boolean']>;
  notifyActivePromotions: Maybe<Scalars['Boolean']>;
  checkStaleUserPromotions: Maybe<Scalars['Boolean']>;
  updatePromotion: Promotion;
  updatePromotionCode: PromoCode;
  createQuest: Quest;
  archiveQuest: Quest;
  updateQuestStatus: Quest;
  createQuestStepEffectModifier: QuestStepEffectModifier;
  createRdyPoints: UserRdyPoints;
  grantTokens: Array<Token>;
  redeemPoints: RewardData;
  redeemPointsByOrganization: RewardData;
  initializeReferral: Referral;
  initializeRequisition: RequisitionPurchase;
  updateRequisition: Requisition;
  purchaseScheduledRequisitions: Array<RequisitionPurchase>;
  chargeSchedule: RequisitionPurchase;
  deleteMyAccount: Scalars['Boolean'];
  updateMyDeviceInfo: Maybe<Scalars['Boolean']>;
  updateMyProfile: UserProfile;
  acceptUserInvite: User;
  sendUserInvite: Scalars['String'];
  updateMyFavoriteMerchant: Maybe<FavoriteMerchant>;
  processDeepLinkClicks: Maybe<Array<DeepLinkClick>>;
  linkAccount: User;
  validateAndCreateUser: LoginResponse;
  updateMyFavoriteOrder: Maybe<FavoriteOrder>;
  updateBooleanSetting: UserSettingOutput;
};


export type MutationSetFeatureFlagArgs = {
  featureFlagName: Maybe<FeatureFlagName>;
  enabled: Scalars['Boolean'];
  organizationId: Maybe<Scalars['ID']>;
  merchantId: Maybe<Scalars['ID']>;
};


export type MutationCreateCsrfTokenArgs = {
  data: Maybe<Scalars['String']>;
};


export type MutationAssignRdyInternalSquareProductionAccessTokenArgs = {
  userId: Scalars['ID'];
};


export type MutationLeadReceivedArgs = {
  input: CafeLeadInput;
};


export type MutationAddToMyCartArgs = {
  input: CartInput;
};


export type MutationUpdateMyCartArgs = {
  input: CartInput;
};


export type MutationCreateCatalogArgs = {
  input: CreateCatalogInput;
};


export type MutationUpdateCatalogArgs = {
  input: UpdateCatalogInput;
};


export type MutationDeleteCatalogArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateSuggestedItemsArgs = {
  input: UpdateSuggestedItemsInput;
};


export type MutationCopyAllProductsFromMerchantArgs = {
  input: CopyAllProductsFromMerchantInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


export type MutationDuplicateProductArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateProductActiveArgs = {
  productId: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationUpdateProductsActiveArgs = {
  productIds: Array<Scalars['ID']>;
  active: Scalars['Boolean'];
};


export type MutationArchiveProductArgs = {
  id: Scalars['ID'];
};


export type MutationArchiveProductsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateModifierArgs = {
  input: ModifierInput;
};


export type MutationUpdateModifierArgs = {
  input: UpdateModifierInput;
};


export type MutationUpdateModifierActiveArgs = {
  modifierId: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationArchiveModifierArgs = {
  id: Scalars['ID'];
};


export type MutationArchiveModifiersArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateModifierDefaultSelectionsArgs = {
  topModifierId: Scalars['ID'];
  selectionIds: Array<Scalars['ID']>;
};


export type MutationUpdateProductInventoryArgs = {
  productId: Scalars['ID'];
  input: ProductInventoryInput;
};


export type MutationUpdateTopModifierUsageArgs = {
  input: Maybe<UpdateModifierUsageInput>;
};


export type MutationCreateTemplateMenuArgs = {
  merchantId: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  input: Maybe<CreateCategoryInput>;
};


export type MutationUpdateCategorySortOrdersArgs = {
  inputs: Array<UpdateSortOrderInput>;
};


export type MutationUpdateProductSortOrdersArgs = {
  inputs: Array<UpdateSortOrderInput>;
};


export type MutationUpdateCategoryNameArgs = {
  categoryId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateProductNameArgs = {
  productId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationArchiveCategoryArgs = {
  categoryId: Scalars['ID'];
};


export type MutationUpdateProductCategoriesArgs = {
  inputs: Array<UpdateProductCategoryInput>;
};


export type MutationSaveContractSignatureArgs = {
  contractId: Scalars['ID'];
  organizationId: Scalars['ID'];
};


export type MutationSaveContractArgs = {
  input: ContractInput;
};


export type MutationUpdateMenuSyncConfigActiveArgs = {
  merchantId: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationSyncFreshMenuArgs = {
  merchantId: Scalars['ID'];
  brandId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCreateMerchantFromLocationArgs = {
  organizationId: Scalars['ID'];
  freshLocationId: Maybe<Scalars['String']>;
  brandId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSyncSquareMenuArgs = {
  merchantId: Scalars['ID'];
  baseUrl: Scalars['String'];
  onlyProductsWithCategories: Scalars['Boolean'];
  doNotImportCategories: Scalars['Boolean'];
};


export type MutationGenerateSquareOAuthTokenArgs = {
  code: Scalars['String'];
};


export type MutationSyncSquareLocationsArgs = {
  organizationId: Scalars['ID'];
  inputs: Array<SquareSyncInput>;
};


export type MutationArchiveFulfillmentLocationArgs = {
  fulfillmentLocationId: Scalars['ID'];
};


export type MutationCreateFulfillmentLocationArgs = {
  input: CreateFulfillmentLocationInput;
};


export type MutationGenerateQrCodeDisplayArgs = {
  fulfillmentLocationId: Scalars['ID'];
};


export type MutationUploadMerchantImageArgs = {
  file: Scalars['Upload'];
  merchantId: Scalars['ID'];
  sortOrder: Scalars['Int'];
};


export type MutationUpdateMerchantImagesArgs = {
  input: Maybe<Array<MerchantImageInput>>;
  merchantId: Scalars['ID'];
};


export type MutationDeleteMerchantImageArgs = {
  id: Scalars['ID'];
};


export type MutationUploadImageArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadImageByUrlArgs = {
  url: Scalars['String'];
};


export type MutationRegisterIoTDeviceArgs = {
  input: RegisterIoTDeviceInput;
};


export type MutationProcessIoTEventArgs = {
  input: IoTEventInput;
};


export type MutationSetUpPassArgs = {
  deviceUuid: Scalars['String'];
  orderId: Scalars['ID'];
};


export type MutationRegisterKdsArgs = {
  input: RegisterKdsInput;
};


export type MutationUnlinkKdsArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateKdsVersionInfoArgs = {
  id: Scalars['ID'];
  os: Scalars['String'];
  appVersion: Scalars['String'];
};


export type MutationKdsHeartbeatArgs = {
  id: Scalars['ID'];
};


export type MutationKdsHeartbeatByDeviceUuidArgs = {
  deviceUuid: Scalars['String'];
};


export type MutationCreateKdsEventsArgs = {
  events: Array<KdsEventInput>;
};


export type MutationCreateMerchantKdsIntegrationArgs = {
  input: CreateMerchantKdsIntegrationInput;
};


export type MutationArchiveMerchantKdsIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMerchantKdsIntegrationActiveArgs = {
  id: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationSetupKdsIntegrationDevicesArgs = {
  integrationId: Scalars['ID'];
};


export type MutationTrackMyLocationArgs = {
  coordinates: Coordinates;
};


export type MutationSaveDeviceLocationArgs = {
  deviceId: Scalars['String'];
  coordinates: Coordinates;
};


export type MutationArchiveMerchantArgs = {
  id: Scalars['ID'];
};


export type MutationCreateMerchantArgs = {
  input: MerchantInput;
};


export type MutationUpdateMerchantArgs = {
  input: MerchantInput;
};


export type MutationUpdateMerchantActiveArgs = {
  id: Scalars['ID'];
  active: Scalars['Boolean'];
};


export type MutationArchiveMerchantPaymentAccountArgs = {
  id: Scalars['ID'];
};


export type MutationSetMerchantPaymentAccountArgs = {
  id: Scalars['ID'];
  stripeAccountId: Scalars['String'];
};


export type MutationCreateMarketArgs = {
  input: MarketInput;
};


export type MutationUpdateMarketArgs = {
  input: MarketInput;
};


export type MutationMoveMerchantToDifferentOrganizationArgs = {
  input: MoveMerchantToDifferentOrganizationInput;
};


export type MutationSendStaleOrderRecapArgs = {
  hourToSendEmailPst: Maybe<Scalars['Int']>;
};


export type MutationUpdateMerchantReadableIdArgs = {
  merchantId: Scalars['ID'];
  readableId: Scalars['String'];
};


export type MutationUpdateMerchantBooleanSettingArgs = {
  merchantId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['Boolean'];
};


export type MutationUpdateMerchantSourceSystemArgs = {
  merchantId: Scalars['ID'];
  sourceSystem: ExternalDataSource;
  sourceSystemId: Scalars['String'];
  syncMenu: Scalars['Boolean'];
};


export type MutationCreateMerchantContactArgs = {
  input: CreateMerchantContactInput;
};


export type MutationUpdateMerchantContactArgs = {
  input: UpdateMerchantContactInput;
};


export type MutationDeleteMerchantContactArgs = {
  id: Scalars['ID'];
};


export type MutationArchiveMerchantFeeConfigArgs = {
  merchantFeeConfigId: Scalars['ID'];
};


export type MutationCreateMerchantFeeConfigArgs = {
  input: CreateMerchantFeeConfigInput;
};


export type MutationSetDefaultMerchantFeeConfigArgs = {
  merchantFeeConfigId: Scalars['ID'];
};


export type MutationSetMerchantFeeConfigForMerchantArgs = {
  merchantId: Scalars['ID'];
  merchantFeeConfigId: Scalars['ID'];
};


export type MutationCreateSubscriptionPlanArgs = {
  input: CreateSubscriptionPlanInput;
};


export type MutationCreateSubscriptionFeeArgs = {
  input: CreateSubscriptionFeeInput;
};


export type MutationSetMerchantSubscriptionArgs = {
  input: SetMerchantSubscriptionInput;
};


export type MutationSetMerchantSubscriptionsArgs = {
  inputs: Array<SetMerchantSubscriptionInput>;
};


export type MutationAddMerchantSubscriptionFeeArgs = {
  input: AddMerchantSubscriptionFeeInput;
};


export type MutationCreateStripeSubscriptionArgs = {
  merchantIds: Array<Scalars['ID']>;
  organizationManaged: Scalars['Boolean'];
};


export type MutationRefreshSubscriptionStatusArgs = {
  merchantIds: Array<Scalars['ID']>;
};


export type MutationUpdateSubscriptionPaymentMethodArgs = {
  stripeSubscriptionId: Scalars['ID'];
  paymentMethodId: Scalars['String'];
};


export type MutationUpdateMerchantsOnOrgSubscriptionArgs = {
  merchantIds: Array<Scalars['ID']>;
};


export type MutationCancelStripeSubscriptionArgs = {
  stripeSubscriptionId: Scalars['ID'];
};


export type MutationUpdateTrialSubscriptionsArgs = {
  currentDate: Maybe<Scalars['Instant']>;
};


export type MutationSetDefaultSubscriptionProductArgs = {
  subscriptionProductId: Scalars['ID'];
  defaultSuscriptionType: DefaultSuscriptionType;
};


export type MutationSavePlatformPricingArgs = {
  input: PlatformPricingInput;
};


export type MutationCreatePlatformPricingPaymentIntentArgs = {
  merchantIds: Array<Scalars['ID']>;
};


export type MutationSendSupportEmailArgs = {
  input: SendSupportEmailInput;
};


export type MutationSendPushToMarketArgs = {
  input: MarketPushInput;
};


export type MutationSendPushToUsersArgs = {
  input: UserPushInput;
};


export type MutationProcessLeadsArgs = {
  leads: Array<LeadInput>;
};


export type MutationSyncCustomerUserToCustomerIoArgs = {
  userId: Scalars['ID'];
};


export type MutationSyncPartnerUserToCustomerIoArgs = {
  userId: Scalars['ID'];
};


export type MutationSyncOrganizationToCustomerIoArgs = {
  organizationId: Scalars['ID'];
};


export type MutationValidateOrderArgs = {
  input: OrderInput;
};


export type MutationGenerateOrderPaymentSummaryArgs = {
  input: GenerateOrderPaymentSummaryInput;
};


export type MutationCheckoutOrderArgs = {
  input: CheckoutOrderInput;
};


export type MutationCreateOrderEventsArgs = {
  input: CreateOrderEventsInput;
};


export type MutationAddOrderToQueueArgs = {
  id: Scalars['ID'];
};


export type MutationCheckPendingOrderPaymentIntentsArgs = {
  since: Maybe<Scalars['Instant']>;
};


export type MutationProcessOrderItemEventArgs = {
  input: OrderItemEventInput;
};


export type MutationRefundOrderArgs = {
  id: Scalars['ID'];
};


export type MutationRefundOrderItemArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateOutputDestinationCategoriesArgs = {
  input: Maybe<UpdateOutputDestinationCategoriesInput>;
};


export type MutationInitializeOrderArgs = {
  input: InitializeOrderInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationArchiveOrganizationArgs = {
  id: Scalars['ID'];
};


export type MutationSetupOrganizationArgs = {
  input: SetupOrganizationInput;
};


export type MutationSetOrganizationPaymentAccountArgs = {
  id: Scalars['ID'];
  stripeAccountId: Scalars['String'];
};


export type MutationArchiveOrganizationPaymentAccountArgs = {
  id: Scalars['ID'];
};


export type MutationAddOrganizationUserRoleArgs = {
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
  role: Maybe<Role>;
};


export type MutationRemoveOrganizationUserRoleArgs = {
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
  role: Maybe<Role>;
};


export type MutationUpdateOrganizationReadableIdArgs = {
  organizationId: Scalars['ID'];
  readableId: Scalars['String'];
};


export type MutationUpdateOrganizationBooleanSettingArgs = {
  organizationId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['Boolean'];
};


export type MutationUpdateOrganizationNumericSettingArgs = {
  organizationId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['Int'];
};


export type MutationSaveMyPaymentMethodArgs = {
  input: PaymentMethodInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: PaymentMethodInput;
};


export type MutationSetupPaymentAccountArgs = {
  organizationId: Scalars['ID'];
  merchantId: Maybe<Scalars['ID']>;
};


export type MutationSyncPayoutArgs = {
  stripeAccountId: Scalars['String'];
  payoutId: Scalars['String'];
};


export type MutationGeneratePayoutArgs = {
  merchantId: Scalars['ID'];
  endDate: Scalars['Instant'];
  payoutType: PayoutType;
};


export type MutationPayoutMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type MutationPrepareMerchantPayoutsArgs = {
  endDate: Scalars['Instant'];
};


export type MutationCreatePromotionArgs = {
  input: PromotionInput;
};


export type MutationCreateMerchantPromotionArgs = {
  input: PromotionInput;
};


export type MutationAdjudicatePromotionArgs = {
  id: Scalars['ID'];
  status: PromotionStatus;
  reason: Maybe<Scalars['String']>;
};


export type MutationArchivePromotionArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePromoCodeArgs = {
  input: PromoCodeInput;
};


export type MutationProcessPromoCodeArgs = {
  code: Scalars['String'];
};


export type MutationCheckEnterUserInMarketLaunchPromoArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


export type MutationCheckStaleUserPromotionsArgs = {
  hourOfExecutionPst: Scalars['Int'];
};


export type MutationUpdatePromotionArgs = {
  input: PromotionUpdateInput;
};


export type MutationUpdatePromotionCodeArgs = {
  input: PromotionCodeUpdateInput;
};


export type MutationCreateQuestArgs = {
  input: CreateQuestInput;
};


export type MutationArchiveQuestArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateQuestStatusArgs = {
  questId: Scalars['ID'];
  status: QuestStatus;
};


export type MutationCreateQuestStepEffectModifierArgs = {
  input: CreateQuestStepEffectModifierInput;
};


export type MutationCreateRdyPointsArgs = {
  userId: Scalars['ID'];
  points: Scalars['Int'];
};


export type MutationGrantTokensArgs = {
  input: GrantTokensInput;
};


export type MutationRedeemPointsByOrganizationArgs = {
  organizationId: Maybe<Scalars['ID']>;
};


export type MutationInitializeReferralArgs = {
  input: Maybe<InitializeReferralInput>;
};


export type MutationInitializeRequisitionArgs = {
  input: InitializeRequisitionInput;
};


export type MutationUpdateRequisitionArgs = {
  input: UpdateRequisitionInput;
};


export type MutationPurchaseScheduledRequisitionsArgs = {
  limit: Scalars['Int'];
};


export type MutationChargeScheduleArgs = {
  scheduleId: Scalars['ID'];
};


export type MutationUpdateMyDeviceInfoArgs = {
  input: DeviceInfoInput;
};


export type MutationUpdateMyProfileArgs = {
  input: UpdateMyProfileInput;
};


export type MutationAcceptUserInviteArgs = {
  token: Scalars['String'];
};


export type MutationSendUserInviteArgs = {
  input: SendUserInviteInput;
};


export type MutationUpdateMyFavoriteMerchantArgs = {
  merchantId: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
};


export type MutationProcessDeepLinkClicksArgs = {
  clicks: Array<DeepLinkClickInput>;
};


export type MutationLinkAccountArgs = {
  secondaryAccessToken: Scalars['String'];
};


export type MutationValidateAndCreateUserArgs = {
  overrideDuplicateCheck: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMyFavoriteOrderArgs = {
  orderId: Scalars['ID'];
  isFavorite: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateBooleanSettingArgs = {
  name: Scalars['String'];
  value: Scalars['Boolean'];
};

export type NearbyMerchantsInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  uuid: Maybe<Scalars['String']>;
  createdOn: Scalars['Instant'];
  items: Array<OrderItem>;
  events: Array<OrderEvent>;
  user: User;
  addedToQueueAt: Maybe<Scalars['Instant']>;
  timerTriggerAt: Maybe<Scalars['Instant']>;
  transactions: Maybe<Array<OrderTransaction>>;
  merchant: Merchant;
  state: Maybe<OrderState>;
  diningOption: Maybe<OrderDiningOption>;
  rewards: Maybe<Array<UserRewardAction>>;
  rdyPoints: Maybe<Array<UserRdyPoints>>;
  expectedRewardPoints: Scalars['Int'];
  sourceClient: Maybe<RdyClient>;
  fulfillmentLocation: Maybe<FulfillmentLocation>;
};

export enum OrderDiningOption {
  ForHere = 'FOR_HERE',
  Takeaway = 'TAKEAWAY',
  TableService = 'TABLE_SERVICE'
}

export type OrderEvent = {
  __typename?: 'OrderEvent';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  name: OrderEventName;
};

export enum OrderEventName {
  AddedToFreshKdsQueue = 'ADDED_TO_FRESH_KDS_QUEUE',
  AddedToFreshKdsQueueWhileKdsUnhealthy = 'ADDED_TO_FRESH_KDS_QUEUE_WHILE_KDS_UNHEALTHY',
  AddedToQueue = 'ADDED_TO_QUEUE',
  AddedToQueueWhileKdsUnhealthy = 'ADDED_TO_QUEUE_WHILE_KDS_UNHEALTHY',
  AllItemsSentToPrinter = 'ALL_ITEMS_SENT_TO_PRINTER',
  AutoCompletedAsSquareOrder = 'AUTO_COMPLETED_AS_SQUARE_ORDER',
  AutoStarted = 'AUTO_STARTED',
  ChowlyOrderConfirmed = 'CHOWLY_ORDER_CONFIRMED',
  ChowlyOrderError = 'CHOWLY_ORDER_ERROR',
  DidNotStartPreparingQuickly = 'DID_NOT_START_PREPARING_QUICKLY',
  EnteredKdsLessFlow = 'ENTERED_KDS_LESS_FLOW',
  ItemsCreated = 'ITEMS_CREATED',
  ItemsReadyForTraditionalPass = 'ITEMS_READY_FOR_TRADITIONAL_PASS',
  ItemsPlacedOnRdyPass = 'ITEMS_PLACED_ON_RDY_PASS',
  ItemsSplitBetweenFreshKds = 'ITEMS_SPLIT_BETWEEN_FRESH_KDS',
  ManuallyClearedFromFreshKdsQueue = 'MANUALLY_CLEARED_FROM_FRESH_KDS_QUEUE',
  ManuallyClearedFromKdsQueue = 'MANUALLY_CLEARED_FROM_KDS_QUEUE',
  ManuallyStarted = 'MANUALLY_STARTED',
  MarkedAsPickedUpViaSquare = 'MARKED_AS_PICKED_UP_VIA_SQUARE',
  MarkedAsPreparingViaSquare = 'MARKED_AS_PREPARING_VIA_SQUARE',
  MarkedAsCompleteViaSquare = 'MARKED_AS_COMPLETE_VIA_SQUARE',
  MarkedAsStale = 'MARKED_AS_STALE',
  PassedToCafe = 'PASSED_TO_CAFE',
  PaymentFulfilled = 'PAYMENT_FULFILLED',
  PaymentRejected = 'PAYMENT_REJECTED',
  PickedUp = 'PICKED_UP',
  PickedUpFromRdyPass = 'PICKED_UP_FROM_RDY_PASS',
  Placed = 'PLACED',
  Preparing = 'PREPARING',
  PrintFailed = 'PRINT_FAILED',
  PrintSucceeded = 'PRINT_SUCCEEDED',
  PendingTriggerFromApi = 'PENDING_TRIGGER_FROM_API',
  PendingTriggerFromLocation = 'PENDING_TRIGGER_FROM_LOCATION',
  PendingTriggerFromAsapSelection = 'PENDING_TRIGGER_FROM_ASAP_SELECTION',
  PendingTriggerFromTimerSelection = 'PENDING_TRIGGER_FROM_TIMER_SELECTION',
  SentToSquareLocation = 'SENT_TO_SQUARE_LOCATION',
  ShownInKds = 'SHOWN_IN_KDS',
  ShownInKdsQueue = 'SHOWN_IN_KDS_QUEUE',
  ShownInKdsPending = 'SHOWN_IN_KDS_PENDING',
  SkippedRdyPass = 'SKIPPED_RDY_PASS',
  TimedOutAfterAutoStart = 'TIMED_OUT_AFTER_AUTO_START',
  TokenBalanceRejected = 'TOKEN_BALANCE_REJECTED',
  TriggeredFromApi = 'TRIGGERED_FROM_API',
  TriggeredFromLocation = 'TRIGGERED_FROM_LOCATION',
  TriggeredFromAsapSelection = 'TRIGGERED_FROM_ASAP_SELECTION',
  TriggeredFromTimerSelection = 'TRIGGERED_FROM_TIMER_SELECTION'
}

export type OrderInput = {
  items: Array<OrderItemInput>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  orderableProduct: Product;
  name: Scalars['String'];
  priceTokens: Scalars['Int'];
  priceCents: Scalars['Int'];
  subtotalChargeTokens: Scalars['Int'];
  subtotalChargeUsCents: Scalars['Int'];
  selectedModifiers: Maybe<Array<OrderItemModifier>>;
  state: OrderItemState;
  order: Order;
  events: Maybe<Array<OrderItemEvent>>;
  refunded: Scalars['Boolean'];
  notes: Maybe<Scalars['String']>;
  destinations: Maybe<Array<OutputDestination>>;
};

export type OrderItemEvent = {
  __typename?: 'OrderItemEvent';
  id: Scalars['ID'];
  name: OrderItemEventName;
  ioTPosition: Maybe<IoTPosition>;
};

export type OrderItemEventInput = {
  name: OrderItemEventName;
  orderItemId: Scalars['ID'];
  deviceUuid: Maybe<Scalars['String']>;
  positionCode: Maybe<PositionCode>;
};

export enum OrderItemEventName {
  ManuallyMarkedAsPickedUp = 'MANUALLY_MARKED_AS_PICKED_UP',
  PutativelyMovedToDevice = 'PUTATIVELY_MOVED_TO_DEVICE',
  PutativelyRemovedFromDevice = 'PUTATIVELY_REMOVED_FROM_DEVICE',
  ShouldMoveToDevice = 'SHOULD_MOVE_TO_DEVICE',
  SkippedDeviceError = 'SKIPPED_DEVICE_ERROR',
  SkippedDeviceFlagDisabled = 'SKIPPED_DEVICE_FLAG_DISABLED',
  SkippedDeviceManual = 'SKIPPED_DEVICE_MANUAL',
  SkippedDeviceNotPassable = 'SKIPPED_DEVICE_NOT_PASSABLE',
  SkippedDeviceNotConnected = 'SKIPPED_DEVICE_NOT_CONNECTED',
  SkippedDevicePositionsFull = 'SKIPPED_DEVICE_POSITIONS_FULL',
  SkippedDeviceTooManyItems = 'SKIPPED_DEVICE_TOO_MANY_ITEMS',
  SentToFreshKdsDevice = 'SENT_TO_FRESH_KDS_DEVICE',
  ShownOnFreshKdsDevice = 'SHOWN_ON_FRESH_KDS_DEVICE'
}

export type OrderItemInput = {
  currencyType: CurrencyType;
  productId: Scalars['ID'];
  modifiers: Maybe<Array<OrderItemModifierInput>>;
  notes: Maybe<Scalars['String']>;
};

export type OrderItemModifier = {
  __typename?: 'OrderItemModifier';
  id: Scalars['ID'];
  priceCents: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  originalModifierId: Scalars['ID'];
  originalTopModifierId: Maybe<Scalars['ID']>;
  originalModifier: Modifier;
  originalTopModifier: Maybe<Modifier>;
};

export type OrderItemModifierInput = {
  parentModifierId: Scalars['ID'];
  selectedModifierId: Scalars['ID'];
};

export type OrderItemPosition = {
  __typename?: 'OrderItemPosition';
  orderItemId: Scalars['ID'];
  deviceUuid: Scalars['String'];
  positionCode: PositionCode;
};

export enum OrderItemState {
  Pending = 'PENDING',
  Prepared = 'PREPARED'
}

export type OrderPaymentIntentUserProfile = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type OrderReportingDetails = {
  __typename?: 'OrderReportingDetails';
  id: Scalars['ID'];
  date: Scalars['Instant'];
  userId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  tokens: Scalars['Int'];
  tokenValue: Scalars['Int'];
  tokenTipValue: Scalars['Int'];
  tokenFeeValue: Scalars['Int'];
  subTotal: Scalars['Int'];
  taxes: Scalars['Int'];
  tip: Scalars['Int'];
  customerFee: Scalars['Int'];
  merchantFee: Scalars['Int'];
  netMerchantPayout: Scalars['Int'];
  merchantDiscountFeeUsCents: Scalars['Int'];
  rdyPromotionDiscountValue: Scalars['Int'];
  rdyPromotionName: Scalars['String'];
  rdyPaidTokenCount: Scalars['Int'];
  organizationPaidTokenCount: Scalars['Int'];
};

export type OrderState = {
  __typename?: 'OrderState';
  name: OrderStateName;
  timestamp: Maybe<Scalars['Instant']>;
};

export enum OrderStateName {
  PaymentFulfilled = 'PAYMENT_FULFILLED',
  PaymentRejected = 'PAYMENT_REJECTED',
  Pending = 'PENDING',
  PickedUpFromRdyPass = 'PICKED_UP_FROM_RDY_PASS',
  Preparing = 'PREPARING',
  ReadyForPickupWithRdyPass = 'READY_FOR_PICKUP_WITH_RDY_PASS',
  ReadyForPickupWithTraditionalPass = 'READY_FOR_PICKUP_WITH_TRADITIONAL_PASS',
  Stale = 'STALE'
}

export enum OrderTiming {
  Asap = 'ASAP',
  OnTimer = 'ON_TIMER',
  OnArrival = 'ON_ARRIVAL',
  /** LEGACY */
  UseLocation = 'USE_LOCATION'
}

export type OrderTransaction = {
  __typename?: 'OrderTransaction';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  tokenValue: Scalars['Int'];
  subtotalUsCents: Scalars['Int'];
  feeUsCents: Scalars['Int'];
  taxUsCents: Scalars['Int'];
  tipUsCents: Scalars['Int'];
  tokenTipUsCents: Scalars['Int'];
  discountUsCents: Scalars['Int'];
  merchantFeeUsCents: Scalars['Int'];
  merchantTokenFeeUsCents: Scalars['Int'];
  merchantDiscountFeeUsCents: Scalars['Int'];
  stripePaymentIntentId: Maybe<Scalars['String']>;
  refunds: Maybe<Array<OrderTransactionRefund>>;
  promotions: Maybe<Array<Promotion>>;
  transactionPromotions: Maybe<Array<OrderTransactionPromotion>>;
  stripePaymentUrl: Maybe<Scalars['String']>;
  tokenValueUsCents: Scalars['Int'];
};

export type OrderTransactionPromotion = {
  __typename?: 'OrderTransactionPromotion';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  usCentsDeductedFromSubtotal: Scalars['Int'];
  promotion: Promotion;
};

export type OrderTransactionRefund = {
  __typename?: 'OrderTransactionRefund';
  id: Scalars['ID'];
  initializedOn: Scalars['Instant'];
  tokenValue: Scalars['Int'];
  amountUsCents: Scalars['Int'];
  stripeId: Maybe<Scalars['String']>;
  appFeeRefundUsCents: Scalars['Int'];
  appFeeRefundStripeId: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name: Scalars['String'];
  merchants: Maybe<Array<Merchant>>;
  /** Should be used only to fetch for a single Organization */
  users: Maybe<Array<User>>;
  active: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  stripeCustomerId: Maybe<Scalars['String']>;
  readableId: Maybe<Scalars['String']>;
  contractSignatures: Array<ContractSignature>;
  settings: Array<OrganizationSettingOutput>;
};

export type OrganizationSettingOutput = {
  __typename?: 'OrganizationSettingOutput';
  name: Scalars['String'];
  description: Scalars['String'];
  category: Scalars['String'];
  value: Maybe<Scalars['String']>;
  valueType: SettingValueType;
};

export enum OriginType {
  SingleOrigin = 'SINGLE_ORIGIN',
  Blend = 'BLEND'
}

export type OutputDestination = {
  __typename?: 'OutputDestination';
  id: Scalars['ID'];
  type: OutputDestinationType;
  kdsIntegrationDevice: Maybe<KdsIntegrationDevice>;
  categorySettings: Maybe<Array<CategoryOutputDestination>>;
};

export enum OutputDestinationType {
  FreshKdsDevice = 'FRESH_KDS_DEVICE'
}

export type PassConfig = {
  __typename?: 'PassConfig';
  status: PassStatus;
  message: Scalars['String'];
  skipEvent: OrderItemEventName;
  orderItemPositions: Maybe<Array<OrderItemPosition>>;
};

export enum PassStatus {
  UseRdyPass = 'USE_RDY_PASS',
  RdyPassFull = 'RDY_PASS_FULL',
  TooManyOrderItemsForRdyPass = 'TOO_MANY_ORDER_ITEMS_FOR_RDY_PASS',
  NoRdyPassableItems = 'NO_RDY_PASSABLE_ITEMS'
}

export type PaymentAccountValidation = {
  __typename?: 'PaymentAccountValidation';
  hasAccount: Scalars['Boolean'];
  chargesEnabled: Scalars['Boolean'];
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  paymentSummary: PaymentSummary;
  paymentIntentId: Scalars['String'];
  stripeClientSecret: Scalars['String'];
  stripeCustomerId: Scalars['String'];
  status: Scalars['String'];
};

export enum PaymentIntentStatus {
  Success = 'SUCCESS',
  Fail = 'FAIL',
  RequiresPayment = 'REQUIRES_PAYMENT',
  Pending = 'PENDING'
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['String'];
  card: Maybe<Card>;
};

export type PaymentMethodInput = {
  id: Scalars['String'];
};

export type PaymentSummary = {
  __typename?: 'PaymentSummary';
  bloomTokens: Scalars['Int'];
  usCents: CurrencySummary;
  promotionsUsedForDiscount: Maybe<Array<DiscountPromotionData>>;
};

export enum PayoutType {
  MerchantReferrals = 'MERCHANT_REFERRALS',
  Tokens = 'TOKENS',
  Orders = 'ORDERS',
  Discounts = 'DISCOUNTS'
}

export type PlatformPricing = {
  __typename?: 'PlatformPricing';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdOn: Scalars['Instant'];
  instantPriceUsCents: Scalars['Int'];
  platformPricingType: PlatformPricingType;
  subscriptionTerm: Maybe<Scalars['Int']>;
  subscriptionProduct: Maybe<SubscriptionProduct>;
};

export type PlatformPricingInput = {
  name: Scalars['String'];
  instantPriceUsCents: Scalars['Int'];
  platformPricingType: PlatformPricingType;
  subscriptionTerm: Maybe<Scalars['Int']>;
  subscriptionMonthlyFeeUsCents: Maybe<Scalars['Int']>;
};

export enum PlatformPricingType {
  Instant = 'INSTANT',
  Standard = 'STANDARD',
  Signature = 'SIGNATURE'
}

export enum PositionCode {
  RdyPass_0 = 'RDY_PASS_0',
  RdyPass_1 = 'RDY_PASS_1',
  RdyPass_2 = 'RDY_PASS_2',
  RdyPass_3 = 'RDY_PASS_3'
}

export type PositionInput = {
  deviceUuid: Scalars['String'];
  positionCode: PositionCode;
};

export type PositionStatus = {
  __typename?: 'PositionStatus';
  deviceUuid: Scalars['String'];
  positionCode: PositionCode;
  orderItem: Maybe<OrderItem>;
};

export type PriceTagInput = {
  itemIds: Array<Scalars['ID']>;
  shippingAddress: Maybe<AddressInput>;
  code: Maybe<Scalars['String']>;
};

export type ProcessPromoCodeResponse = {
  __typename?: 'ProcessPromoCodeResponse';
  promoTitle: Scalars['String'];
  benefactor: Scalars['String'];
  numTokensReceived: Scalars['Int'];
  potentialPercentageDiscount: Scalars['Int'];
  potentialFlatDiscount: Scalars['Int'];
  expiresOn: Maybe<Scalars['Instant']>;
  maxDiscount: Maybe<Scalars['Int']>;
  merchantName: Maybe<Scalars['String']>;
  productTags: Maybe<Array<Scalars['String']>>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  category: Category;
  active: Scalars['Boolean'];
  priceCents: Scalars['Int'];
  sizeInGrams: Maybe<Scalars['Int']>;
  originType: Maybe<OriginType>;
  priceTokens: Scalars['Int'];
  roaster: Maybe<Scalars['String']>;
  roastType: Maybe<RoastType>;
  modifiers: Maybe<Array<Modifier>>;
  allModifiers: Maybe<Array<Modifier>>;
  merchant: Merchant;
  archived: Scalars['Boolean'];
  tags: Maybe<Array<Scalars['String']>>;
  /** deprecated used in capp <= 1.9.x */
  productTags: Maybe<Array<ProductTag>>;
  images: Maybe<Array<ProductImage>>;
  inventory: Maybe<Inventory>;
  availability: Maybe<InventoryAvailability>;
  availabilityWindows: Maybe<Array<AvailabilityWindow>>;
  suspendedUntil: Maybe<Scalars['Instant']>;
  taxRate: Maybe<Scalars['Float']>;
  pickUpPriceUsCents: Maybe<Scalars['Int']>;
  isSynced: Scalars['Boolean'];
  catalogs: Maybe<Array<Catalog>>;
  sortOrder: Maybe<Scalars['Int']>;
  source: Maybe<ExternalDataSource>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  id: Scalars['ID'];
  imageUri: Scalars['String'];
  createdOn: Scalars['Instant'];
  sortOrder: Scalars['Int'];
  libraryImage: Maybe<LibraryImage>;
};

export type ProductImageInput = {
  imageUri: Scalars['String'];
  sortOrder: Scalars['Int'];
  sourceImageUri: Maybe<Scalars['String']>;
  libraryImageId: Maybe<Scalars['ID']>;
};

export type ProductInventoryInput = {
  inventoryType: InventoryType;
  restockQuantity: Maybe<Scalars['Int']>;
  currentQuantity: Maybe<Scalars['Int']>;
  currentlyAvailable: Scalars['Boolean'];
  restockDays: Maybe<Array<Scalars['Int']>>;
};

export type ProductTag = {
  __typename?: 'ProductTag';
  value: Scalars['String'];
  tagType: ProductTagType;
};

export type ProductTagInput = {
  value: Scalars['String'];
  tagType: ProductTagType;
};

export enum ProductTagType {
  Coffee = 'COFFEE',
  Tea = 'TEA',
  BeanOrigin = 'BEAN_ORIGIN',
  FlavorNotes = 'FLAVOR_NOTES'
}

export type PromoCode = {
  __typename?: 'PromoCode';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  archived: Scalars['Boolean'];
  code: Scalars['String'];
  promotion: Promotion;
  validStart: Scalars['Instant'];
  validEnd: Maybe<Scalars['Instant']>;
  limitTotalUses: Maybe<Scalars['Int']>;
  deepLink: Maybe<Scalars['String']>;
};

export type PromoCodeInput = {
  promotionId: Scalars['ID'];
  validStart: Scalars['Instant'];
  validEnd: Maybe<Scalars['Instant']>;
  limitTotalUses: Maybe<Scalars['Int']>;
  code: Maybe<Scalars['String']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  customerFacingName: Scalars['String'];
  costOwner: PromotionCostOwner;
  recurring: Scalars['Boolean'];
  start: Scalars['Instant'];
  end: Maybe<Scalars['Instant']>;
  perUserPeriodSeconds: Maybe<Scalars['Int']>;
  frequency: Maybe<Frequency>;
  tokenType: Maybe<TokenType>;
  numberOfTokens: Scalars['Int'];
  dispenseRewardOnEntry: Scalars['Boolean'];
  archived: Scalars['Boolean'];
  newUserOnly: Scalars['Boolean'];
  codes: Maybe<Array<PromoCode>>;
  type: PromotionType;
  limitPerUser: Maybe<Scalars['Int']>;
  flatDiscountUsCents: Maybe<Scalars['Int']>;
  percentageDiscount: Maybe<Scalars['Int']>;
  status: PromotionStatus;
  merchants: Maybe<Array<Merchant>>;
  maxDiscountUsCents: Maybe<Scalars['Int']>;
  productTags: Maybe<Array<Scalars['String']>>;
};

export type PromotionCodeUpdateInput = {
  promotionCodeId: Scalars['ID'];
  validStart: Scalars['Instant'];
  validEnd: Maybe<Scalars['Instant']>;
};

export enum PromotionCostOwner {
  Rdy = 'RDY',
  Merchant = 'MERCHANT'
}

export type PromotionInput = {
  type: PromotionType;
  customerFacingName: Scalars['String'];
  costOwner: PromotionCostOwner;
  recurring: Scalars['Boolean'];
  start: Scalars['Instant'];
  end: Maybe<Scalars['Instant']>;
  perUserPeriodSeconds: Maybe<Scalars['Int']>;
  frequency: Maybe<Frequency>;
  tokenType: Maybe<TokenTypeName>;
  numberOfTokens: Maybe<Scalars['Int']>;
  newUserOnly: Maybe<Scalars['Boolean']>;
  dispenseRewardOnEntry: Maybe<Scalars['Boolean']>;
  limitPerUser: Maybe<Scalars['Int']>;
  flatDiscountUsCents: Maybe<Scalars['Int']>;
  percentageDiscount: Maybe<Scalars['Int']>;
  merchantIds: Maybe<Array<Maybe<Scalars['ID']>>>;
  maxDiscountUsCents: Maybe<Scalars['Int']>;
  promoCode: Maybe<Scalars['String']>;
  notifyUsersOn: Maybe<Scalars['Instant']>;
  productTags: Maybe<Array<Scalars['String']>>;
};

export enum PromotionStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export enum PromotionType {
  Discount = 'DISCOUNT',
  Token = 'TOKEN'
}

export type PromotionUpdateInput = {
  promotionId: Scalars['ID'];
  start: Scalars['Instant'];
  end: Maybe<Scalars['Instant']>;
  updateDatesOnAllCodes: Maybe<Scalars['Boolean']>;
};

export enum PushAppAction {
  Vibrate = 'VIBRATE'
}

export type PushContentInput = {
  title: Scalars['String'];
  body: Scalars['String'];
  analyticsTag: Maybe<Scalars['String']>;
  appAction: Maybe<PushAppAction>;
};

export type Query = {
  __typename?: 'Query';
  featureFlag: Maybe<FeatureFlag>;
  appVersion: Maybe<AppVersion>;
  cart: Maybe<Cart>;
  myCart: Maybe<Cart>;
  catalog: Maybe<Catalog>;
  catalogs: Array<Catalog>;
  categories: Array<Category>;
  product: Maybe<Product>;
  products: Array<Product>;
  modifier: Modifier;
  modifiers: Array<Modifier>;
  topModifiersByMerchant: Maybe<Array<Modifier>>;
  modifiersWithoutChildrenByMerchant: Maybe<Array<Modifier>>;
  suggestedProducts: Maybe<Array<SuggestedProduct>>;
  contractByContractType: Array<Contract>;
  getFreshLocations: Array<FreshLocation>;
  getExternalClientSyncs: Array<ExternalClientSync>;
  getExternalClientSyncsByMerchantId: Array<ExternalClientSync>;
  squareLocations: Array<SquareLocation>;
  hasValidSquareOAuthToken: Scalars['Boolean'];
  getMenuSyncConfig: Maybe<MenuSyncConfig>;
  getServingLocationsByMerchantId: Array<FulfillmentLocation>;
  merchantImagesByMerchant: Array<MerchantImage>;
  productImagesByMerchant: Array<ProductImage>;
  libraryImages: Array<LibraryImage>;
  positionStatuses: Array<PositionStatus>;
  kds: Maybe<Kds>;
  kdsById: Kds;
  kdsIntegration: Maybe<MerchantKdsIntegration>;
  merchant: Maybe<Merchant>;
  merchantByReadableId: Maybe<Merchant>;
  merchants: Array<Merchant>;
  merchantsById: Array<Merchant>;
  merchantsByOrganizationId: Array<Merchant>;
  merchantsByReadableOrganizationId: Array<Merchant>;
  nearbyMerchants: Array<Merchant>;
  myFavoriteMerchants: Maybe<Array<Merchant>>;
  myPreviousMerchants: Maybe<Array<Merchant>>;
  markets: Maybe<Array<Market>>;
  suggestedMerchants: Array<Merchant>;
  newMerchants: Array<Merchant>;
  merchantReferralsByMerchantId: Array<MerchantReferral>;
  merchantContact: Maybe<MerchantContact>;
  merchantFeeConfigs: Array<MerchantFeeConfig>;
  stripeSubscription: StripeSubscription;
  merchantSubscription: MerchantSubscription;
  merchantSubscriptionByMerchantId: Maybe<MerchantSubscription>;
  merchantSubscriptionsByOrganizationId: Array<MerchantSubscription>;
  subscriptionPlans: Array<SubscriptionProduct>;
  subscriptionFees: Array<SubscriptionProduct>;
  merchantSubscriptionFeesByMerchantId: Array<MerchantSubscriptionFee>;
  merchantSubscriptionFeesByOrganizationId: Array<MerchantSubscriptionFee>;
  organizationInvoices: Array<SubscriptionInvoice>;
  merchantInvoices: Array<SubscriptionInvoice>;
  defaultSubscriptionProducts: Array<DefaultSubscriptionProduct>;
  getPlatformPricing: Array<PlatformPricing>;
  orderTriggerRadius: Scalars['Int'];
  myOrders: Maybe<Array<Order>>;
  orders: Maybe<Array<Order>>;
  myOrderHistory: Maybe<Array<Order>>;
  myFavoriteOrders: Maybe<Array<Order>>;
  orderEvents: Array<OrderEvent>;
  order: Order;
  orderByUuid: Order;
  ordersByMerchantId: Array<Order>;
  orderReportingDetailsByMerchantId: Array<OrderReportingDetails>;
  ordersByDateRange: Array<Order>;
  organization: Maybe<Organization>;
  organizations: Array<Organization>;
  organizationPayouts: Array<MerchantPayout>;
  myPaymentMethods: Maybe<Array<PaymentMethod>>;
  validatePaymentAccount: Maybe<PaymentAccountValidation>;
  getAccountsNeedingSyncing: Array<Scalars['String']>;
  getPayoutsNeedingSyncing: Array<Scalars['String']>;
  merchantsRequiringPayout: Array<Merchant>;
  merchantPayouts: Array<MerchantPayout>;
  getMerchantsWithUnpaidPayouts: Array<Merchant>;
  promotions: Maybe<Array<Promotion>>;
  promotion: Promotion;
  promotionsByMerchant: Maybe<Array<Promotion>>;
  quests: Array<Quest>;
  quest: Quest;
  questByCodename: Quest;
  tokenTypes: Array<TokenType>;
  activeQuests: Array<ActiveQuest>;
  myRewardDetails: Array<RewardData>;
  myRewardData: RewardData;
  pendingReferral: Maybe<Referral>;
  myCompletedReferrals: Array<Referral>;
  myReferralCode: Scalars['String'];
  beans: Array<Item>;
  priceTag: RequisitionPriceTag;
  /** Current logged User */
  me: Maybe<User>;
  userInvite: UserInvite;
  userGroups: Maybe<Array<UserGroup>>;
  myUnusedDiscountPromotions: Maybe<Array<Promotion>>;
};


export type QueryFeatureFlagArgs = {
  merchantId: Scalars['ID'];
  featureFlagName: FeatureFlagName;
};


export type QueryAppVersionArgs = {
  appType: AppType;
};


export type QueryCartArgs = {
  userId: Scalars['ID'];
};


export type QueryCatalogArgs = {
  id: Scalars['ID'];
};


export type QueryCatalogsArgs = {
  merchantIds: Array<Scalars['ID']>;
};


export type QueryCategoriesArgs = {
  merchantId: Scalars['ID'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProductsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryModifierArgs = {
  id: Scalars['ID'];
};


export type QueryModifiersArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryTopModifiersByMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type QueryModifiersWithoutChildrenByMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type QuerySuggestedProductsArgs = {
  coordinates: Maybe<Coordinates>;
};


export type QueryContractByContractTypeArgs = {
  contractType: ContractType;
};


export type QueryGetFreshLocationsArgs = {
  organizationId: Scalars['ID'];
  brandId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryGetExternalClientSyncsArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetExternalClientSyncsByMerchantIdArgs = {
  merchantId: Scalars['ID'];
};


export type QuerySquareLocationsArgs = {
  organizationId: Scalars['ID'];
  filter: SquareLocationQueryFilter;
};


export type QueryHasValidSquareOAuthTokenArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetMenuSyncConfigArgs = {
  merchantId: Scalars['ID'];
};


export type QueryGetServingLocationsByMerchantIdArgs = {
  merchantId: Scalars['ID'];
};


export type QueryMerchantImagesByMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type QueryProductImagesByMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type QueryPositionStatusesArgs = {
  input: Array<PositionInput>;
};


export type QueryKdsArgs = {
  uuid: Scalars['String'];
};


export type QueryKdsByIdArgs = {
  id: Scalars['ID'];
};


export type QueryKdsIntegrationArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantByReadableIdArgs = {
  readableId: Scalars['String'];
};


export type QueryMerchantsArgs = {
  active: Maybe<Scalars['Boolean']>;
};


export type QueryMerchantsByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryMerchantsByOrganizationIdArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantsByReadableOrganizationIdArgs = {
  readableOrganizationId: Scalars['ID'];
};


export type QueryNearbyMerchantsArgs = {
  input: NearbyMerchantsInput;
};


export type QueryMarketsArgs = {
  marketType: Maybe<MarketType>;
};


export type QuerySuggestedMerchantsArgs = {
  coordinates: Maybe<Coordinates>;
};


export type QueryNewMerchantsArgs = {
  coordinates: Maybe<Coordinates>;
};


export type QueryMerchantReferralsByMerchantIdArgs = {
  id: Scalars['ID'];
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
};


export type QueryMerchantContactArgs = {
  id: Scalars['ID'];
};


export type QueryStripeSubscriptionArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantSubscriptionArgs = {
  id: Scalars['ID'];
};


export type QueryMerchantSubscriptionByMerchantIdArgs = {
  merchantId: Scalars['ID'];
};


export type QueryMerchantSubscriptionsByOrganizationIdArgs = {
  organizationId: Scalars['ID'];
};


export type QueryMerchantSubscriptionFeesByMerchantIdArgs = {
  merchantId: Scalars['ID'];
};


export type QueryMerchantSubscriptionFeesByOrganizationIdArgs = {
  organizationId: Scalars['ID'];
};


export type QueryOrganizationInvoicesArgs = {
  organizationId: Maybe<Scalars['ID']>;
};


export type QueryMerchantInvoicesArgs = {
  merchantId: Maybe<Scalars['ID']>;
};


export type QueryOrderTriggerRadiusArgs = {
  merchantId: Scalars['ID'];
};


export type QueryOrdersArgs = {
  after: Scalars['Instant'];
};


export type QueryMyOrderHistoryArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryOrderEventsArgs = {
  orderId: Scalars['ID'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderByUuidArgs = {
  uuid: Scalars['String'];
};


export type QueryOrdersByMerchantIdArgs = {
  id: Scalars['ID'];
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
};


export type QueryOrderReportingDetailsByMerchantIdArgs = {
  id: Scalars['ID'];
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
};


export type QueryOrdersByDateRangeArgs = {
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationPayoutsArgs = {
  id: Scalars['ID'];
};


export type QueryValidatePaymentAccountArgs = {
  organizationId: Scalars['ID'];
  merchantId: Maybe<Scalars['ID']>;
};


export type QueryGetAccountsNeedingSyncingArgs = {
  seedTransactionLimit: Scalars['Int'];
};


export type QueryGetPayoutsNeedingSyncingArgs = {
  stripeAccountId: Scalars['String'];
};


export type QueryMerchantsRequiringPayoutArgs = {
  endDate: Scalars['Instant'];
  payoutType: PayoutType;
};


export type QueryMerchantPayoutsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetMerchantsWithUnpaidPayoutsArgs = {
  endDate: Scalars['Instant'];
};


export type QueryPromotionArgs = {
  id: Scalars['ID'];
};


export type QueryPromotionsByMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type QueryQuestArgs = {
  id: Scalars['ID'];
};


export type QueryQuestByCodenameArgs = {
  codename: Scalars['String'];
};


export type QueryActiveQuestsArgs = {
  timezone: Scalars['String'];
};


export type QueryPendingReferralArgs = {
  deviceId: Scalars['String'];
};


export type QueryPriceTagArgs = {
  input: PriceTagInput;
};


export type QueryUserInviteArgs = {
  token: Maybe<Scalars['String']>;
};

export type Quest = {
  __typename?: 'Quest';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  name: Scalars['String'];
  codename: Scalars['String'];
  status: QuestStatus;
  description: Scalars['String'];
  start: DateTimeInfo;
  end: DateTimeInfo;
  steps: Maybe<Array<QuestStep>>;
};

export enum QuestStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type QuestStep = {
  __typename?: 'QuestStep';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  name: Scalars['String'];
  action: Maybe<Action>;
  effects: Maybe<Array<QuestStepEffect>>;
  rules: Maybe<QuestStepRules>;
  sortOrder: Maybe<Scalars['Int']>;
  completeAfterIterations: Maybe<Scalars['Int']>;
  parentStepId: Maybe<Scalars['ID']>;
};

export type QuestStepEffect = {
  __typename?: 'QuestStepEffect';
  id: Scalars['ID'];
  rdyPoints: Maybe<RdyPointsData>;
  modifiers: Maybe<Array<QuestStepEffectModifier>>;
};

export type QuestStepEffectModifications = {
  __typename?: 'QuestStepEffectModifications';
  rdyPointsMultiple: Maybe<Scalars['Float']>;
  rdyPointsAddition: Maybe<Scalars['Int']>;
};

export type QuestStepEffectModificationsInput = {
  rdyPointsMultiple: Maybe<Scalars['Float']>;
  rdyPointsAddition: Maybe<Scalars['Int']>;
};

export type QuestStepEffectModifier = {
  __typename?: 'QuestStepEffectModifier';
  id: Scalars['ID'];
  modifications: QuestStepEffectModifications;
};

export type QuestStepRules = {
  __typename?: 'QuestStepRules';
  uniqueBy: Maybe<UniqueByRule>;
  start: Maybe<DateTimeInfo>;
  end: Maybe<DateTimeInfo>;
  organizationIds: Maybe<Array<Maybe<Scalars['ID']>>>;
  excludeOrganizationIds: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type QuestStepRulesInput = {
  uniqueBy: Maybe<UniqueByRuleInput>;
  start: Maybe<DateTimeInfoInput>;
  end: Maybe<DateTimeInfoInput>;
  organizationIds: Maybe<Array<Maybe<Scalars['ID']>>>;
  excludeOrganizationIds: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum RdyClient {
  Cappuccino = 'CAPPUCCINO',
  Cupping = 'CUPPING',
  Doppio = 'DOPPIO',
  Latte = 'LATTE',
  Macchiato = 'MACCHIATO',
  Mocha = 'MOCHA'
}

export type RdyPointsData = {
  __typename?: 'RdyPointsData';
  flat: Maybe<Scalars['Int']>;
  perDollar: Maybe<Scalars['Int']>;
};

export type RdyPointsInput = {
  flat: Maybe<Scalars['Int']>;
  perDollar: Maybe<Scalars['Int']>;
};

export enum RecurringInterval {
  Month = 'MONTH',
  Year = 'YEAR'
}

export type Referral = {
  __typename?: 'Referral';
  id: Scalars['ID'];
  initializedOn: Scalars['Instant'];
  completedOn: Maybe<Scalars['Instant']>;
  state: ReferralState;
  referredUserFirstName: Maybe<Scalars['String']>;
  referringUserFirstName: Maybe<Scalars['String']>;
};

export enum ReferralState {
  Completed = 'COMPLETED',
  Initialized = 'INITIALIZED'
}

export type RegisterIoTDeviceInput = {
  uuid: Scalars['String'];
  type: IoTDeviceType;
  merchantId: Scalars['ID'];
};

export type RegisterKdsInput = {
  uuid: Scalars['String'];
  merchantId: Scalars['ID'];
};

export type Requisition = {
  __typename?: 'Requisition';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  createdOn: Scalars['Instant'];
  items: Maybe<Array<RequisitionItem>>;
  purchases: Maybe<Array<RequisitionPurchase>>;
  fulfillmentLocation: Maybe<RequisitionFulfillmentLocation>;
  schedule: Maybe<RequisitionSchedule>;
};

export type RequisitionFulfillmentInput = {
  paymentMethodId: Scalars['String'];
  shippingAddress: Maybe<AddressInput>;
  schedule: Maybe<RequisitionScheduleInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type RequisitionFulfillmentLocation = {
  __typename?: 'RequisitionFulfillmentLocation';
  id: Scalars['ID'];
  street: Scalars['String'];
  street2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  countryCode: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type RequisitionItem = {
  __typename?: 'RequisitionItem';
  id: Scalars['ID'];
  item: Item;
  cents: Scalars['Int'];
};

export type RequisitionPriceTag = {
  __typename?: 'RequisitionPriceTag';
  subtotal: Maybe<Scalars['Int']>;
  tax: Maybe<Scalars['Int']>;
  discount: Maybe<RequisitionPriceTagDiscount>;
};

export type RequisitionPriceTagDiscount = {
  __typename?: 'RequisitionPriceTagDiscount';
  total: Scalars['Int'];
  discountId: Scalars['ID'];
  name: Scalars['String'];
};

export type RequisitionPurchase = {
  __typename?: 'RequisitionPurchase';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  subtotal: Scalars['Int'];
  tax: Scalars['Int'];
  discount: Scalars['Int'];
  paymentIntent: Maybe<RequisitionPurchasePaymentIntent>;
};

export type RequisitionPurchasePaymentIntent = {
  __typename?: 'RequisitionPurchasePaymentIntent';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  stripeId: Scalars['String'];
  stripeClientSecret: Scalars['String'];
  status: PaymentIntentStatus;
};

export type RequisitionSchedule = {
  __typename?: 'RequisitionSchedule';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  paymentMethodId: Scalars['String'];
  paused: Scalars['Boolean'];
  canceled: Scalars['Boolean'];
  frequency: RequisitionScheduleFrequency;
  nextScheduledPayment: Scalars['String'];
  status: RequisitionScheduleStatus;
};

export enum RequisitionScheduleFrequency {
  EndOfMonth = 'EndOfMonth'
}

export type RequisitionScheduleInput = {
  frequency: RequisitionScheduleFrequency;
  paused: Maybe<Scalars['Boolean']>;
  archived: Maybe<Scalars['Boolean']>;
};

export enum RequisitionScheduleStatus {
  PaymentFailed = 'PaymentFailed',
  Canceled = 'Canceled',
  Active = 'Active'
}

export enum RewardAction {
  Redeem = 'REDEEM',
  PurchaseOrder = 'PURCHASE_ORDER',
  AdminGrant = 'ADMIN_GRANT',
  RedactPurchaseOrder = 'REDACT_PURCHASE_ORDER'
}

export type RewardData = {
  __typename?: 'RewardData';
  pointBalance: Scalars['Int'];
  tokens: Array<Token>;
  potentialTokens: Scalars['Int'];
  pointsPerToken: Scalars['Int'];
  organization: Maybe<Organization>;
};

export enum RoastType {
  Light = 'LIGHT',
  Medium = 'MEDIUM',
  Dark = 'DARK'
}

export enum Role {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  TreasuryContact = 'TREASURY_CONTACT'
}

export type SendSupportEmailInput = {
  contactEmail: Scalars['String'];
  reason: Scalars['String'];
  details: Scalars['String'];
};

export type SendUserInviteInput = {
  organizationId: Scalars['ID'];
  email: Scalars['String'];
  /** frontend url to have the token concatenated at the end */
  acceptUrl: Scalars['String'];
  roles: Array<Role>;
};

export type SetMerchantSubscriptionInput = {
  platformPricingId: Maybe<Scalars['ID']>;
  subscriptionProductId: Maybe<Scalars['ID']>;
  merchantId: Scalars['ID'];
  trialEndDate: Maybe<Scalars['Instant']>;
  termPricing: Maybe<Scalars['Boolean']>;
};

export enum SettingValueType {
  Boolean = 'BOOLEAN',
  String = 'STRING',
  Numeric = 'NUMERIC'
}

export type SetupOrganizationInput = {
  name: Scalars['String'];
  merchant: Maybe<MerchantInput>;
};

export type SquareAddress = {
  __typename?: 'SquareAddress';
  line1: Maybe<Scalars['String']>;
  line2: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
};

export type SquareLocation = {
  __typename?: 'SquareLocation';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  address: Maybe<SquareAddress>;
  phoneNumber: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  description: Maybe<Scalars['String']>;
  merchantHasSquareSite: Scalars['Boolean'];
};

export type SquareLocationQueryFilter = {
  availableForMerchantId: Maybe<Scalars['ID']>;
};

export type SquareSyncInput = {
  identifier: Scalars['String'];
  locationId: Scalars['String'];
  createKdsIntegration: Scalars['Boolean'];
  createMerchant: Scalars['Boolean'];
  syncProducts: Scalars['Boolean'];
  onlyWebCatalogItems: Scalars['Boolean'];
  merchantId: Maybe<Scalars['ID']>;
  keepMenuSynced: Scalars['Boolean'];
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  id: Scalars['ID'];
  stripeSubscriptionId: Scalars['String'];
  stripePaymentIntentId: Scalars['String'];
  stripeCustomerId: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
  subscriptionStatus: Maybe<SubscriptionStatus>;
};

export type Subscription = {
  __typename?: 'Subscription';
  orderAddedToKdsQueue: Order;
  orderInQueue: Order;
};


export type SubscriptionOrderAddedToKdsQueueArgs = {
  kdsUuid: Scalars['String'];
};


export type SubscriptionOrderInQueueArgs = {
  kdsId: Scalars['ID'];
};

export type SubscriptionInvoice = {
  __typename?: 'SubscriptionInvoice';
  subscriptionInvoiceLines: Array<SubscriptionInvoiceLine>;
  status: Scalars['String'];
  costUsCents: Scalars['Int'];
  createdOn: Scalars['Instant'];
  finalizedOn: Maybe<Scalars['Instant']>;
  paidOn: Maybe<Scalars['Instant']>;
};

export type SubscriptionInvoiceLine = {
  __typename?: 'SubscriptionInvoiceLine';
  costUsCents: Scalars['Int'];
  quantity: Scalars['Int'];
  subscriptionProduct: Maybe<SubscriptionProduct>;
};

export type SubscriptionPaymentIntent = {
  __typename?: 'SubscriptionPaymentIntent';
  merchantSubscriptions: Array<MerchantSubscription>;
  clientSecret: Maybe<Scalars['String']>;
  paymentIntentId: Maybe<Scalars['String']>;
  costUsCents: Maybe<Scalars['Int']>;
  lineItems: Maybe<Array<SubscriptionPaymentIntentLineItem>>;
};

export type SubscriptionPaymentIntentLineItem = {
  __typename?: 'SubscriptionPaymentIntentLineItem';
  costUsCents: Scalars['Int'];
  name: Scalars['String'];
};

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  id: Scalars['ID'];
  costUsCents: Scalars['Int'];
  name: Scalars['String'];
  recurringInterval: Maybe<RecurringInterval>;
  createdOn: Scalars['Instant'];
};

export enum SubscriptionStatus {
  Current = 'CURRENT',
  PaymentFailed = 'PAYMENT_FAILED',
  Cancelled = 'CANCELLED',
  PendingPayment = 'PENDING_PAYMENT',
  PastDue = 'PAST_DUE',
  Complete = 'COMPLETE'
}

export type SuggestedItem = {
  __typename?: 'SuggestedItem';
  id: Scalars['ID'];
  product: Product;
};

export type SuggestedItemInput = {
  productId: Scalars['ID'];
};

export type SuggestedProduct = {
  __typename?: 'SuggestedProduct';
  product: Product;
  suggestedModifierIds: Maybe<Array<Scalars['Int']>>;
};

export type TimeIntervalInput = {
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
};

export type Token = {
  __typename?: 'Token';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  userId: Scalars['ID'];
  expirationTimestamp: Maybe<Scalars['Instant']>;
  spent: Scalars['Boolean'];
  tokenType: TokenType;
};

export type TokenType = {
  __typename?: 'TokenType';
  id: Scalars['ID'];
  name: TokenTypeName;
  customerFacingName: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  expirationMilli: Maybe<Scalars['Instant']>;
};

export enum TokenTypeName {
  AcceptReferral = 'ACCEPT_REFERRAL',
  AdminGrant = 'ADMIN_GRANT',
  Oatly = 'OATLY',
  PointsRedemption = 'POINTS_REDEMPTION',
  PromotionNoExp = 'PROMOTION_NO_EXP',
  RdyNoExp = 'RDY_NO_EXP',
  RdyExpOneWeek = 'RDY_EXP_ONE_WEEK',
  Refer = 'REFER',
  OrganizationReward = 'ORGANIZATION_REWARD'
}

export type TopModifierInput = {
  modifierId: Scalars['ID'];
  sortOrder: Scalars['Int'];
};

export enum UniqueByContext {
  QuestWindow = 'QuestWindow',
  UserLifetime = 'UserLifetime'
}

export type UniqueByRule = {
  __typename?: 'UniqueByRule';
  context: UniqueByContext;
  city: Maybe<Scalars['Boolean']>;
  merchant: Maybe<Scalars['Boolean']>;
  order: Maybe<Scalars['Boolean']>;
  referredDevice: Maybe<Scalars['Boolean']>;
};

export type UniqueByRuleInput = {
  context: UniqueByContext;
  city: Maybe<Scalars['Boolean']>;
  order: Maybe<Scalars['Boolean']>;
  referredDevice: Maybe<Scalars['Boolean']>;
  merchant: Maybe<Scalars['Boolean']>;
};

export type UpdateCatalogInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  availabilityWindows: Array<Maybe<CatalogAvailabilityWindowInput>>;
  productIds: Array<Scalars['ID']>;
};

export type UpdateMerchantContactInput = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type UpdateModifierInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  childSelectionType: Maybe<ModifierChildSelectionType>;
  priceCents: Maybe<Scalars['Int']>;
  selectedName: Maybe<Scalars['String']>;
  defaultSelectionIds: Maybe<Array<Scalars['ID']>>;
  options: Maybe<Array<ModifierChildInput>>;
};

export type UpdateModifierUsageInput = {
  modifierId: Scalars['ID'];
  productIds: Array<Scalars['ID']>;
};

export type UpdateMyProfileInput = {
  contactEmail: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateOutputDestinationCategoriesInput = {
  merchantId: Scalars['ID'];
  categoryOutputDestinations: Array<CategoryOutputDestinationInput>;
};

export type UpdateProductCategoryInput = {
  productId: Scalars['ID'];
  categoryId: Scalars['ID'];
};

export type UpdateProductInput = {
  id: Scalars['ID'];
  categoryId: Scalars['ID'];
  catalogIds: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  printableName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  priceCents: Scalars['Int'];
  sizeInGrams: Maybe<Scalars['Int']>;
  originType: Maybe<OriginType>;
  roaster: Maybe<Scalars['String']>;
  roastType: Maybe<RoastType>;
  topModifiers: Maybe<Array<TopModifierInput>>;
  productTags: Maybe<Array<ProductTagInput>>;
  images: Maybe<Array<ProductImageInput>>;
  inventory: Maybe<ProductInventoryInput>;
  availabilityWindows: Maybe<Array<AvailabilityWindowInput>>;
  suspendedUntil: Maybe<Scalars['Instant']>;
  taxRate: Maybe<Scalars['Float']>;
  pickUpPriceUsCents: Maybe<Scalars['Int']>;
};

export type UpdateRequisitionInput = {
  id: Scalars['ID'];
  fulfillment: RequisitionFulfillmentInput;
};

export type UpdateSortOrderInput = {
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
};

export type UpdateSuggestedItemsInput = {
  catalogId: Scalars['ID'];
  items: Array<SuggestedItemInput>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  roles: Maybe<Array<Scalars['String']>>;
  accessIdentifier: Scalars['String'];
  profile: UserProfile;
  cart: Maybe<Cart>;
  kdss: Maybe<Array<Kds>>;
  orders: Maybe<Array<Order>>;
  orderCount: Scalars['Int'];
  settings: Maybe<Array<UserSettingOutput>>;
  requisitions: Maybe<Array<Requisition>>;
  stripeCustomerId: Maybe<Scalars['String']>;
  numberRdyPoints: Scalars['Int'];
  tokens: Maybe<Array<Token>>;
  actions: Maybe<Array<UserAction>>;
  questSteps: Maybe<Array<UserQuestStep>>;
};

export type UserAction = {
  __typename?: 'UserAction';
  id: Scalars['ID'];
  action: Action;
  data: Maybe<UserActionData>;
};

export type UserActionData = {
  __typename?: 'UserActionData';
  centsSpent: Maybe<Scalars['Int']>;
  referringUserId: Maybe<Scalars['ID']>;
  referredUserId: Maybe<Scalars['ID']>;
  referredDeviceId: Maybe<Scalars['String']>;
  merchantId: Maybe<Scalars['ID']>;
  merchantName: Maybe<Scalars['String']>;
  orderId: Maybe<Scalars['String']>;
  favoriteMerchantId: Maybe<Scalars['ID']>;
};

export enum UserGroup {
  All = 'ALL'
}

export type UserInvite = {
  __typename?: 'UserInvite';
  organization: Organization;
  invitedBy: User;
  roles: Array<Role>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  id: Scalars['ID'];
  contactEmail: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  loginEmail: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
};

export type UserPushInput = {
  messageType: MessageType;
  userIds: Maybe<Array<Scalars['ID']>>;
  content: PushContentInput;
};

export type UserQuestStep = {
  __typename?: 'UserQuestStep';
  id: Scalars['ID'];
  completedOn: Scalars['Instant'];
  questStep: QuestStep;
  userAction: Maybe<UserAction>;
  effects: Maybe<Array<UserQuestStepEffect>>;
};

export type UserQuestStepEffect = {
  __typename?: 'UserQuestStepEffect';
  id: Scalars['ID'];
  receivedOn: Scalars['Instant'];
  effect: QuestStepEffect;
  rdyPoints: Maybe<UserRdyPoints>;
};

export type UserRdyPoints = {
  __typename?: 'UserRdyPoints';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  user: User;
  points: Scalars['Int'];
};

export type UserRewardAction = {
  __typename?: 'UserRewardAction';
  id: Scalars['ID'];
  createdOn: Scalars['Instant'];
  points: Scalars['Int'];
  description: Scalars['String'];
  action: RewardAction;
  organizationId: Maybe<Scalars['ID']>;
};

export type UserSettingOutput = {
  __typename?: 'UserSettingOutput';
  id: Scalars['ID'];
  name: Scalars['String'];
  readableName: Scalars['String'];
  category: Scalars['String'];
  value: Maybe<Scalars['String']>;
  valueType: SettingValueType;
};

export type MoveMerchantToDifferentOrganizationInput = {
  merchantId: Scalars['ID'];
  toOrganizationId: Scalars['ID'];
};

export type FeatureFlagQueryVariables = Exact<{
  merchantId: Scalars['ID'];
  featureFlagName: FeatureFlagName;
}>;


export type FeatureFlagQuery = (
  { __typename?: 'Query' }
  & { featureFlag: Maybe<(
    { __typename?: 'FeatureFlag' }
    & Pick<FeatureFlag, 'name' | 'enabled'>
  )> }
);

export type UpdateRequisitionMutationVariables = Exact<{
  input: UpdateRequisitionInput;
}>;


export type UpdateRequisitionMutation = (
  { __typename?: 'Mutation' }
  & { updateRequisition: (
    { __typename?: 'Requisition' }
    & Pick<Requisition, 'id'>
    & { schedule: Maybe<(
      { __typename?: 'RequisitionSchedule' }
      & Pick<RequisitionSchedule, 'id' | 'paused'>
    )>, purchases: Maybe<Array<(
      { __typename?: 'RequisitionPurchase' }
      & Pick<RequisitionPurchase, 'id' | 'createdOn' | 'subtotal' | 'tax'>
    )>>, items: Maybe<Array<(
      { __typename?: 'RequisitionItem' }
      & Pick<RequisitionItem, 'id' | 'cents'>
      & { item: (
        { __typename?: 'Item' }
        & Pick<Item, 'id' | 'name'>
      ) }
    )>>, fulfillmentLocation: Maybe<(
      { __typename?: 'RequisitionFulfillmentLocation' }
      & Pick<RequisitionFulfillmentLocation, 'street' | 'street2' | 'city' | 'state' | 'zipcode'>
    )> }
  ) }
);

export type UserRequisitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserRequisitionsQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { requisitions: Maybe<Array<(
      { __typename?: 'Requisition' }
      & Pick<Requisition, 'id' | 'firstName' | 'lastName' | 'createdOn'>
      & { schedule: Maybe<(
        { __typename?: 'RequisitionSchedule' }
        & Pick<RequisitionSchedule, 'id' | 'paymentMethodId' | 'paused' | 'frequency' | 'nextScheduledPayment'>
      )>, purchases: Maybe<Array<(
        { __typename?: 'RequisitionPurchase' }
        & Pick<RequisitionPurchase, 'id' | 'createdOn' | 'subtotal' | 'tax' | 'discount'>
      )>>, items: Maybe<Array<(
        { __typename?: 'RequisitionItem' }
        & Pick<RequisitionItem, 'id' | 'cents'>
        & { item: (
          { __typename?: 'Item' }
          & Pick<Item, 'id' | 'name'>
        ) }
      )>>, fulfillmentLocation: Maybe<(
        { __typename?: 'RequisitionFulfillmentLocation' }
        & Pick<RequisitionFulfillmentLocation, 'id' | 'street' | 'street2' | 'city' | 'state' | 'zipcode'>
      )> }
    )>> }
  )> }
);

export type GenerateOrderPaymentSummaryMutationVariables = Exact<{
  input: GenerateOrderPaymentSummaryInput;
}>;


export type GenerateOrderPaymentSummaryMutation = (
  { __typename?: 'Mutation' }
  & { generateOrderPaymentSummary: (
    { __typename?: 'PaymentSummary' }
    & Pick<PaymentSummary, 'bloomTokens'>
    & { usCents: (
      { __typename?: 'CurrencySummary' }
      & Pick<CurrencySummary, 'subtotal' | 'fee' | 'tax' | 'tip' | 'discount' | 'total'>
    ), promotionsUsedForDiscount: Maybe<Array<(
      { __typename?: 'DiscountPromotionData' }
      & Pick<DiscountPromotionData, 'usCentsDeductedFromSubtotal'>
      & { promotion: (
        { __typename?: 'Promotion' }
        & Pick<Promotion, 'id' | 'customerFacingName'>
      ) }
    )>> }
  ) }
);

export type MerchantForCartQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MerchantForCartQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name' | 'readableId' | 'diningOptions'>
    & { operationHours: Maybe<Array<(
      { __typename?: 'MerchantHours' }
      & Pick<MerchantHours, 'id' | 'dayOfWeek' | 'start' | 'end' | 'durationMinutes'>
    )>>, location: (
      { __typename?: 'MerchantLocation' }
      & Pick<MerchantLocation, 'id' | 'street' | 'city' | 'latitude' | 'longitude' | 'state'>
    ), images: Maybe<Array<(
      { __typename?: 'MerchantImage' }
      & Pick<MerchantImage, 'id' | 'imageUri' | 'sortOrder' | 'createdOn'>
    )>>, servingLocations: Maybe<Array<(
      { __typename?: 'FulfillmentLocation' }
      & Pick<FulfillmentLocation, 'id' | 'displayName' | 'urlSafeName' | 'appUrl'>
    )>>, settings: Array<(
      { __typename?: 'MerchantSettingOutput' }
      & Pick<MerchantSettingOutput, 'name' | 'value' | 'valueType'>
    )> }
  )> }
);

export type UpsellDataQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpsellDataQuery = (
  { __typename?: 'Query' }
  & { merchant: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name'>
    & { activeCatalog: Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'id' | 'name'>
      & { activeCategories: Maybe<Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name' | 'type'>
        & { activeProducts: Maybe<Array<(
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name' | 'priceCents' | 'priceTokens' | 'roaster'>
          & { category: (
            { __typename?: 'Category' }
            & Pick<Category, 'id' | 'type'>
          ), modifiers: Maybe<Array<(
            { __typename?: 'Modifier' }
            & Pick<Modifier, 'id'>
            & { children: Maybe<Array<(
              { __typename?: 'Modifier' }
              & Pick<Modifier, 'id' | 'priceTokens' | 'priceCents'>
            )>> }
          )>>, productTags: Maybe<Array<(
            { __typename?: 'ProductTag' }
            & Pick<ProductTag, 'value' | 'tagType'>
          )>>, images: Maybe<Array<(
            { __typename?: 'ProductImage' }
            & Pick<ProductImage, 'id' | 'imageUri' | 'createdOn' | 'sortOrder'>
          )>> }
        )>> }
      )>> }
    )> }
  )> }
);

export type NotableProductInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NotableProductInfoQuery = (
  { __typename?: 'Query' }
  & { catalog: Maybe<(
    { __typename?: 'Catalog' }
    & Pick<Catalog, 'id'>
    & { topProducts: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
    )>>, trendingProducts: Maybe<Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
    )>> }
  )> }
);

export type InitializeOrderMutationVariables = Exact<{
  input: InitializeOrderInput;
}>;


export type InitializeOrderMutation = (
  { __typename?: 'Mutation' }
  & { initializeOrder: (
    { __typename?: 'InitializeOrderOutput' }
    & { order: (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'uuid'>
      & { transactions: Maybe<Array<(
        { __typename?: 'OrderTransaction' }
        & Pick<OrderTransaction, 'id' | 'subtotalUsCents' | 'taxUsCents' | 'tipUsCents' | 'feeUsCents'>
      )>> }
    ), paymentIntent: Maybe<(
      { __typename?: 'InitializeOrderPaymentIntent' }
      & Pick<InitializeOrderPaymentIntent, 'id' | 'clientSecret'>
    )> }
  ) }
);

export type MerchantCatalogQueryVariables = Exact<{
  readableId: Scalars['String'];
}>;


export type MerchantCatalogQuery = (
  { __typename?: 'Query' }
  & { merchantByReadableId: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
    & { activeCatalog: Maybe<(
      { __typename?: 'Catalog' }
      & Pick<Catalog, 'id' | 'name' | 'description'>
      & { suggestedProducts: Maybe<Array<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'priceCents' | 'sortOrder' | 'roaster'>
        & { category: (
          { __typename?: 'Category' }
          & Pick<Category, 'id' | 'name' | 'type' | 'sortOrder'>
        ), modifiers: Maybe<Array<(
          { __typename?: 'Modifier' }
          & Pick<Modifier, 'id' | 'name' | 'description' | 'childSelectionType' | 'priceCents' | 'priceTokens' | 'active' | 'defaultSelectionIds'>
          & { children: Maybe<Array<(
            { __typename?: 'Modifier' }
            & Pick<Modifier, 'id' | 'name' | 'description' | 'childSelectionType' | 'priceCents' | 'priceTokens' | 'selectedName' | 'active'>
          )>> }
        )>>, images: Maybe<Array<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'id' | 'imageUri' | 'createdOn' | 'sortOrder'>
        )>>, productTags: Maybe<Array<(
          { __typename?: 'ProductTag' }
          & Pick<ProductTag, 'value' | 'tagType'>
        )>> }
      )>>, activeProducts: Maybe<Array<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'priceCents' | 'sortOrder' | 'roaster'>
        & { productTags: Maybe<Array<(
          { __typename?: 'ProductTag' }
          & Pick<ProductTag, 'value' | 'tagType'>
        )>>, images: Maybe<Array<(
          { __typename?: 'ProductImage' }
          & Pick<ProductImage, 'id' | 'imageUri' | 'createdOn' | 'sortOrder'>
        )>>, category: (
          { __typename?: 'Category' }
          & Pick<Category, 'id' | 'type' | 'name' | 'sortOrder'>
        ), modifiers: Maybe<Array<(
          { __typename?: 'Modifier' }
          & Pick<Modifier, 'id' | 'name' | 'description' | 'childSelectionType' | 'priceCents' | 'priceTokens' | 'active' | 'defaultSelectionIds'>
          & { children: Maybe<Array<(
            { __typename?: 'Modifier' }
            & Pick<Modifier, 'id' | 'name' | 'description' | 'childSelectionType' | 'priceCents' | 'priceTokens' | 'selectedName' | 'active'>
          )>> }
        )>> }
      )>> }
    )> }
  )> }
);

export type MerchantOverviewQueryVariables = Exact<{
  readableId: Scalars['String'];
}>;


export type MerchantOverviewQuery = (
  { __typename?: 'Query' }
  & { merchantByReadableId: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'readableId' | 'name' | 'active' | 'archived'>
    & { images: Maybe<Array<(
      { __typename?: 'MerchantImage' }
      & Pick<MerchantImage, 'id' | 'imageUri' | 'sortOrder' | 'createdOn'>
    )>>, operationHours: Maybe<Array<(
      { __typename?: 'MerchantHours' }
      & Pick<MerchantHours, 'id' | 'dayOfWeek' | 'start' | 'end' | 'durationMinutes'>
    )>>, location: (
      { __typename?: 'MerchantLocation' }
      & Pick<MerchantLocation, 'id' | 'latitude' | 'longitude' | 'street' | 'street2' | 'zipCode' | 'city' | 'state' | 'countryCode'>
    ), organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    ), servingLocations: Maybe<Array<(
      { __typename?: 'FulfillmentLocation' }
      & Pick<FulfillmentLocation, 'id' | 'displayName' | 'urlSafeName' | 'appUrl'>
    )>> }
  )> }
);

export type MerchantsByOrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MerchantsByOrganizationQuery = (
  { __typename?: 'Query' }
  & { merchantsByOrganizationId: Array<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name' | 'readableId'>
  )> }
);

export type UserForGuestOrderQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type UserForGuestOrderQuery = (
  { __typename?: 'Query' }
  & { orderByUuid: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { profile: (
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'firstName' | 'lastName' | 'contactEmail' | 'phoneNumber'>
      ) }
    ) }
  ) }
);

export type OrderReceiptQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type OrderReceiptQuery = (
  { __typename?: 'Query' }
  & { orderByUuid: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'uuid' | 'createdOn'>
    & { merchant: (
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'readableId' | 'name'>
      & { settings: Array<(
        { __typename?: 'MerchantSettingOutput' }
        & Pick<MerchantSettingOutput, 'name' | 'value' | 'valueType'>
      )>, operationHours: Maybe<Array<(
        { __typename?: 'MerchantHours' }
        & Pick<MerchantHours, 'id' | 'dayOfWeek' | 'start' | 'end' | 'durationMinutes'>
      )>>, location: (
        { __typename?: 'MerchantLocation' }
        & Pick<MerchantLocation, 'id' | 'street' | 'city' | 'state' | 'latitude' | 'longitude'>
      ) }
    ), events: Array<(
      { __typename?: 'OrderEvent' }
      & Pick<OrderEvent, 'id' | 'createdOn' | 'name'>
    )>, transactions: Maybe<Array<(
      { __typename?: 'OrderTransaction' }
      & Pick<OrderTransaction, 'id' | 'createdOn' | 'tokenValue' | 'subtotalUsCents' | 'feeUsCents' | 'taxUsCents' | 'tipUsCents' | 'discountUsCents'>
      & { refunds: Maybe<Array<(
        { __typename?: 'OrderTransactionRefund' }
        & Pick<OrderTransactionRefund, 'id' | 'initializedOn' | 'tokenValue' | 'amountUsCents' | 'stripeId'>
      )>> }
    )>>, items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'name' | 'notes'>
      & { orderableProduct: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'priceCents' | 'active' | 'roaster'>
        & { modifiers: Maybe<Array<(
          { __typename?: 'Modifier' }
          & Pick<Modifier, 'id' | 'name' | 'childSelectionType'>
          & { children: Maybe<Array<(
            { __typename?: 'Modifier' }
            & Pick<Modifier, 'id' | 'name' | 'selectedName'>
          )>> }
        )>>, category: (
          { __typename?: 'Category' }
          & Pick<Category, 'id' | 'type'>
        ) }
      ), selectedModifiers: Maybe<Array<(
        { __typename?: 'OrderItemModifier' }
        & Pick<OrderItemModifier, 'id' | 'name'>
        & { originalModifier: (
          { __typename?: 'Modifier' }
          & Pick<Modifier, 'id'>
        ) }
      )>> }
    )> }
  ) }
);

export type OrderByUuidQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type OrderByUuidQuery = (
  { __typename?: 'Query' }
  & { orderByUuid: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'uuid' | 'createdOn' | 'expectedRewardPoints'>
    & { merchant: (
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'readableId' | 'name'>
      & { location: (
        { __typename?: 'MerchantLocation' }
        & Pick<MerchantLocation, 'id' | 'street' | 'city' | 'state' | 'latitude' | 'longitude'>
      ) }
    ), state: Maybe<(
      { __typename?: 'OrderState' }
      & Pick<OrderState, 'name' | 'timestamp'>
    )>, events: Array<(
      { __typename?: 'OrderEvent' }
      & Pick<OrderEvent, 'id' | 'createdOn' | 'name'>
    )>, items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'name'>
      & { events: Maybe<Array<(
        { __typename?: 'OrderItemEvent' }
        & Pick<OrderItemEvent, 'id' | 'name'>
        & { ioTPosition: Maybe<(
          { __typename?: 'IoTPosition' }
          & Pick<IoTPosition, 'id' | 'code'>
        )> }
      )>>, selectedModifiers: Maybe<Array<(
        { __typename?: 'OrderItemModifier' }
        & Pick<OrderItemModifier, 'id' | 'name'>
      )>> }
    )>, fulfillmentLocation: Maybe<(
      { __typename?: 'FulfillmentLocation' }
      & Pick<FulfillmentLocation, 'id' | 'displayName' | 'urlSafeName' | 'appUrl'>
    )> }
  ) }
);

export type MerchantsForOrganizationSplashQueryVariables = Exact<{
  readableOrganizationId: Scalars['ID'];
}>;


export type MerchantsForOrganizationSplashQuery = (
  { __typename?: 'Query' }
  & { merchantsByReadableOrganizationId: Array<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'readableId' | 'name'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    ) }
  )> }
);

export type MerchantForProductQueryVariables = Exact<{
  readableId: Scalars['String'];
}>;


export type MerchantForProductQuery = (
  { __typename?: 'Query' }
  & { merchantByReadableId: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'name'>
    & { location: (
      { __typename?: 'MerchantLocation' }
      & Pick<MerchantLocation, 'id' | 'street' | 'city' | 'state' | 'latitude' | 'longitude'>
    ) }
  )> }
);

export type ProductDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProductDetailsQuery = (
  { __typename?: 'Query' }
  & { product: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'description' | 'active' | 'priceCents' | 'priceTokens' | 'roastType' | 'roaster' | 'availability'>
    & { modifiers: Maybe<Array<(
      { __typename?: 'Modifier' }
      & Pick<Modifier, 'id' | 'name' | 'description' | 'childSelectionType' | 'priceCents' | 'priceTokens' | 'active' | 'defaultSelectionIds'>
      & { children: Maybe<Array<(
        { __typename?: 'Modifier' }
        & Pick<Modifier, 'id' | 'name' | 'description' | 'childSelectionType' | 'priceCents' | 'priceTokens' | 'selectedName' | 'active'>
      )>> }
    )>>, category: (
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'type'>
    ), productTags: Maybe<Array<(
      { __typename?: 'ProductTag' }
      & Pick<ProductTag, 'value' | 'tagType'>
    )>>, images: Maybe<Array<(
      { __typename?: 'ProductImage' }
      & Pick<ProductImage, 'id' | 'imageUri' | 'createdOn' | 'sortOrder'>
    )>> }
  )> }
);

export type BeansQueryVariables = Exact<{ [key: string]: never; }>;


export type BeansQuery = (
  { __typename?: 'Query' }
  & { beans: Array<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'priceCents' | 'name' | 'scheduleFrequency' | 'shippingRequired'>
  )> }
);

export type InitializeRequisitionMutationVariables = Exact<{
  input: InitializeRequisitionInput;
}>;


export type InitializeRequisitionMutation = (
  { __typename?: 'Mutation' }
  & { initializeRequisition: (
    { __typename?: 'RequisitionPurchase' }
    & Pick<RequisitionPurchase, 'id'>
    & { paymentIntent: Maybe<(
      { __typename?: 'RequisitionPurchasePaymentIntent' }
      & Pick<RequisitionPurchasePaymentIntent, 'id' | 'stripeId' | 'stripeClientSecret'>
    )> }
  ) }
);

export type PriceTagQueryVariables = Exact<{
  input: PriceTagInput;
}>;


export type PriceTagQuery = (
  { __typename?: 'Query' }
  & { priceTag: (
    { __typename?: 'RequisitionPriceTag' }
    & Pick<RequisitionPriceTag, 'subtotal' | 'tax'>
    & { discount: Maybe<(
      { __typename?: 'RequisitionPriceTagDiscount' }
      & Pick<RequisitionPriceTagDiscount, 'total' | 'name'>
    )> }
  ) }
);

export type UserStripeCustomerIdQueryVariables = Exact<{ [key: string]: never; }>;


export type UserStripeCustomerIdQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'stripeCustomerId'>
  )> }
);

export type ValidateAndCreateUserMutationVariables = Exact<{
  overrideDuplicateCheck: Maybe<Scalars['Boolean']>;
}>;


export type ValidateAndCreateUserMutation = (
  { __typename?: 'Mutation' }
  & { validateAndCreateUser: (
    { __typename?: 'LoginResponse' }
    & { me: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  ) }
);

export type ProductsForQuickCartQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ProductsForQuickCartQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'description' | 'active' | 'priceCents' | 'priceTokens' | 'roastType' | 'roaster' | 'availability'>
    & { merchant: (
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'name' | 'readableId'>
    ), modifiers: Maybe<Array<(
      { __typename?: 'Modifier' }
      & Pick<Modifier, 'id' | 'name' | 'description' | 'childSelectionType' | 'priceCents' | 'priceTokens' | 'active' | 'defaultSelectionIds'>
      & { children: Maybe<Array<(
        { __typename?: 'Modifier' }
        & Pick<Modifier, 'id' | 'name' | 'description' | 'childSelectionType' | 'priceCents' | 'priceTokens' | 'selectedName' | 'active'>
      )>> }
    )>>, category: (
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'type'>
    ), productTags: Maybe<Array<(
      { __typename?: 'ProductTag' }
      & Pick<ProductTag, 'value' | 'tagType'>
    )>>, images: Maybe<Array<(
      { __typename?: 'ProductImage' }
      & Pick<ProductImage, 'id' | 'imageUri' | 'createdOn' | 'sortOrder'>
    )>> }
  )> }
);


export const FeatureFlagDocument = gql`
    query FeatureFlag($merchantId: ID!, $featureFlagName: FeatureFlagName!) {
  featureFlag(merchantId: $merchantId, featureFlagName: $featureFlagName) {
    name
    enabled
  }
}
    `;

/**
 * __useFeatureFlagQuery__
 *
 * To run a query within a React component, call `useFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *      featureFlagName: // value for 'featureFlagName'
 *   },
 * });
 */
export function useFeatureFlagQuery(baseOptions: Apollo.QueryHookOptions<FeatureFlagQuery, FeatureFlagQueryVariables>) {
        return Apollo.useQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FeatureFlagDocument, baseOptions);
      }
export function useFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagQuery, FeatureFlagQueryVariables>) {
          return Apollo.useLazyQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FeatureFlagDocument, baseOptions);
        }
export type FeatureFlagQueryHookResult = ReturnType<typeof useFeatureFlagQuery>;
export type FeatureFlagLazyQueryHookResult = ReturnType<typeof useFeatureFlagLazyQuery>;
export type FeatureFlagQueryResult = Apollo.QueryResult<FeatureFlagQuery, FeatureFlagQueryVariables>;
export const UpdateRequisitionDocument = gql`
    mutation UpdateRequisition($input: UpdateRequisitionInput!) {
  updateRequisition(input: $input) {
    id
    schedule {
      id
      paused
    }
    purchases {
      id
      createdOn
      subtotal
      tax
    }
    items {
      id
      cents
      item {
        id
        name
      }
    }
    fulfillmentLocation {
      street
      street2
      city
      state
      zipcode
    }
  }
}
    `;
export type UpdateRequisitionMutationFn = Apollo.MutationFunction<UpdateRequisitionMutation, UpdateRequisitionMutationVariables>;

/**
 * __useUpdateRequisitionMutation__
 *
 * To run a mutation, you first call `useUpdateRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequisitionMutation, { data, loading, error }] = useUpdateRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequisitionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRequisitionMutation, UpdateRequisitionMutationVariables>) {
        return Apollo.useMutation<UpdateRequisitionMutation, UpdateRequisitionMutationVariables>(UpdateRequisitionDocument, baseOptions);
      }
export type UpdateRequisitionMutationHookResult = ReturnType<typeof useUpdateRequisitionMutation>;
export type UpdateRequisitionMutationResult = Apollo.MutationResult<UpdateRequisitionMutation>;
export type UpdateRequisitionMutationOptions = Apollo.BaseMutationOptions<UpdateRequisitionMutation, UpdateRequisitionMutationVariables>;
export const UserRequisitionsDocument = gql`
    query UserRequisitions {
  me {
    id
    requisitions {
      id
      firstName
      lastName
      createdOn
      schedule {
        id
        paymentMethodId
        paused
        frequency
        nextScheduledPayment
      }
      purchases {
        id
        createdOn
        subtotal
        tax
        discount
      }
      items {
        id
        cents
        item {
          id
          name
        }
      }
      fulfillmentLocation {
        id
        street
        street2
        city
        state
        zipcode
      }
    }
  }
}
    `;

/**
 * __useUserRequisitionsQuery__
 *
 * To run a query within a React component, call `useUserRequisitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRequisitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRequisitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRequisitionsQuery(baseOptions?: Apollo.QueryHookOptions<UserRequisitionsQuery, UserRequisitionsQueryVariables>) {
        return Apollo.useQuery<UserRequisitionsQuery, UserRequisitionsQueryVariables>(UserRequisitionsDocument, baseOptions);
      }
export function useUserRequisitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRequisitionsQuery, UserRequisitionsQueryVariables>) {
          return Apollo.useLazyQuery<UserRequisitionsQuery, UserRequisitionsQueryVariables>(UserRequisitionsDocument, baseOptions);
        }
export type UserRequisitionsQueryHookResult = ReturnType<typeof useUserRequisitionsQuery>;
export type UserRequisitionsLazyQueryHookResult = ReturnType<typeof useUserRequisitionsLazyQuery>;
export type UserRequisitionsQueryResult = Apollo.QueryResult<UserRequisitionsQuery, UserRequisitionsQueryVariables>;
export const GenerateOrderPaymentSummaryDocument = gql`
    mutation GenerateOrderPaymentSummary($input: GenerateOrderPaymentSummaryInput!) {
  generateOrderPaymentSummary(input: $input) {
    bloomTokens
    usCents {
      subtotal
      fee
      tax
      tip
      discount
      total
    }
    promotionsUsedForDiscount {
      usCentsDeductedFromSubtotal
      promotion {
        id
        customerFacingName
      }
    }
  }
}
    `;
export type GenerateOrderPaymentSummaryMutationFn = Apollo.MutationFunction<GenerateOrderPaymentSummaryMutation, GenerateOrderPaymentSummaryMutationVariables>;

/**
 * __useGenerateOrderPaymentSummaryMutation__
 *
 * To run a mutation, you first call `useGenerateOrderPaymentSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOrderPaymentSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOrderPaymentSummaryMutation, { data, loading, error }] = useGenerateOrderPaymentSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateOrderPaymentSummaryMutation(baseOptions?: Apollo.MutationHookOptions<GenerateOrderPaymentSummaryMutation, GenerateOrderPaymentSummaryMutationVariables>) {
        return Apollo.useMutation<GenerateOrderPaymentSummaryMutation, GenerateOrderPaymentSummaryMutationVariables>(GenerateOrderPaymentSummaryDocument, baseOptions);
      }
export type GenerateOrderPaymentSummaryMutationHookResult = ReturnType<typeof useGenerateOrderPaymentSummaryMutation>;
export type GenerateOrderPaymentSummaryMutationResult = Apollo.MutationResult<GenerateOrderPaymentSummaryMutation>;
export type GenerateOrderPaymentSummaryMutationOptions = Apollo.BaseMutationOptions<GenerateOrderPaymentSummaryMutation, GenerateOrderPaymentSummaryMutationVariables>;
export const MerchantForCartDocument = gql`
    query MerchantForCart($id: ID!) {
  merchant(id: $id) {
    id
    name
    readableId
    operationHours {
      id
      dayOfWeek
      start
      end
      durationMinutes
    }
    location {
      id
      street
      city
      latitude
      longitude
      state
    }
    images {
      id
      imageUri
      sortOrder
      createdOn
    }
    diningOptions
    servingLocations {
      id
      displayName
      urlSafeName
      appUrl
    }
    settings {
      name
      value
      valueType
    }
  }
}
    `;

/**
 * __useMerchantForCartQuery__
 *
 * To run a query within a React component, call `useMerchantForCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantForCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantForCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchantForCartQuery(baseOptions: Apollo.QueryHookOptions<MerchantForCartQuery, MerchantForCartQueryVariables>) {
        return Apollo.useQuery<MerchantForCartQuery, MerchantForCartQueryVariables>(MerchantForCartDocument, baseOptions);
      }
export function useMerchantForCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantForCartQuery, MerchantForCartQueryVariables>) {
          return Apollo.useLazyQuery<MerchantForCartQuery, MerchantForCartQueryVariables>(MerchantForCartDocument, baseOptions);
        }
export type MerchantForCartQueryHookResult = ReturnType<typeof useMerchantForCartQuery>;
export type MerchantForCartLazyQueryHookResult = ReturnType<typeof useMerchantForCartLazyQuery>;
export type MerchantForCartQueryResult = Apollo.QueryResult<MerchantForCartQuery, MerchantForCartQueryVariables>;
export const UpsellDataDocument = gql`
    query UpsellData($id: ID!) {
  merchant(id: $id) {
    id
    name
    activeCatalog {
      id
      name
      activeCategories {
        id
        name
        type
        activeProducts {
          id
          name
          priceCents
          priceTokens
          category {
            id
            type
          }
          modifiers {
            id
            children {
              id
              priceTokens
              priceCents
            }
          }
          productTags {
            value
            tagType
          }
          roaster
          images {
            id
            imageUri
            createdOn
            sortOrder
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUpsellDataQuery__
 *
 * To run a query within a React component, call `useUpsellDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpsellDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpsellDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpsellDataQuery(baseOptions: Apollo.QueryHookOptions<UpsellDataQuery, UpsellDataQueryVariables>) {
        return Apollo.useQuery<UpsellDataQuery, UpsellDataQueryVariables>(UpsellDataDocument, baseOptions);
      }
export function useUpsellDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpsellDataQuery, UpsellDataQueryVariables>) {
          return Apollo.useLazyQuery<UpsellDataQuery, UpsellDataQueryVariables>(UpsellDataDocument, baseOptions);
        }
export type UpsellDataQueryHookResult = ReturnType<typeof useUpsellDataQuery>;
export type UpsellDataLazyQueryHookResult = ReturnType<typeof useUpsellDataLazyQuery>;
export type UpsellDataQueryResult = Apollo.QueryResult<UpsellDataQuery, UpsellDataQueryVariables>;
export const NotableProductInfoDocument = gql`
    query NotableProductInfo($id: ID!) {
  catalog(id: $id) {
    id
    topProducts {
      id
      name
    }
    trendingProducts {
      id
      name
    }
  }
}
    `;

/**
 * __useNotableProductInfoQuery__
 *
 * To run a query within a React component, call `useNotableProductInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotableProductInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotableProductInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotableProductInfoQuery(baseOptions: Apollo.QueryHookOptions<NotableProductInfoQuery, NotableProductInfoQueryVariables>) {
        return Apollo.useQuery<NotableProductInfoQuery, NotableProductInfoQueryVariables>(NotableProductInfoDocument, baseOptions);
      }
export function useNotableProductInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotableProductInfoQuery, NotableProductInfoQueryVariables>) {
          return Apollo.useLazyQuery<NotableProductInfoQuery, NotableProductInfoQueryVariables>(NotableProductInfoDocument, baseOptions);
        }
export type NotableProductInfoQueryHookResult = ReturnType<typeof useNotableProductInfoQuery>;
export type NotableProductInfoLazyQueryHookResult = ReturnType<typeof useNotableProductInfoLazyQuery>;
export type NotableProductInfoQueryResult = Apollo.QueryResult<NotableProductInfoQuery, NotableProductInfoQueryVariables>;
export const InitializeOrderDocument = gql`
    mutation InitializeOrder($input: InitializeOrderInput!) {
  initializeOrder(input: $input) {
    order {
      id
      uuid
      transactions {
        id
        subtotalUsCents
        taxUsCents
        tipUsCents
        feeUsCents
      }
    }
    paymentIntent {
      id
      clientSecret
    }
  }
}
    `;
export type InitializeOrderMutationFn = Apollo.MutationFunction<InitializeOrderMutation, InitializeOrderMutationVariables>;

/**
 * __useInitializeOrderMutation__
 *
 * To run a mutation, you first call `useInitializeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeOrderMutation, { data, loading, error }] = useInitializeOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitializeOrderMutation(baseOptions?: Apollo.MutationHookOptions<InitializeOrderMutation, InitializeOrderMutationVariables>) {
        return Apollo.useMutation<InitializeOrderMutation, InitializeOrderMutationVariables>(InitializeOrderDocument, baseOptions);
      }
export type InitializeOrderMutationHookResult = ReturnType<typeof useInitializeOrderMutation>;
export type InitializeOrderMutationResult = Apollo.MutationResult<InitializeOrderMutation>;
export type InitializeOrderMutationOptions = Apollo.BaseMutationOptions<InitializeOrderMutation, InitializeOrderMutationVariables>;
export const MerchantCatalogDocument = gql`
    query MerchantCatalog($readableId: String!) {
  merchantByReadableId(readableId: $readableId) {
    id
    activeCatalog {
      id
      name
      description
      suggestedProducts {
        id
        name
        priceCents
        sortOrder
        category {
          id
          name
          type
          sortOrder
        }
        modifiers {
          id
          name
          description
          childSelectionType
          priceCents
          priceTokens
          active
          defaultSelectionIds
          children {
            id
            name
            description
            childSelectionType
            priceCents
            priceTokens
            selectedName
            active
          }
        }
        roaster
        images {
          id
          imageUri
          createdOn
          sortOrder
        }
        productTags {
          value
          tagType
        }
      }
      activeProducts {
        id
        name
        priceCents
        sortOrder
        productTags {
          value
          tagType
        }
        roaster
        images {
          id
          imageUri
          createdOn
          sortOrder
        }
        category {
          id
          type
          name
          sortOrder
        }
        modifiers {
          id
          name
          description
          childSelectionType
          priceCents
          priceTokens
          active
          defaultSelectionIds
          children {
            id
            name
            description
            childSelectionType
            priceCents
            priceTokens
            selectedName
            active
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMerchantCatalogQuery__
 *
 * To run a query within a React component, call `useMerchantCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantCatalogQuery({
 *   variables: {
 *      readableId: // value for 'readableId'
 *   },
 * });
 */
export function useMerchantCatalogQuery(baseOptions: Apollo.QueryHookOptions<MerchantCatalogQuery, MerchantCatalogQueryVariables>) {
        return Apollo.useQuery<MerchantCatalogQuery, MerchantCatalogQueryVariables>(MerchantCatalogDocument, baseOptions);
      }
export function useMerchantCatalogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantCatalogQuery, MerchantCatalogQueryVariables>) {
          return Apollo.useLazyQuery<MerchantCatalogQuery, MerchantCatalogQueryVariables>(MerchantCatalogDocument, baseOptions);
        }
export type MerchantCatalogQueryHookResult = ReturnType<typeof useMerchantCatalogQuery>;
export type MerchantCatalogLazyQueryHookResult = ReturnType<typeof useMerchantCatalogLazyQuery>;
export type MerchantCatalogQueryResult = Apollo.QueryResult<MerchantCatalogQuery, MerchantCatalogQueryVariables>;
export const MerchantOverviewDocument = gql`
    query MerchantOverview($readableId: String!) {
  merchantByReadableId(readableId: $readableId) {
    id
    readableId
    name
    active
    archived
    images {
      id
      imageUri
      sortOrder
      createdOn
    }
    operationHours {
      id
      dayOfWeek
      start
      end
      durationMinutes
    }
    location {
      id
      latitude
      longitude
      street
      street2
      zipCode
      city
      state
      countryCode
    }
    organization {
      id
    }
    servingLocations {
      id
      displayName
      urlSafeName
      appUrl
    }
  }
}
    `;

/**
 * __useMerchantOverviewQuery__
 *
 * To run a query within a React component, call `useMerchantOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantOverviewQuery({
 *   variables: {
 *      readableId: // value for 'readableId'
 *   },
 * });
 */
export function useMerchantOverviewQuery(baseOptions: Apollo.QueryHookOptions<MerchantOverviewQuery, MerchantOverviewQueryVariables>) {
        return Apollo.useQuery<MerchantOverviewQuery, MerchantOverviewQueryVariables>(MerchantOverviewDocument, baseOptions);
      }
export function useMerchantOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantOverviewQuery, MerchantOverviewQueryVariables>) {
          return Apollo.useLazyQuery<MerchantOverviewQuery, MerchantOverviewQueryVariables>(MerchantOverviewDocument, baseOptions);
        }
export type MerchantOverviewQueryHookResult = ReturnType<typeof useMerchantOverviewQuery>;
export type MerchantOverviewLazyQueryHookResult = ReturnType<typeof useMerchantOverviewLazyQuery>;
export type MerchantOverviewQueryResult = Apollo.QueryResult<MerchantOverviewQuery, MerchantOverviewQueryVariables>;
export const MerchantsByOrganizationDocument = gql`
    query MerchantsByOrganization($id: ID!) {
  merchantsByOrganizationId(id: $id) {
    id
    name
    readableId
  }
}
    `;

/**
 * __useMerchantsByOrganizationQuery__
 *
 * To run a query within a React component, call `useMerchantsByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantsByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantsByOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchantsByOrganizationQuery(baseOptions: Apollo.QueryHookOptions<MerchantsByOrganizationQuery, MerchantsByOrganizationQueryVariables>) {
        return Apollo.useQuery<MerchantsByOrganizationQuery, MerchantsByOrganizationQueryVariables>(MerchantsByOrganizationDocument, baseOptions);
      }
export function useMerchantsByOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantsByOrganizationQuery, MerchantsByOrganizationQueryVariables>) {
          return Apollo.useLazyQuery<MerchantsByOrganizationQuery, MerchantsByOrganizationQueryVariables>(MerchantsByOrganizationDocument, baseOptions);
        }
export type MerchantsByOrganizationQueryHookResult = ReturnType<typeof useMerchantsByOrganizationQuery>;
export type MerchantsByOrganizationLazyQueryHookResult = ReturnType<typeof useMerchantsByOrganizationLazyQuery>;
export type MerchantsByOrganizationQueryResult = Apollo.QueryResult<MerchantsByOrganizationQuery, MerchantsByOrganizationQueryVariables>;
export const UserForGuestOrderDocument = gql`
    query UserForGuestOrder($uuid: String!) {
  orderByUuid(uuid: $uuid) {
    id
    user {
      id
      profile {
        firstName
        lastName
        contactEmail
        phoneNumber
      }
    }
  }
}
    `;

/**
 * __useUserForGuestOrderQuery__
 *
 * To run a query within a React component, call `useUserForGuestOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserForGuestOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserForGuestOrderQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUserForGuestOrderQuery(baseOptions: Apollo.QueryHookOptions<UserForGuestOrderQuery, UserForGuestOrderQueryVariables>) {
        return Apollo.useQuery<UserForGuestOrderQuery, UserForGuestOrderQueryVariables>(UserForGuestOrderDocument, baseOptions);
      }
export function useUserForGuestOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserForGuestOrderQuery, UserForGuestOrderQueryVariables>) {
          return Apollo.useLazyQuery<UserForGuestOrderQuery, UserForGuestOrderQueryVariables>(UserForGuestOrderDocument, baseOptions);
        }
export type UserForGuestOrderQueryHookResult = ReturnType<typeof useUserForGuestOrderQuery>;
export type UserForGuestOrderLazyQueryHookResult = ReturnType<typeof useUserForGuestOrderLazyQuery>;
export type UserForGuestOrderQueryResult = Apollo.QueryResult<UserForGuestOrderQuery, UserForGuestOrderQueryVariables>;
export const OrderReceiptDocument = gql`
    query OrderReceipt($uuid: String!) {
  orderByUuid(uuid: $uuid) {
    id
    uuid
    createdOn
    merchant {
      id
      readableId
      name
      settings {
        name
        value
        valueType
      }
      operationHours {
        id
        dayOfWeek
        start
        end
        durationMinutes
      }
      location {
        id
        street
        city
        state
        latitude
        longitude
      }
    }
    events {
      id
      createdOn
      name
    }
    transactions {
      id
      createdOn
      tokenValue
      subtotalUsCents
      feeUsCents
      taxUsCents
      tipUsCents
      discountUsCents
      refunds {
        id
        initializedOn
        tokenValue
        amountUsCents
        stripeId
      }
    }
    items {
      id
      name
      orderableProduct {
        id
        name
        priceCents
        active
        modifiers {
          id
          name
          childSelectionType
          children {
            id
            name
            selectedName
          }
        }
        roaster
        category {
          id
          type
        }
      }
      notes
      selectedModifiers {
        id
        name
        originalModifier {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useOrderReceiptQuery__
 *
 * To run a query within a React component, call `useOrderReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderReceiptQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useOrderReceiptQuery(baseOptions: Apollo.QueryHookOptions<OrderReceiptQuery, OrderReceiptQueryVariables>) {
        return Apollo.useQuery<OrderReceiptQuery, OrderReceiptQueryVariables>(OrderReceiptDocument, baseOptions);
      }
export function useOrderReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderReceiptQuery, OrderReceiptQueryVariables>) {
          return Apollo.useLazyQuery<OrderReceiptQuery, OrderReceiptQueryVariables>(OrderReceiptDocument, baseOptions);
        }
export type OrderReceiptQueryHookResult = ReturnType<typeof useOrderReceiptQuery>;
export type OrderReceiptLazyQueryHookResult = ReturnType<typeof useOrderReceiptLazyQuery>;
export type OrderReceiptQueryResult = Apollo.QueryResult<OrderReceiptQuery, OrderReceiptQueryVariables>;
export const OrderByUuidDocument = gql`
    query OrderByUuid($uuid: String!) {
  orderByUuid(uuid: $uuid) {
    id
    uuid
    createdOn
    merchant {
      id
      readableId
      name
      location {
        id
        street
        city
        state
        latitude
        longitude
      }
    }
    state {
      name
      timestamp
    }
    events {
      id
      createdOn
      name
    }
    items {
      id
      name
      events {
        id
        ioTPosition {
          id
          code
        }
        name
      }
      selectedModifiers {
        id
        name
      }
    }
    expectedRewardPoints
    fulfillmentLocation {
      id
      displayName
      urlSafeName
      appUrl
    }
  }
}
    `;

/**
 * __useOrderByUuidQuery__
 *
 * To run a query within a React component, call `useOrderByUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderByUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderByUuidQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useOrderByUuidQuery(baseOptions: Apollo.QueryHookOptions<OrderByUuidQuery, OrderByUuidQueryVariables>) {
        return Apollo.useQuery<OrderByUuidQuery, OrderByUuidQueryVariables>(OrderByUuidDocument, baseOptions);
      }
export function useOrderByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderByUuidQuery, OrderByUuidQueryVariables>) {
          return Apollo.useLazyQuery<OrderByUuidQuery, OrderByUuidQueryVariables>(OrderByUuidDocument, baseOptions);
        }
export type OrderByUuidQueryHookResult = ReturnType<typeof useOrderByUuidQuery>;
export type OrderByUuidLazyQueryHookResult = ReturnType<typeof useOrderByUuidLazyQuery>;
export type OrderByUuidQueryResult = Apollo.QueryResult<OrderByUuidQuery, OrderByUuidQueryVariables>;
export const MerchantsForOrganizationSplashDocument = gql`
    query MerchantsForOrganizationSplash($readableOrganizationId: ID!) {
  merchantsByReadableOrganizationId(
    readableOrganizationId: $readableOrganizationId
  ) {
    id
    readableId
    name
    organization {
      id
      name
    }
  }
}
    `;

/**
 * __useMerchantsForOrganizationSplashQuery__
 *
 * To run a query within a React component, call `useMerchantsForOrganizationSplashQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantsForOrganizationSplashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantsForOrganizationSplashQuery({
 *   variables: {
 *      readableOrganizationId: // value for 'readableOrganizationId'
 *   },
 * });
 */
export function useMerchantsForOrganizationSplashQuery(baseOptions: Apollo.QueryHookOptions<MerchantsForOrganizationSplashQuery, MerchantsForOrganizationSplashQueryVariables>) {
        return Apollo.useQuery<MerchantsForOrganizationSplashQuery, MerchantsForOrganizationSplashQueryVariables>(MerchantsForOrganizationSplashDocument, baseOptions);
      }
export function useMerchantsForOrganizationSplashLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantsForOrganizationSplashQuery, MerchantsForOrganizationSplashQueryVariables>) {
          return Apollo.useLazyQuery<MerchantsForOrganizationSplashQuery, MerchantsForOrganizationSplashQueryVariables>(MerchantsForOrganizationSplashDocument, baseOptions);
        }
export type MerchantsForOrganizationSplashQueryHookResult = ReturnType<typeof useMerchantsForOrganizationSplashQuery>;
export type MerchantsForOrganizationSplashLazyQueryHookResult = ReturnType<typeof useMerchantsForOrganizationSplashLazyQuery>;
export type MerchantsForOrganizationSplashQueryResult = Apollo.QueryResult<MerchantsForOrganizationSplashQuery, MerchantsForOrganizationSplashQueryVariables>;
export const MerchantForProductDocument = gql`
    query MerchantForProduct($readableId: String!) {
  merchantByReadableId(readableId: $readableId) {
    id
    name
    location {
      id
      street
      city
      state
      latitude
      longitude
    }
  }
}
    `;

/**
 * __useMerchantForProductQuery__
 *
 * To run a query within a React component, call `useMerchantForProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantForProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantForProductQuery({
 *   variables: {
 *      readableId: // value for 'readableId'
 *   },
 * });
 */
export function useMerchantForProductQuery(baseOptions: Apollo.QueryHookOptions<MerchantForProductQuery, MerchantForProductQueryVariables>) {
        return Apollo.useQuery<MerchantForProductQuery, MerchantForProductQueryVariables>(MerchantForProductDocument, baseOptions);
      }
export function useMerchantForProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantForProductQuery, MerchantForProductQueryVariables>) {
          return Apollo.useLazyQuery<MerchantForProductQuery, MerchantForProductQueryVariables>(MerchantForProductDocument, baseOptions);
        }
export type MerchantForProductQueryHookResult = ReturnType<typeof useMerchantForProductQuery>;
export type MerchantForProductLazyQueryHookResult = ReturnType<typeof useMerchantForProductLazyQuery>;
export type MerchantForProductQueryResult = Apollo.QueryResult<MerchantForProductQuery, MerchantForProductQueryVariables>;
export const ProductDetailsDocument = gql`
    query ProductDetails($id: ID!) {
  product(id: $id) {
    id
    name
    description
    active
    priceCents
    priceTokens
    roastType
    roaster
    modifiers {
      id
      name
      description
      childSelectionType
      priceCents
      priceTokens
      active
      defaultSelectionIds
      children {
        id
        name
        description
        childSelectionType
        priceCents
        priceTokens
        selectedName
        active
      }
    }
    category {
      id
      name
      type
    }
    productTags {
      value
      tagType
    }
    images {
      id
      imageUri
      createdOn
      sortOrder
    }
    availability
  }
}
    `;

/**
 * __useProductDetailsQuery__
 *
 * To run a query within a React component, call `useProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductDetailsQuery(baseOptions: Apollo.QueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
        return Apollo.useQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, baseOptions);
      }
export function useProductDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
          return Apollo.useLazyQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, baseOptions);
        }
export type ProductDetailsQueryHookResult = ReturnType<typeof useProductDetailsQuery>;
export type ProductDetailsLazyQueryHookResult = ReturnType<typeof useProductDetailsLazyQuery>;
export type ProductDetailsQueryResult = Apollo.QueryResult<ProductDetailsQuery, ProductDetailsQueryVariables>;
export const BeansDocument = gql`
    query Beans {
  beans {
    id
    priceCents
    name
    scheduleFrequency
    shippingRequired
  }
}
    `;

/**
 * __useBeansQuery__
 *
 * To run a query within a React component, call `useBeansQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeansQuery({
 *   variables: {
 *   },
 * });
 */
export function useBeansQuery(baseOptions?: Apollo.QueryHookOptions<BeansQuery, BeansQueryVariables>) {
        return Apollo.useQuery<BeansQuery, BeansQueryVariables>(BeansDocument, baseOptions);
      }
export function useBeansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeansQuery, BeansQueryVariables>) {
          return Apollo.useLazyQuery<BeansQuery, BeansQueryVariables>(BeansDocument, baseOptions);
        }
export type BeansQueryHookResult = ReturnType<typeof useBeansQuery>;
export type BeansLazyQueryHookResult = ReturnType<typeof useBeansLazyQuery>;
export type BeansQueryResult = Apollo.QueryResult<BeansQuery, BeansQueryVariables>;
export const InitializeRequisitionDocument = gql`
    mutation InitializeRequisition($input: InitializeRequisitionInput!) {
  initializeRequisition(input: $input) {
    id
    paymentIntent {
      id
      stripeId
      stripeClientSecret
    }
  }
}
    `;
export type InitializeRequisitionMutationFn = Apollo.MutationFunction<InitializeRequisitionMutation, InitializeRequisitionMutationVariables>;

/**
 * __useInitializeRequisitionMutation__
 *
 * To run a mutation, you first call `useInitializeRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeRequisitionMutation, { data, loading, error }] = useInitializeRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitializeRequisitionMutation(baseOptions?: Apollo.MutationHookOptions<InitializeRequisitionMutation, InitializeRequisitionMutationVariables>) {
        return Apollo.useMutation<InitializeRequisitionMutation, InitializeRequisitionMutationVariables>(InitializeRequisitionDocument, baseOptions);
      }
export type InitializeRequisitionMutationHookResult = ReturnType<typeof useInitializeRequisitionMutation>;
export type InitializeRequisitionMutationResult = Apollo.MutationResult<InitializeRequisitionMutation>;
export type InitializeRequisitionMutationOptions = Apollo.BaseMutationOptions<InitializeRequisitionMutation, InitializeRequisitionMutationVariables>;
export const PriceTagDocument = gql`
    query PriceTag($input: PriceTagInput!) {
  priceTag(input: $input) {
    subtotal
    tax
    discount {
      total
      name
    }
  }
}
    `;

/**
 * __usePriceTagQuery__
 *
 * To run a query within a React component, call `usePriceTagQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceTagQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePriceTagQuery(baseOptions: Apollo.QueryHookOptions<PriceTagQuery, PriceTagQueryVariables>) {
        return Apollo.useQuery<PriceTagQuery, PriceTagQueryVariables>(PriceTagDocument, baseOptions);
      }
export function usePriceTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PriceTagQuery, PriceTagQueryVariables>) {
          return Apollo.useLazyQuery<PriceTagQuery, PriceTagQueryVariables>(PriceTagDocument, baseOptions);
        }
export type PriceTagQueryHookResult = ReturnType<typeof usePriceTagQuery>;
export type PriceTagLazyQueryHookResult = ReturnType<typeof usePriceTagLazyQuery>;
export type PriceTagQueryResult = Apollo.QueryResult<PriceTagQuery, PriceTagQueryVariables>;
export const UserStripeCustomerIdDocument = gql`
    query UserStripeCustomerId {
  me {
    id
    stripeCustomerId
  }
}
    `;

/**
 * __useUserStripeCustomerIdQuery__
 *
 * To run a query within a React component, call `useUserStripeCustomerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStripeCustomerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStripeCustomerIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserStripeCustomerIdQuery(baseOptions?: Apollo.QueryHookOptions<UserStripeCustomerIdQuery, UserStripeCustomerIdQueryVariables>) {
        return Apollo.useQuery<UserStripeCustomerIdQuery, UserStripeCustomerIdQueryVariables>(UserStripeCustomerIdDocument, baseOptions);
      }
export function useUserStripeCustomerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserStripeCustomerIdQuery, UserStripeCustomerIdQueryVariables>) {
          return Apollo.useLazyQuery<UserStripeCustomerIdQuery, UserStripeCustomerIdQueryVariables>(UserStripeCustomerIdDocument, baseOptions);
        }
export type UserStripeCustomerIdQueryHookResult = ReturnType<typeof useUserStripeCustomerIdQuery>;
export type UserStripeCustomerIdLazyQueryHookResult = ReturnType<typeof useUserStripeCustomerIdLazyQuery>;
export type UserStripeCustomerIdQueryResult = Apollo.QueryResult<UserStripeCustomerIdQuery, UserStripeCustomerIdQueryVariables>;
export const ValidateAndCreateUserDocument = gql`
    mutation ValidateAndCreateUser($overrideDuplicateCheck: Boolean) {
  validateAndCreateUser(overrideDuplicateCheck: $overrideDuplicateCheck) {
    me {
      id
    }
  }
}
    `;
export type ValidateAndCreateUserMutationFn = Apollo.MutationFunction<ValidateAndCreateUserMutation, ValidateAndCreateUserMutationVariables>;

/**
 * __useValidateAndCreateUserMutation__
 *
 * To run a mutation, you first call `useValidateAndCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAndCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAndCreateUserMutation, { data, loading, error }] = useValidateAndCreateUserMutation({
 *   variables: {
 *      overrideDuplicateCheck: // value for 'overrideDuplicateCheck'
 *   },
 * });
 */
export function useValidateAndCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<ValidateAndCreateUserMutation, ValidateAndCreateUserMutationVariables>) {
        return Apollo.useMutation<ValidateAndCreateUserMutation, ValidateAndCreateUserMutationVariables>(ValidateAndCreateUserDocument, baseOptions);
      }
export type ValidateAndCreateUserMutationHookResult = ReturnType<typeof useValidateAndCreateUserMutation>;
export type ValidateAndCreateUserMutationResult = Apollo.MutationResult<ValidateAndCreateUserMutation>;
export type ValidateAndCreateUserMutationOptions = Apollo.BaseMutationOptions<ValidateAndCreateUserMutation, ValidateAndCreateUserMutationVariables>;
export const ProductsForQuickCartDocument = gql`
    query ProductsForQuickCart($ids: [ID!]!) {
  products(ids: $ids) {
    id
    name
    description
    active
    priceCents
    priceTokens
    roastType
    roaster
    merchant {
      id
      name
      readableId
    }
    modifiers {
      id
      name
      description
      childSelectionType
      priceCents
      priceTokens
      active
      defaultSelectionIds
      children {
        id
        name
        description
        childSelectionType
        priceCents
        priceTokens
        selectedName
        active
      }
    }
    category {
      id
      name
      type
    }
    productTags {
      value
      tagType
    }
    images {
      id
      imageUri
      createdOn
      sortOrder
    }
    availability
  }
}
    `;

/**
 * __useProductsForQuickCartQuery__
 *
 * To run a query within a React component, call `useProductsForQuickCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForQuickCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForQuickCartQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useProductsForQuickCartQuery(baseOptions: Apollo.QueryHookOptions<ProductsForQuickCartQuery, ProductsForQuickCartQueryVariables>) {
        return Apollo.useQuery<ProductsForQuickCartQuery, ProductsForQuickCartQueryVariables>(ProductsForQuickCartDocument, baseOptions);
      }
export function useProductsForQuickCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsForQuickCartQuery, ProductsForQuickCartQueryVariables>) {
          return Apollo.useLazyQuery<ProductsForQuickCartQuery, ProductsForQuickCartQueryVariables>(ProductsForQuickCartDocument, baseOptions);
        }
export type ProductsForQuickCartQueryHookResult = ReturnType<typeof useProductsForQuickCartQuery>;
export type ProductsForQuickCartLazyQueryHookResult = ReturnType<typeof useProductsForQuickCartLazyQuery>;
export type ProductsForQuickCartQueryResult = Apollo.QueryResult<ProductsForQuickCartQuery, ProductsForQuickCartQueryVariables>;