import { formatUsCentsAsUsDollar } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

interface OrderSummaryProps {
  subtotalUsCents: number
  tipUsCents: number
  taxUsCents: number
  feeUsCents: number
  totalUsCents?: number
}

export const OrderSummary = (props: OrderSummaryProps) => {
  const { subtotalUsCents, tipUsCents, taxUsCents, feeUsCents, totalUsCents } = props

  let total = totalUsCents
  if (!total) {
    total = subtotalUsCents + (tipUsCents || 0) + taxUsCents + feeUsCents
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'space-around', flexDirection: 'column' }}>
        <div>
          <Text variant='body1' color={Color.RDY_FOREST}>
            Subtotal
          </Text>
        </div>
        <div>
          <Text variant='body1' color={Color.RDY_FOREST}>
            Tip
          </Text>
        </div>

        <div>
          <Text variant='body1' color={Color.RDY_FOREST}>
            Taxes & Fees
          </Text>
        </div>

        <div>
          <Text variant='body1' color={Color.RDY_FOREST}>
            Total
          </Text>
        </div>
      </div>

      <div
        style={{ display: 'flex', flex: 1, alignItems: 'flex-end', justifyContent: 'center', flexDirection: 'column' }}
      >
        <div>
          <Text variant='subheader2' color={Color.RDY_FOREST}>
            {formatUsCentsAsUsDollar(subtotalUsCents)}
          </Text>
        </div>
        <div>
          <Text variant='subheader2' color={Color.RDY_FOREST}>
            {formatUsCentsAsUsDollar(tipUsCents)}
          </Text>
        </div>
        <div>
          <Text variant='subheader2' color={Color.RDY_FOREST}>
            {formatUsCentsAsUsDollar(feeUsCents + taxUsCents)}
          </Text>
        </div>
        <div>
          <Text variant='subheader2' color={Color.RDY_FOREST}>
            ${formatUsCentsAsUsDollar(total)}
          </Text>
        </div>
      </div>
    </div>
  )
}
