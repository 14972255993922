/* eslint-disable max-len */
import React from 'react'

export const Position4 = (props: { height: number }) => {
  const { height } = props
  return (
    <svg height={height} width='100%' viewBox='0 0 72 81' fill='none'>
      <path
        d='M72 54.5625C72 69.075 60.2059 81 45.6037 81H26.3963C11.9064 81 0 69.075 0 54.5625V26.4375C0 11.925 11.9064 0 26.3963 0H45.6037C60.0936 0 72 11.925 72 26.4375V54.5625Z'
        fill='#09292D'
      />
      <path
        d='M54.0276 48.4875H46.5018V39.4875H35.6064V48.4875H26.5081L47.7374 10.125H35.1571L13.9277 48.7125V59.625H22.1274H35.6064V68.85H46.5018V59.625H54.0276L58.8575 54L54.0276 48.4875Z'
        fill='#F2EFE4'
      />
    </svg>
  )
}
