import { Button } from '@bloom-coffee/steamed-milk'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { TextField } from '../../../components/TextField'
import { AddressFormModel, addressFormSchema } from './addressFormValidator'

interface AddressFormProps {
  onComplete: (input: AddressFormModel) => void
}
export const AddressForm = (props: AddressFormProps) => {
  const { onComplete } = props

  const formProps = useForm<AddressFormModel>({
    resolver: yupResolver(addressFormSchema)
  })
  const { register, handleSubmit, formState } = formProps

  const textFieldWidth = window.innerWidth > 400 ? 250 : 140

  function passBack(model: AddressFormModel) {
    onComplete(model)
  }

  return (
    <div>
      <FormProvider {...formProps}>
        <form>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            <div style={{ marginRight: 4 }}>
              <TextField name='first' label='First Name' ref={register()} width={textFieldWidth} />
            </div>
            <div style={{ marginLeft: 4, marginRight: 4 }}>
              <TextField name='last' label='Last Name' ref={register()} width={textFieldWidth} />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            <div style={{ marginRight: 4 }}>
              <TextField name='street' label='Address Line 1' ref={register()} width={textFieldWidth} />
            </div>
            <div style={{ marginLeft: 4, marginRight: 4 }}>
              <TextField name='street2' label='Address Line 2 (optional)' ref={register()} width={textFieldWidth} />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingBottom: 12
            }}
          >
            <div style={{ marginRight: 4 }}>
              <TextField name='city' label='City' ref={register()} width={textFieldWidth} />
            </div>
            <div style={{ marginLeft: 4, marginRight: 4 }}>
              <TextField name='state' label='State' ref={register()} width={textFieldWidth} />
            </div>
            <div style={{ marginLeft: 4 }}>
              <TextField name='zipcode' label='Zip Code' ref={register()} width={textFieldWidth} />
            </div>
          </div>

          <div style={{ marginBottom: 12 }}>
            <Button
              theme='action'
              onClick={handleSubmit(passBack)}
              size='medium'
              label='Save'
              disabled={formState.isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
