import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { AuthProvider } from '../providers/auth/AuthProvider'
import { Account } from './Account/Account'
import { Beans } from './Purchase/Beans'

export const CanAuthRoot = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path='account' element={<Account />} />
        <Route path='' element={<Beans />} />
      </Routes>
    </AuthProvider>
  )
}
