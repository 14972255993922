import { OrderStateInfo, OrderStateName, readableTimeFromInstant } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import Icon from '../../../components/RdyIcon/Icon'
import { RdyIconName } from '../../../components/RdyIcon/RdyIconName'

interface OrderStatusShapeProps {
  isRdy: boolean
  orderState: OrderStateInfo
}

const orderStateNameToReadable: Record<OrderStateName, string> = {
  [OrderStateName.Pending]: 'Confirming Payment',
  [OrderStateName.PaymentRejected]: 'Payment Declined',
  [OrderStateName.PaymentFulfilled]: 'Confirmed',
  [OrderStateName.Preparing]: 'Preparing',
  [OrderStateName.ReadyForPickupWithRdyPass]: 'RDY',
  [OrderStateName.ReadyForPickupWithTraditionalPass]: 'RDY',
  [OrderStateName.Stale]: 'Check In with the Staff',
  [OrderStateName.PickedUpFromRdyPass]: 'Picked Up'
}

const orderStateNameToIconInfo: Record<OrderStateName, { glyph: RdyIconName; color: Color }> = {
  [OrderStateName.Pending]: { glyph: RdyIconName.SUN, color: Color.WHITE },
  [OrderStateName.Preparing]: { glyph: RdyIconName.CLOCKFACE, color: Color.WHITE },
  [OrderStateName.PaymentFulfilled]: { glyph: RdyIconName.SUN, color: Color.WHITE },
  [OrderStateName.PaymentRejected]: { glyph: RdyIconName.SUN, color: Color.RDY_RED },
  [OrderStateName.Stale]: { glyph: RdyIconName.SUN, color: Color.WHITE },
  [OrderStateName.ReadyForPickupWithRdyPass]: { glyph: RdyIconName.THUMBSUP, color: Color.WHITE },
  [OrderStateName.ReadyForPickupWithTraditionalPass]: { glyph: RdyIconName.THUMBSUP, color: Color.RDY_FOREST },
  [OrderStateName.PickedUpFromRdyPass]: { glyph: RdyIconName.SUN, color: Color.WHITE }
}

export const OrderStatusShape = (props: OrderStatusShapeProps) => {
  const { orderState, isRdy } = props
  const { name, timestamp } = orderState

  const strokeWidth = name === OrderStateName.Preparing ? 20 : 4
  const fill = name === OrderStateName.ReadyForPickupWithTraditionalPass ? Color.RDY_MALIBU : Color.RDY_FOREST

  const originalWidth = 341
  const originalHeight = 268

  return (
    <div>
      {isRdy ? (
        <div>
          <div style={{ marginBottom: 24, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Icon color={orderStateNameToIconInfo[name].color} icon={orderStateNameToIconInfo[name].glyph} size={34} />

            <div>
              <Text variant='header4' color={orderStateNameToIconInfo[name].color}>
                {orderStateNameToReadable[name]}
              </Text>
            </div>

            <div>
              <Text variant='subheader3' color={orderStateNameToIconInfo[name].color}>
                {timestamp ? readableTimeFromInstant(timestamp) : ''}
              </Text>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ position: 'relative' }}>
          <svg
            preserveAspectRatio='none'
            width={'100%'}
            height={'100%'}
            viewBox={`0 0 ${originalWidth} ${originalHeight}`}
            fill='none'
          >
            <path
              d='M10 258V75.9899L170.5 10.7935L331 75.9899V258H10Z'
              stroke={Color.RDY_MALIBU}
              strokeWidth={strokeWidth}
              fill={fill}
            />
          </svg>

          <div
            style={{
              position: 'absolute',
              top: 0,
              bottom: 16,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div style={{ marginBottom: 30 }}>
                <Icon
                  color={orderStateNameToIconInfo[name].color}
                  icon={orderStateNameToIconInfo[name].glyph}
                  size={42}
                />
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text variant='header4' color={orderStateNameToIconInfo[name].color} textAlign='center'>
                  {orderStateNameToReadable[name]}
                </Text>
              </div>

              <div>
                <Text variant='subheader3' color={orderStateNameToIconInfo[name].color}>
                  {timestamp ? readableTimeFromInstant(timestamp) : ''}
                </Text>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
