/* eslint-disable max-len */
import React from 'react'

export const Position3 = (props: { height: number }) => {
  const { height } = props
  return (
    <svg height={height} width='100%' viewBox='0 0 72 81' fill='none'>
      <path
        d='M72 54.5625C72 69.075 60.2059 81 45.6037 81H26.3963C11.9064 81 0 69.075 0 54.5625V26.4375C0 11.925 11.9064 0 26.3963 0H45.6037C60.0936 0 72 11.925 72 26.4375V54.5625Z'
        fill='#AAE0F8'
      />
      <path
        d='M41.5596 32.9625L54.4769 21.2625V10.6875H19.0947V21.6H40.8856L24.0369 36.1125L32.2366 43.425C33.3599 42.8625 35.0447 42.6375 36.6173 42.6375C41.3349 42.6375 44.817 45.7875 44.817 50.9625C44.817 55.6875 41.7842 59.2875 36.6173 59.2875C31.5627 59.2875 28.3053 55.8 27.9683 51.4125V50.0625V46.8V46.35L22.3521 41.4L16.7358 46.35V51.1875C16.7358 55.0125 17.7468 58.6125 19.6563 61.65C22.9137 66.7125 28.7546 70.2 36.8419 70.2C48.636 70.2 56.7234 61.875 56.7234 50.85C56.8357 40.6125 50.9948 34.2 41.5596 32.9625Z'
        fill='#09292D'
      />
    </svg>
  )
}
