import { Cart, Maybe } from '@bloom-coffee/espresso'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'

import { logger } from '../../logger'
import { fetchCart } from '../../service/CartService'
import { CartContext } from './CartContext'

export const CartProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props

  logger.debug('Cart Provider', 'render')

  const [updates, setNumberUpdates] = useState(0)
  const [cart, setCart] = useState<Maybe<Cart>>()

  useEffect(() => {
    logger.info('CartProvider', 'responding to cart updates')
    setCart(fetchCart())
  }, [updates])

  function notifyCartUpdated() {
    setNumberUpdates((updates) => updates + 1)
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        notifyCartUpdated
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export function useCart() {
  return useContext(CartContext)
}
