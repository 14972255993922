export enum RdyIconName {
  ALARM = 'RDY_alarm',
  BAG = 'RDY_bag',
  CLOCKFACE = 'RDY_clockface',
  CUP = 'RDY_cup',
  DOORWAY = 'RDY_doorway',
  EYES = 'RDY_eyes',
  HOME = 'RDY_home',
  HOT = 'RDY_hot',
  MUG = 'RDY_mug',
  POINTING = 'RDY_pointing',
  READY = 'RDY_ready',
  SMILEY = 'RDY_smiley',
  STAR = 'RDY_star',
  SUN = 'RDY_sun',
  THUMBSUP = 'RDY_thumbsup',
  LOGO_PRIMARY = 'RDY_logo_primary',
  UP_CHEVRON = 'RDY_up_chevron',
  DOWN_CHEVRON = 'RDY_down_chevron',
  BACK_ARROW = 'RDY_back_arrow',
  RIGHT_ARROW = 'RDY_right_arrow'
}
