import { Color, Text } from '@bloom-coffee/steamed-milk'
import React, { useEffect, useState } from 'react'
import { get, useFormContext } from 'react-hook-form'

import { TextFieldProps } from './models'

export const TextArea = React.forwardRef((props: TextFieldProps, ref?: any) => {
  const { name, label, ...rest } = props
  const [error, setError] = useState<string | undefined>()

  const methods = useFormContext()
  useEffect(() => {
    const errMessage = get(methods.errors, name)?.message
    if (errMessage) {
      setError(errMessage)
    }
  }, [methods, name])

  return (
    <>
      <textarea
        ref={ref}
        name={name}
        placeholder={label}
        {...rest}
        style={{
          border: `1px solid ${Color.RDY_FOREST}`,
          padding: '12px 8px 12px 8px',
          fontSize: 14,
          width: 200,
          height: 100
        }}
      />
      {error && (
        <div style={{ marginTop: 4 }}>
          <Text variant='error'>{error}</Text>
        </div>
      )}
    </>
  )
})
