import { Maybe } from '@bloom-coffee/espresso'
import React from 'react'

import { MaskedField } from './MaskedField'
import { TextFieldProps } from './models'

function parseValue(rawValue: string): Maybe<number> {
  if (rawValue === '$0.00') {
    return undefined
  }
  return Number(rawValue.replace(/[^0-9-]/g, ''))
}

function formatValue(value: Maybe<number>): string {
  if (value === null || typeof value === 'undefined') {
    return ''
  }
  const dollars = value / 100
  return formatter.format(dollars)
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const PriceField = (props: { defaultValue?: Maybe<number> } & TextFieldProps) => {
  return <MaskedField {...props} formatValue={formatValue} parseValue={parseValue} />
}
