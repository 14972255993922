import { Maybe } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React, { useEffect, useState } from 'react'
import { get, useController, useFormContext } from 'react-hook-form'

import { TextFieldProps } from './models'

export interface MaskedFieldProps extends TextFieldProps {
  formatValue: (value: any | null) => string
  parseValue: (rawValue: string) => any
  defaultValue?: Maybe<any>
}

export const MaskedField = (props: MaskedFieldProps) => {
  const { formatValue, parseValue, name, label, defaultValue, width, ...rest } = props
  const [error, setError] = useState<string | undefined>()

  const methods = useFormContext()
  useEffect(() => {
    const errMessage = get(methods.errors, name)?.message
    if (errMessage) {
      setError(errMessage)
    }
  }, [methods.errors, name])

  const {
    // name causes a bug on defaultValue
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field: { name: excludedName, value, onChange, ...fieldProps }
  } = useController({
    name,
    defaultValue
  })

  return (
    <div>
      <input
        {...rest}
        {...fieldProps}
        name={name}
        style={{ border: `1px solid ${Color.RDY_FOREST}`, padding: '12px 8px 12px 8px', fontSize: 14, width }}
        placeholder={label}
        value={formatValue(value)}
        onChange={(e) => onChange(parseValue(e.target.value))}
      />
      {error && (
        <div style={{ marginTop: 4 }}>
          <Text variant='error'>{error}</Text>
        </div>
      )}
    </div>
  )
}
