import { formatUsCentsAsUsDollar, getDefaultRawItemPriceTag, ProductInfo } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import { BadgeType } from '../../Product/UxUtils'

interface ProductListContainerProps {
  products: ProductInfo[]
  onPressItem: (product: ProductInfo) => void
  topProductIds: string[]
  trendingProductIds: string[]
}

const listItemBadge = (props: { type: BadgeType }) => {
  const badgeText = props.type === BadgeType.TOP ? 'MOST POPULAR' : 'TRENDING'
  return (
    <div style={{ flexDirection: 'row', flexGrow: 1 }}>
      <Text variant='header2' color={Color.RDY_RED}>
        {badgeText}
      </Text>
    </div>
  )
}

export const ProductListContainer = (props: ProductListContainerProps) => {
  const { products, onPressItem, topProductIds, trendingProductIds } = props

  return (
    <div style={{ display: 'block' }}>
      {products.map((item, index) => {
        const showPopularBadge = topProductIds.includes(item.id)
        const showTrendingBadge = trendingProductIds.includes(item.id)
        return (
          <div style={{ textAlign: 'center' }} key={`${item.id}.${index}`}>
            <button
              onClick={() => onPressItem(item)}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                paddingTop: 12,
                paddingBottom: 4,
                cursor: 'pointer'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flexShrink: 1, marginRight: 8, backgroundColor: undefined }}>
                      <Text variant='header1'>{item.name.toUpperCase()}</Text>
                    </div>
                    {(showPopularBadge || showTrendingBadge) && (
                      <div>
                        {showPopularBadge && listItemBadge({ type: BadgeType.TOP })}
                        {showTrendingBadge && listItemBadge({ type: BadgeType.TRENDING })}
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Text variant='body1'>${formatUsCentsAsUsDollar(getDefaultRawItemPriceTag(item).priceUsCents)}</Text>
                </div>
              </div>
            </button>
          </div>
        )
      })}
    </div>
  )
}
