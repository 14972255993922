import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Routes } from 'react-router-dom'

import { CanAuthRoot } from './CanAuthRoot'
import { CartContainer } from './Cart/CartContainer'
import { Merchant } from './Merchant/Merchant'
import { Order } from './Order/Order'
import { Organization } from './Organization/Organization'
import { Product } from './Product/Product'
import { QuickCart } from './QuickCart/QuickCart'
import { Splash } from './Splash/Splash'

export const Root = () => {
  return (
    <div>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
      </Helmet>
      <Routes>
        <Route path='/'>
          <Route path='beans/*' element={<CanAuthRoot />} />

          <Route path='quickCart' element={<QuickCart />} />

          <Route path='cart' element={<CartContainer />} />

          <Route path='order'>
            <Route path=':uuid' element={<Order />} />
          </Route>

          <Route path='locations'>
            <Route path=':readableOrganizationId' element={<Organization />} />
          </Route>

          <Route path=':readableMerchantId'>
            <Route path='products'>
              <Route path=':productId' element={<Product />} />
            </Route>

            <Route path='servingLocation/:servingLocationName' element={<Merchant />} />
            <Route path='' element={<Merchant />} />
          </Route>

          <Route path='' element={<Splash />} />
        </Route>
      </Routes>
    </div>
  )
}
