import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

import { config } from '../../config'

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate()

  const handleRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      cacheLocation='localstorage'
      clientId={config.auth0.clientId}
      domain={config.auth0.domain}
      audience={config.auth0.audience}
      scope='openid email profile offline_access'
      useRefreshTokens
      onRedirectCallback={handleRedirectCallback}
      redirectUri={`${window.location.origin}`}
    >
      {children}
    </Auth0Provider>
  )
}
