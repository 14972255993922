import { useAuth0 } from '@auth0/auth0-react'
import { AddressInput, Item } from '@bloom-coffee/espresso'
import { Button, Color, Modal, Text } from '@bloom-coffee/steamed-milk'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'

import { config } from '../../config'
import ImageAsset from '../../img/ImageAsset'
import { logger } from '../../logger'
import { AddressForm } from './AddressForm/AddressForm'
import { AddressFormModel } from './AddressForm/addressFormValidator'
import { CheckoutContainer } from './CheckoutContainer'
import { PurchaseItem } from './PurchaseItem'

const stripePromise = loadStripe(config.stripePK)

const localStorageKeyPurchaseShipping = 'purchase_shipping'
const localStorageKeyPurchaseFirst = 'purchase_firstName'
const localStorageKeyPurchaseLast = 'purchase_lastName'
const localStorageKeyPurchaseItem = 'purchase_Item'

interface PurchaseProps {
  items: Item[]
}

export const Purchase = (props: PurchaseProps) => {
  const { items } = props
  const [shippingRequired, setShippingRequired] = useState(false)
  const [item, setItem] = useState<Item | undefined>()
  const [shippingAddress, setShippingAddress] = useState<AddressInput | undefined>()
  const [first, setFirst] = useState<string | undefined>()
  const [last, setLast] = useState<string | undefined>()
  const [error, setError] = useState<string | undefined>()
  const [isCheckingOut, setCheckingOut] = useState(false)

  const { loginWithRedirect, isAuthenticated } = useAuth0()

  useEffect(() => {
    logger.debug('Purchase', 'fetching stored data')
    const storedShipping = localStorage.getItem(localStorageKeyPurchaseShipping)
    if (typeof storedShipping !== 'undefined' && storedShipping !== null) {
      logger.debug('Purchase', 'stored shipping: ' + storedShipping)
      try {
        setShippingAddress(JSON.parse(storedShipping) as AddressInput)
      } catch (e) {
        logger.error('Purchase', 'cannot parse shipping')
        localStorage.removeItem(localStorageKeyPurchaseShipping)
      }
    }

    const storedFirst = localStorage.getItem(localStorageKeyPurchaseFirst)
    if (typeof storedFirst !== 'undefined' && storedFirst !== null) {
      setFirst(storedFirst)
    }

    const storedLast = localStorage.getItem(localStorageKeyPurchaseLast)
    if (typeof storedLast !== 'undefined' && storedLast !== null) {
      setLast(storedLast)
    }

    const storedItem = localStorage.getItem(localStorageKeyPurchaseItem)
    if (typeof storedItem !== 'undefined' && storedItem !== null) {
      try {
        const _item = JSON.parse(storedItem) as Item
        if (items.map((i) => i.id).includes(_item.id)) {
          setItem(_item)
        }
      } catch (e) {
        logger.error('Purchase', 'cannot parse item')
        localStorage.removeItem(localStorageKeyPurchaseItem)
      }
    }
  }, [items])

  useEffect(() => {
    logger.debug('Purchase', 'checking whether to update shipping-required flag')
    if (!!item?.shippingRequired) {
      if (shippingRequired !== item.shippingRequired) {
        setShippingRequired(item.shippingRequired)
      }
    }
  }, [item, shippingRequired])

  function login() {
    storeData()
    loginWithRedirect({
      redirectUri: `${config.baseUrl}/beans`
    })
  }

  function storeData() {
    if (!!shippingAddress) {
      localStorage.setItem(localStorageKeyPurchaseShipping, JSON.stringify(shippingAddress))
    }
    if (!!first) {
      localStorage.setItem(localStorageKeyPurchaseFirst, first)
    }
    if (!!last) {
      localStorage.setItem(localStorageKeyPurchaseLast, last)
    }
    if (!!item) {
      localStorage.setItem(localStorageKeyPurchaseItem, JSON.stringify(item))
    }
  }

  function handleAddressForm(form: AddressFormModel) {
    setShippingAddress({
      street: form.street,
      street2: form.street2 || null,
      state: form.state,
      city: form.city,
      zipcode: form.zipcode,
      countryCode: null
    })
    setFirst(form.first)
    setLast(form.last)

    localStorage.setItem(localStorageKeyPurchaseShipping, JSON.stringify(shippingAddress))
    localStorage.setItem(localStorageKeyPurchaseFirst, form.first)
    localStorage.setItem(localStorageKeyPurchaseLast, form.last)
  }

  function handlePress(_item: Item) {
    if (!item || item.id !== _item.id) {
      setItem(_item)
    }
  }

  function editShipping() {
    if (!!shippingAddress) {
      setShippingAddress(undefined)
    }
    if (!!first) {
      setFirst(undefined)
    }
    if (!!last) {
      setLast(undefined)
    }
  }

  function checkout() {
    if (!shippingAddress || !first || !last) {
      setError('Must complete shipping address')
      return
    }

    if (!item) {
      setError('You must select an item')
      return
    }

    setError(undefined)
    setCheckingOut(true)
  }

  return (
    <div>
      <div
        style={{
          height: 200,
          width: '50%',
          backgroundImage: `url('${ImageAsset.RDY_HEADER}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          marginBottom: 24
        }}
      />

      <div style={{ marginBottom: 12 }}>
        <Text variant='header1'>Choose Item</Text>
      </div>

      <div style={{ display: 'flex', marginBottom: 24 }}>
        {items.map((eachItem: Item, index) => (
          <div key={`${eachItem.id}.${index}`} style={{ marginLeft: 12, marginRight: 12 }}>
            <PurchaseItem
              index={index}
              item={eachItem}
              onPress={handlePress}
              disabled={isCheckingOut}
              selected={item?.id === eachItem.id}
            />
          </div>
        ))}
      </div>

      <div style={{ marginBottom: 24 }}>
        <div style={{ marginBottom: 12 }}>
          <Text variant='header1'>Shipping</Text>
        </div>
        {!!shippingAddress ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 8 }}>
              <div>
                <Text variant='body2'>{`${first} ${last}`}</Text>
              </div>

              <div>
                <Text variant='body2'>{shippingAddress.street}</Text>
              </div>
              <div>
                <Text variant='body2'>{shippingAddress.street2}</Text>
              </div>

              <div>
                <Text variant='body2'>
                  {`${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zipcode}`}
                </Text>
              </div>
            </div>

            <div style={{ paddingTop: 6 }}>
              <Button
                theme='action'
                disabled={isCheckingOut}
                onClick={() => editShipping()}
                size='small'
                label='Edit'
              />
            </div>
          </div>
        ) : (
          <AddressForm onComplete={handleAddressForm} />
        )}
      </div>

      {error && (
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 8, paddingBottom: 8 }}>
          <Text variant='error'>{error}</Text>
        </div>
      )}

      {!isAuthenticated ? (
        <div>
          <div style={{ marginBottom: 12 }}>
            <Text variant='header1'>Account</Text>
          </div>
          <Button theme='action' onClick={() => login()} size='medium' label='Log In' />
        </div>
      ) : (
        <div>
          <Button theme='action' onClick={() => checkout()} size='medium' label='Checkout' />
        </div>
      )}

      <Modal open={isCheckingOut} onClose={() => setCheckingOut(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 8,
            paddingLeft: 8,
            paddingRight: 8,
            paddingBottom: 12,
            backgroundColor: Color.WHITE
          }}
        >
          <div style={{ marginBottom: 12 }}>
            <Text variant='header1'>Payment</Text>
          </div>
          {!!first && !!last && !!item && !!shippingAddress && (
            <div>
              <Elements stripe={stripePromise}>
                <CheckoutContainer
                  first={first}
                  last={last}
                  item={item}
                  shippingAddress={shippingAddress}
                  onCancel={() => setCheckingOut(false)}
                />
              </Elements>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}
