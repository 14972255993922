import { PositionCode } from '@bloom-coffee/espresso'
import { Color, Text } from '@bloom-coffee/steamed-milk'
import React from 'react'

import ImageAsset from '../../../../img/ImageAsset'
import { Position1 } from './Position1'
import { Position2 } from './Position2'
import { Position3 } from './Position3'
import { Position4 } from './Position4'

export type PositionStateName = 'none' | 'notPickedUp' | 'pickedUp' | 'shouldBeHere' | 'skipped'

interface PositionState {
  state: PositionStateName
  orderItemDetails?: {
    name: string
    modifiers: string
  }
}
export type RdyPassState = Record<PositionCode, PositionState>

interface PassStatusContainerProps {
  state: RdyPassState
}

const ICON_HEIGHT = window.innerHeight < 700 ? 70 : 81
const RDY_PASS_WIDTH = window.innerWidth > 400 ? 350 : 250
const RDY_PASS_HEIGHT = RDY_PASS_WIDTH / 1.54

const styles: Record<string, React.CSSProperties> = {
  infoContainer: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 8,
    width: 100,
    backgroundColor: Color.WHITE,
    borderWidth: 2,
    borderColor: Color.GREY_700,
    borderRadius: 10
  }
}

export const RdyPassContainer = (props: PassStatusContainerProps) => {
  const { state } = props

  /**
   * Decal if 1 item, details if more than one
   */
  const showDetails = Object.values(state).filter((val) => val.state !== 'none' && val.state !== 'skipped').length > 1
  return (
    <div
      style={{
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundImage: `url("${ImageAsset.RDY_PASS}"`,
        height: RDY_PASS_HEIGHT,
        width: RDY_PASS_WIDTH,
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {state.RDY_PASS_0.orderItemDetails && showDetails && (
          <div style={styles.infoContainer}>
            <div>
              <Text variant='subheader3' color={Color.RDY_FOREST}>{`${state.RDY_PASS_0.orderItemDetails.name}`}</Text>
            </div>
            <div>
              <Text variant='body2' color={Color.GREY_600}>{`${state.RDY_PASS_0.orderItemDetails.modifiers}`}</Text>
            </div>
          </div>
        )}
        {state.RDY_PASS_0.orderItemDetails && !showDetails && <Position1 height={ICON_HEIGHT} />}
      </div>

      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {state.RDY_PASS_3.orderItemDetails && showDetails && (
            <div style={styles.infoContainer}>
              <div>
                <Text variant='subheader3' color={Color.RDY_FOREST}>
                  {`${state.RDY_PASS_3.orderItemDetails.name}`}
                </Text>
              </div>

              <div>
                <Text variant='body2' color={Color.GREY_600}>
                  {`${state.RDY_PASS_3.orderItemDetails.modifiers}`}
                </Text>
              </div>
            </div>
          )}
          {state.RDY_PASS_3.orderItemDetails && !showDetails && <Position4 height={ICON_HEIGHT} />}
        </div>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {state.RDY_PASS_1.orderItemDetails && showDetails && (
            <div style={styles.infoContainer}>
              <div>
                <Text variant='subheader3' color={Color.RDY_FOREST}>
                  {`${state.RDY_PASS_1.orderItemDetails.name}`}
                </Text>
              </div>

              <div>
                <Text variant='body2' color={Color.GREY_600}>
                  {`${state.RDY_PASS_1.orderItemDetails.modifiers}`}
                </Text>
              </div>
            </div>
          )}
          {state.RDY_PASS_1.orderItemDetails && !showDetails && <Position2 height={ICON_HEIGHT} />}
        </div>
      </div>

      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {state.RDY_PASS_2.orderItemDetails && showDetails && (
          <div style={styles.infoContainer}>
            <div>
              <Text variant='subheader3' color={Color.RDY_FOREST}>
                {`${state.RDY_PASS_2.orderItemDetails.name}`}
              </Text>
            </div>

            <div>
              <Text variant='body2' color={Color.GREY_600}>
                {`${state.RDY_PASS_2.orderItemDetails.modifiers}`}
              </Text>
            </div>
          </div>
        )}
        {state.RDY_PASS_2.orderItemDetails && !showDetails && <Position3 height={ICON_HEIGHT} />}
      </div>
    </div>
  )
}
