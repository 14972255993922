import {
  Cart,
  CartItem,
  CategoryInfo,
  CategoryType,
  diningOptionToOrderDiningOption,
  FulfillmentLocationInfo,
  getMerchantAvailability,
  Maybe,
  ProductInfo,
  TipOptionConfig,
  TipType
} from '@bloom-coffee/espresso'
import { ActionText, Button, Color, RdyIconName, Spinner, Text } from '@bloom-coffee/steamed-milk'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { config } from '../../config'
import {
  DiningOption,
  OrderItemInput,
  OrderItemModifierInput,
  useGenerateOrderPaymentSummaryMutation,
  useMerchantForCartLazyQuery,
  useUpsellDataLazyQuery
} from '../../graphql/types.generated'
import { logger } from '../../logger'
import { useCart } from '../../providers/cart/CartProvider'
import {
  addToCart,
  duplicateCartItem,
  fetchCart,
  getDefaultTipInfoFromCart,
  getOrderTimingConfigFromAvailability,
  removeFromCart
} from '../../service/CartService'
import { fetchServingLocation } from '../../service/ServingLocationService'
import { ProductImageContainer } from '../Catalog/components/ProductImageContainer'
import { Checkout } from '../Checkout/Checkout'
import { OrderSummary } from '../Order/components/OrderSummary'
import { CartHeader } from './components/CartHeader'
import { CartItemsContainer } from './components/CartItemsContainer'
import { DiningOptionSelector } from './components/DiningOptionSelector'
import { OrderTimingConfig, OrderTimingSelection } from './components/OrderTimingConfig'
import { OrderTimingSelector } from './components/OrderTimingSelector'
import { ServingLocationSelector } from './components/ServingLocationSelector'
import { TipSelectionForm } from './components/TipSelectionForm'

const stripePromise = loadStripe(config.stripePK)

export const CartContainer = () => {
  const [upsellProducts, setUpsellProducts] = useState<ProductInfo[] | undefined>()
  const [isCheckingOut, setIsCheckingOut] = useState(false)
  const [diningOption, setDiningOption] = useState(DiningOption.Takeaway)
  const [servingLocation, setServingLocation] = useState<FulfillmentLocationInfo | undefined>()
  const [tip, setTip] = useState<number | undefined>()
  const [tipOptionIndex, setTipOptionIndex] = useState<number | undefined>()
  const [tipType, setTipType] = useState<TipType | undefined>()
  const [tipOptions, setTipOptions] = useState<TipOptionConfig[]>([])
  const [subtotalForTip, setSubtotalForTip] = useState<Maybe<number>>()
  const [checkedFetchData, setCheckedFetchData] = useState(false)
  const [orderTimingConfig, setOrderTimingConfig] = useState<OrderTimingConfig | undefined>()
  const [orderTimingSelection, setOrderTimingSelection] = useState<OrderTimingSelection | undefined>()
  const [showTableConfirmation, setShowTableConfirmation] = useState(false)
  const [confirmedTable, setConfirmedTable] = useState(false)

  const navigate = useNavigate()

  const { cart, notifyCartUpdated } = useCart()

  const [execute, { data: orderPaymentSummary, error: orderPaymentSummaryError, loading: loadingOrderPaymentSummary }] =
    useGenerateOrderPaymentSummaryMutation()
  const [fetchMerchantData, { data: merchantData }] = useMerchantForCartLazyQuery({ fetchPolicy: 'cache-first' })
  const [fetchUpsellData, { data: upsellData }] = useUpsellDataLazyQuery({ fetchPolicy: 'cache-first' })

  useEffect(() => {
    let servingLocation = fetchServingLocation()

    if (servingLocation && merchantData?.merchant?.diningOptions.find((d) => d === DiningOption.TableService)) {
      setDiningOption(DiningOption.TableService)
      let foundLocation = merchantData?.merchant?.servingLocations?.find((s) => s.id === servingLocation?.id)
      setServingLocation(foundLocation)
    } else {
      if (!merchantData?.merchant?.diningOptions || !merchantData?.merchant?.diningOptions.length) {
        setDiningOption(DiningOption.Takeaway)
      } else {
        let nonTableSerivce = merchantData?.merchant?.diningOptions.find((d) => d !== DiningOption.TableService)
        if (nonTableSerivce) {
          setDiningOption(nonTableSerivce)
        } else {
          setDiningOption(DiningOption.TableService)
          let foundLocation = merchantData?.merchant?.servingLocations?.find((s) => !!s)
          if (!!foundLocation) {
            setServingLocation(foundLocation)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantData])

  const updatePaymentSummary = useCallback(
    (
      _tipUsCents: number,
      _cart: Cart,
      _diningOption: DiningOption,
      _orderTimingSelection: Maybe<OrderTimingSelection>
    ) => {
      const items: OrderItemInput[] = []
      for (let cartItem of _cart.items) {
        const modifiers: OrderItemModifierInput[] = cartItem.selectedModifiers.map((sm) => ({
          parentModifierId: sm.topModifier.id,
          selectedModifierId: sm.secondLevelModifier.id
        }))
        items.push({
          productId: cartItem.product.id,
          modifiers,
          currencyType: cartItem.currencyType,
          notes: cartItem.notes
        })
      }
      let placeInSeconds = _orderTimingSelection?.placeInSeconds
      if (!placeInSeconds && !!_orderTimingSelection?.date) {
        placeInSeconds = Math.round((_orderTimingSelection.date.getTime() - new Date().getTime()) / 1000) + 60
      }
      const graphqlInput = {
        tipUsCents: _tipUsCents,
        order: { items },
        diningOption: diningOptionToOrderDiningOption[_diningOption],
        timing: _orderTimingSelection?.timing,
        placeInSeconds
      }
      execute({ variables: { input: graphqlInput } })
    },
    [execute]
  )

  const resetTipInfo = useCallback(
    (nonPromoSubtotal: number, options: TipOptionConfig[], type: TipType, newTipCents: number, optionIndex: number) => {
      setSubtotalForTip(nonPromoSubtotal)
      setTipOptions(options)
      setTipType(type)
      setTip(newTipCents)
      setTipOptionIndex(optionIndex)
    },
    []
  )

  useEffect(() => {
    if (typeof upsellProducts === 'undefined' && !!upsellData?.merchant) {
      logger.debug('CartContainer', 'useEffect - setting upsell products')
      const categories: CategoryInfo[] = upsellData?.merchant?.activeCatalog?.activeCategories || []
      if (categories.length) {
        const _upsellProducts: ProductInfo[] = categories
          .filter((cat) => cat.type === CategoryType.RetailBeans) // beans only for now
          .flatMap((cat) => cat.activeProducts || [])
          .filter((p) => !cart?.items.map((i) => i.product.id).includes(p.id))
        setUpsellProducts(_upsellProducts)
      }
    }
  }, [upsellData, upsellProducts, cart])

  useEffect(() => {
    if (!checkedFetchData) {
      logger.debug('CartContainer', 'checking if cart exists')
      setCheckedFetchData(true)
      const cart = fetchCart()
      if (cart) {
        logger.debug('CartContainer', 'fetching merchant & upsell data')
        fetchMerchantData({ variables: { id: cart.merchant.id } })
        fetchUpsellData({ variables: { id: cart.merchant.id } })
      }
    }
  }, [checkedFetchData, fetchMerchantData, fetchUpsellData])

  useEffect(() => {
    logger.debug('CartContainer', 'checking whether cart & merchant data exist')
    const cart = fetchCart()
    if (cart && merchantData?.merchant) {
      logger.debug('CartContainer', 'initializing first payment summary & setting order timing config')

      const { nonPromoSubtotal, options, type, newTipCents, defaultIndex } = getDefaultTipInfoFromCart(cart)
      resetTipInfo(nonPromoSubtotal, options, type, newTipCents, defaultIndex)

      const availability = getMerchantAvailability(merchantData.merchant.operationHours)
      const shouldIntermissionConfig =
        merchantData.merchant.settings.filter((s) => s.name === 'orders_intermissionTimingConfiguration')[0]?.value ===
        '1'

      const _orderTimingConfig = getOrderTimingConfigFromAvailability(availability, shouldIntermissionConfig)
      logger.debug('CartContainer', JSON.stringify(availability))
      logger.debug('CartContainer', JSON.stringify(_orderTimingConfig))
      const defaultSelection = _orderTimingConfig.selections[0]
      setOrderTimingConfig(_orderTimingConfig)
      setOrderTimingSelection(defaultSelection)

      updatePaymentSummary(newTipCents, cart, diningOption, defaultSelection)
    }
  }, [diningOption, merchantData, resetTipInfo, updatePaymentSummary])

  function navigateToMenu() {
    navigate(`/${merchantData?.merchant?.readableId}`)
  }

  async function handleDuplicateItem(item: CartItem) {
    if (!loadingOrderPaymentSummary) {
      const newCartResult = duplicateCartItem(item)
      notifyCartUpdated()

      const { nonPromoSubtotal, options, type, newTipCents, defaultIndex } = getDefaultTipInfoFromCart(newCartResult!)
      resetTipInfo(nonPromoSubtotal, options, type, newTipCents, defaultIndex)
      updatePaymentSummary(newTipCents, newCartResult!, diningOption, orderTimingSelection)
    }
  }

  async function handleRemoveItem(item: CartItem) {
    if (!loadingOrderPaymentSummary) {
      const newCartResult = removeFromCart(item)
      notifyCartUpdated()
      if (newCartResult) {
        const { nonPromoSubtotal, options, type, newTipCents, defaultIndex } = getDefaultTipInfoFromCart(newCartResult!)
        resetTipInfo(nonPromoSubtotal, options, type, newTipCents, defaultIndex)
        updatePaymentSummary(newTipCents, newCartResult!, diningOption, orderTimingSelection)
      }
    }
  }

  async function addUpsellProductToCart(product: ProductInfo) {
    if (!loadingOrderPaymentSummary && merchantData?.merchant) {
      const newCartResult = addToCart(merchantData.merchant, product, [], 1)
      notifyCartUpdated()

      const { nonPromoSubtotal, options, type, newTipCents, defaultIndex } = getDefaultTipInfoFromCart(newCartResult!)
      resetTipInfo(nonPromoSubtotal, options, type, newTipCents, defaultIndex)
      updatePaymentSummary(newTipCents, newCartResult!, diningOption, orderTimingSelection)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isCheckingOut])

  function handleOrderTimingClick(selection: OrderTimingSelection) {
    if (!loadingOrderPaymentSummary) {
      setOrderTimingSelection(selection)
      updatePaymentSummary(tip!, cart!, diningOption, selection)
    }
  }

  function handleDiningOptionAction(diningOption: DiningOption) {
    if (!loadingOrderPaymentSummary) {
      setDiningOption(diningOption)

      if (diningOption === DiningOption.TableService && !servingLocation) {
        let linkedServingLocation = fetchServingLocation()
        let foundLocation =
          merchantData?.merchant?.servingLocations?.find((s) => s.id === linkedServingLocation?.id) ??
          merchantData?.merchant?.servingLocations?.find((s) => !!s)

        setServingLocation(foundLocation)
      }

      updatePaymentSummary(tip!, cart!, diningOption, orderTimingSelection)
    }
  }

  async function handleTipSelection(selectionIndex: number) {
    if (!loadingOrderPaymentSummary && selectionIndex !== tipOptionIndex) {
      setTipOptionIndex(selectionIndex)
      const value = tipOptions![selectionIndex].value
      const newTipCents = tipType === 'flat' ? value : Math.round(Math.round(subtotalForTip! * value * 100) / 100)
      setTip(newTipCents)
      updatePaymentSummary(newTipCents, cart!, diningOption, orderTimingSelection)
    }
  }

  async function handleCustomTip(valueUsCents: number) {
    if (!loadingOrderPaymentSummary) {
      setTipOptionIndex(tipOptions.length)
      setTip(valueUsCents)
      updatePaymentSummary(valueUsCents, cart!, diningOption, orderTimingSelection)
    }
  }

  useEffect(() => {
    if (confirmedTable) {
      attemptCheckout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedTable])

  function attemptCheckout() {
    if (servingLocation && diningOption === DiningOption.TableService && !confirmedTable) {
      setShowTableConfirmation(true)
    } else {
      setIsCheckingOut(true)
    }
  }

  return (
    <div>
      <div
        style={{
          display: isCheckingOut || showTableConfirmation ? 'none' : 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: 48
        }}
      >
        <div
          style={{
            width: window.innerWidth > 550 ? 700 : 320,
            backgroundColor: Color.GREY_200,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: 24,
            paddingLeft: 12,
            paddingRight: 12
          }}
        >
          <div style={{ marginBottom: 6 }}>
            <ActionText
              onClick={() => navigateToMenu()}
              color={Color.BLUE_GREY_500}
              message='View Menu'
              iconName={RdyIconName.BACK_ARROW}
            />
          </div>

          <div
            style={{
              borderBottom: `1px solid ${Color.RDY_BEIGE}`,
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 24
            }}
          >
            {!!merchantData?.merchant && <CartHeader merchant={merchantData.merchant} onPress={navigateToMenu} />}
          </div>

          <div style={{ marginBottom: 24, backgroundColor: Color.WHITE, borderRadius: 8 }}>
            <CartItemsContainer
              readableId={merchantData?.merchant?.readableId || ''}
              cart={cart}
              handleDuplicateItem={handleDuplicateItem}
              handleRemoveItem={handleRemoveItem}
            />
          </div>

          {!!upsellProducts?.length && !!cart && cart.items.length > 0 && (
            <div style={{ marginBottom: 24 }}>
              <div>
                <Text variant='header3'>People Also Ordered</Text>
              </div>

              <div style={{ backgroundColor: Color.RDY_FOREST, borderRadius: 8 }}>
                <ProductImageContainer
                  products={upsellProducts}
                  onPressItem={addUpsellProductToCart}
                  topProductIds={[]}
                  trendingProductIds={[]}
                  disabled={loadingOrderPaymentSummary || isCheckingOut}
                />
              </div>
            </div>
          )}

          {!!cart && cart.items.length > 0 && (
            <>
              {merchantData?.merchant?.diningOptions && merchantData?.merchant?.diningOptions.length > 1 && (
                <div style={{ marginBottom: 24 }}>
                  <div style={{ paddingBottom: 12 }}>
                    <Text variant='subheader2'>Dining Option</Text>
                  </div>

                  <div>
                    <DiningOptionSelector
                      diningOptions={merchantData?.merchant?.diningOptions}
                      onDiningOptionUpdate={handleDiningOptionAction}
                      diningOption={diningOption}
                    />
                  </div>
                </div>
              )}

              {diningOption !== DiningOption.TableService && (
                <div style={{ marginBottom: 24 }}>
                  <div style={{ paddingBottom: 12 }}>
                    <Text variant='subheader2'>Order Timing</Text>
                  </div>

                  {!!orderTimingConfig && !!orderTimingSelection && (
                    <OrderTimingSelector
                      config={orderTimingConfig}
                      selected={orderTimingSelection}
                      onOrderTimingUpdate={handleOrderTimingClick}
                    />
                  )}
                </div>
              )}

              {diningOption === DiningOption.TableService && merchantData?.merchant?.servingLocations?.length && (
                <div style={{ marginBottom: 24 }}>
                  <div style={{ paddingBottom: 12 }}>
                    <Text variant='subheader2'>Table</Text>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <ServingLocationSelector
                      initialValue={servingLocation}
                      onChange={(s) => setServingLocation(s)}
                      servingLocations={merchantData?.merchant?.servingLocations}
                    />
                  </div>
                </div>
              )}
              <div style={{ paddingBottom: 12, marginBottom: 24, borderBottom: `1px solid ${Color.RDY_BEIGE}` }}>
                <div style={{ paddingBottom: 12 }}>
                  <Text variant='subheader2'>Tip</Text>
                </div>

                {!orderPaymentSummaryError && typeof tipOptionIndex !== 'undefined' && tipOptions.length > 0 && (
                  <TipSelectionForm
                    disabled={loadingOrderPaymentSummary || isCheckingOut}
                    options={tipOptions}
                    selectedIndex={tipOptionIndex}
                    handleSelection={handleTipSelection}
                    handleCustomTip={handleCustomTip}
                  />
                )}
              </div>
            </>
          )}

          <div style={{ marginBottom: 24 }}>
            {loadingOrderPaymentSummary && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner color='primary' size='small' />
              </div>
            )}
            {!!cart?.items.length && !!orderPaymentSummary?.generateOrderPaymentSummary && (
              <OrderSummary
                tipUsCents={orderPaymentSummary.generateOrderPaymentSummary.usCents!.tip!}
                taxUsCents={orderPaymentSummary.generateOrderPaymentSummary.usCents!.tax!}
                feeUsCents={orderPaymentSummary.generateOrderPaymentSummary.usCents!.fee!}
                subtotalUsCents={orderPaymentSummary.generateOrderPaymentSummary.usCents!.subtotal!}
                totalUsCents={orderPaymentSummary.generateOrderPaymentSummary.usCents!.total}
              />
            )}
          </div>

          {!cart ||
            (cart.items.length === 0 && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Text variant='subheader2'>No items in cart</Text>
              </div>
            ))}

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ paddingTop: 8, paddingBottom: 8 }}>
              {!!orderPaymentSummaryError && (
                <Text variant='subheader2' color={Color.RED}>
                  {orderPaymentSummaryError.message}
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>

      {!!cart?.items.length &&
        !orderPaymentSummaryError &&
        !loadingOrderPaymentSummary &&
        !isCheckingOut &&
        !showTableConfirmation && (
          <div
            style={{
              borderTop: `1px solid ${Color.BLUE_GREY_700}`,
              backgroundColor: Color.WHITE,
              display: 'flex',
              justifyContent: 'center',
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              paddingTop: 12,
              paddingBottom: 18
            }}
          >
            <Button
              theme='action'
              onClick={() => attemptCheckout()}
              size='large'
              label='Check Out'
              disabled={loadingOrderPaymentSummary || isCheckingOut}
            />
          </div>
        )}

      {showTableConfirmation && (
        <div
          style={{
            backgroundColor: Color.WHITE,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 12,
            paddingBottom: 18
          }}
        >
          <div
            style={{
              paddingBottom: 20,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Text textAlign='center' variant='header2'>
                  {'Please confirm your location is:'}
                </Text>
                <Text textAlign='center' variant='header2'>{`"${servingLocation?.displayName}"`}</Text>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                <Button
                  style={{ margin: 5 }}
                  theme='cancel'
                  onClick={() => setShowTableConfirmation(false)}
                  size='medium'
                  label='No'
                />
                <Button
                  style={{ margin: 5 }}
                  theme='action'
                  onClick={() => {
                    setShowTableConfirmation(false)
                    setConfirmedTable(true)
                  }}
                  size='medium'
                  label='Yes'
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isCheckingOut && !!orderTimingSelection && !!cart && !!orderPaymentSummary?.generateOrderPaymentSummary && (
        <div
          style={{
            backgroundColor: Color.WHITE,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 12,
            paddingBottom: 18
          }}
        >
          <div
            style={{
              paddingBottom: 20,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Text variant='header1'>{`${cart!.merchant.name} Checkout`}</Text>
            {!!cart!.merchant?.location && (
              <div style={{ textAlign: 'center' }}>
                <div>
                  <Text variant='body3'>{cart!.merchant.location.street}</Text>
                </div>

                <div>
                  <Text variant='body3'>{`${cart!.merchant.location.city}, ${cart!.merchant.location.state}`}</Text>
                </div>
              </div>
            )}
          </div>
          <Elements stripe={stripePromise}>
            <Checkout
              onCancel={() => setIsCheckingOut(false)}
              merchantName={cart!.merchant.name}
              cartItems={cart!.items}
              paymentSummary={orderPaymentSummary.generateOrderPaymentSummary}
              diningOption={diningOption}
              servingLocation={servingLocation}
              orderTiming={orderTimingSelection.timing}
              placeInSeconds={orderTimingSelection.placeInSeconds}
              placeOnDate={orderTimingSelection.date}
              intermission={orderTimingSelection.displayName === 'Intermission'} // hack - not good, fix this
            />
          </Elements>
        </div>
      )}
    </div>
  )
}
