import { isValidEmail, isValidUsPhoneWithoutCountryCode, stripNonNumericCharacters } from '@bloom-coffee/espresso'
import * as yup from 'yup'

export const guestCheckoutInfoSchema = yup.object({
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  email: yup
    .string()
    .label('Email')
    .test({
      message: 'Invalid email',
      test: (inputVal) => {
        if (inputVal) {
          return isValidEmail(inputVal)
        }
        return true
      }
    })
    .required(),
  phoneNumber: yup
    .string()
    .label('Phone number')
    .test({
      message: 'Invalid phone number',
      test: (inputVal) => {
        if (inputVal) {
          const nonNumeric = stripNonNumericCharacters(inputVal)
          return isValidUsPhoneWithoutCountryCode(nonNumeric)
        }
        return true
      }
    })
    .required()
})

export type GuestCheckoutInfoModel = yup.InferType<typeof guestCheckoutInfoSchema>
